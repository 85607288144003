import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import PointsSelect from '../../../Components/PointsSelect';

// models
import {Points, ProductOptionGroups} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';


// start
const ProductOptionGroupsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [points, setPoints] = useState([]);
	const [categoriesAll, setCategoriesAll] = useState([]);
	const [categories, setCategories] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const [categoryPoints, setCategoryPoints] = useState([]);
	const [productOptions, setProductOptions] = useState([]);
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await pointsGet();
			await categoriesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const categoriesGet = async () => {
		const categories = await ProductOptionGroups.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined || categories.length === 0) return;
		setCategories(categories);
		setCategoriesAll(categories);
	};
	const categoryAdd = () => show();
	const categoryEdit = (category) => {
		category = category || categories.find(f => f._id === id);
		show(category);
	};
	const categoryShow = (category) => show(category);
	const show = (category) => {
		setId(category?._id||null);
		setName(category?.name||'');
		setDescription(category?.description||'');
		setProductOptions(category?category.productOptions:[]);
		setCategoryPoints(category ? category.points : points.map(v => ({id:v._id,name:v.name})));
	};
	const cancel = () => categoryShow(null);
	const save = async () => {
		const data = {
			name:name,
			description:description,
			points:categoryPoints
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название группы');
			return;
		}
		if (data.points.length === 0) {
			errorShow('Необходимо выбрать филиалы');
			return;
		}
		const res = id ?
				await ProductOptionGroups.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await ProductOptionGroups.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			categoriesGet();
			cancel();
			return true;
		}
	}
	const searchCategory = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setCategories(categoriesAll);
		else setCategories(categoriesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const categoryDelete = (id) => {
		ProductOptionGroups.remove(id).then((res) => {
			successShow(res.message);
			categoriesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const pointGet = (item) => {
		const p = item.points;
		const out = [];
		if (p && p.length !== points.length) {
			for (let i = 0; i < p.length; i++) {
				out.push(points.find(f => f._id === p[i].id).name);
			}
		}
		return out.length === 0 ? 'Все филиалы' : out.join(', ');
	};
	const pointCategorySelect = (point) => {
		const p = categoryPoints.find(f => f.id === point._id);
		if (p) setCategoryPoints(categoryPoints.filter(f => f.id !== point._id));
		else setCategoryPoints([...categoryPoints, {id:point._id,name:point.name}]);
	}
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = categories.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					description:s.description,
					points:s.points,
					productOptions:s.productOptions
				};
				await ProductOptionGroups.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		categoriesGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await ProductOptionGroups.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		categoriesGet();
		cancel();
		return true;
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Группы опций"
							type="books"
							data={categories}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'},
								{title:'Филиал',field:'points',class:'area',func:pointGet}
							]}
							rowsShort={['name']}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первую<br/>группу опций</>}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<PointsSelect isShow={true} selected={categoryPoints} />
								<h4>Выбранные опции</h4>
								<div className="product-view-row product-view-row-simple">
									{productOptions.length === 0 ? 'Опции не выбраны' : productOptions.map((v,i) => v.name).join(', ')}
								</div>
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название группы" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription}></textarea>
								</div>
								<PointsSelect isEdit={true} points={points} selected={categoryPoints} onSelect={pointCategorySelect} />
							</>}
							onDelete={categoryDelete}
							onEdit={categoryEdit}
							onAdd={categoryAdd}
							onSave={save}
							onSearch={searchCategory}
							onShow={categoryShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default ProductOptionGroupsScreen;