import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import InputMask from 'react-input-mask';
import Moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Partners, Tariffs, Transactions, Users} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Orfo from '../../../Globals/Orfo';

// constants
import { API, tariffTransactionStatusName, userTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgNoPhoto from './Images/no-photo.svg';
import imgEdit from './Images/edit.svg';
import imgWarning from './Images/warning.svg';
import imgClose from './Images/close.svg';


// start
const AccountScreen = (props) => {
	const [ModalConfirm, modalConfirmOpen, modalConfirmClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [partnerId, setPartnerId] = useState(null);
	const [profile, setProfile] = useState([]);
	const [partner, setPartner] = useState([]);
	const [transactions, setTransactions] = useState([]);
	const [tariffs, setTariffs] = useState([]);
	const [type, setType] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:comment, setValue:setComment, bind:bindComment} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:email, setValue:setEmail, bind:bindEmail} = useInput('');
	const {value:password, setValue:setPassword, bind:bindPassword} = useInput('');
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:address, setValue:setAddress, bind:bindAddress} = useInput('');
	const {value:officialName, setValue:setOfficialName, bind:bindOfficialName} = useInput('');
	const {value:officialAddress, setValue:setOfficialAddress, bind:bindOfficialAddress} = useInput('');
	const {value:inn, setValue:setInn, bind:bindInn} = useInput('');
	const {value:kpp, setValue:setKpp, bind:bindKpp} = useInput('');
	const {value:ogrn, setValue:setOgrn, bind:bindOgrn} = useInput('');
	const {value:okpo, setValue:setOkpo, bind:bindOkpo} = useInput('');
	const {value:okved, setValue:setOkved, bind:bindOkved} = useInput('');
	const {value:bank, setValue:setBank, bind:bindBank} = useInput('');
	const {value:bik, setValue:setBik, bind:bindBik} = useInput('');
	const {value:correspondent, setValue:setCorrespondent, bind:bindCorrespondent} = useInput('');
	const {value:account, setValue:setAccount, bind:bindAccount} = useInput('');
	const [avatar, setAvatar] = useState(null);
	const [tariffTab, setTariffTab] = useState(0);
	const [tariffId, setTariffId] = useState(null);
	const [tierIndex, setTierIndex] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isLicenseError, setIsLicenseError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const partner = await partnerGet();
			await profileGet();
			await transactionsGet();
			await tariffsGet();
			const lastupdate = ls('lastupdate');
			if (lastupdate) setIsLicenseError(!lastupdate.isactive);
			const point = ls('point');
			setPartnerId(point.partnerId);
			if (props.match.path.indexOf('/account/tariff') === 0) {
				setIsEditShow(false);
				setIsShow(true);
				if (props.match.path === '/account/tariff') showTariff(partner);
				if (props.match.path === '/account/tariff/fail') setType('tarifffail');
				if (props.match.path === '/account/tariff/success') setType('tariffsuccess');
			}
		};
		await dataGet();
		setLoading(false);
	}, []);
	const profileGet = async () => {
		const profile = await Users.profile().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (profile === undefined) return;
		setProfile(profile);
	};
	const tariffsGet = async () => {
		const tariffs = await Tariffs.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (tariffs === undefined) return;
		setTariffs(tariffs);
	};
	const transactionsGet = async () => {
		const transactions = await Transactions.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (transactions === undefined) return
		setTransactions(transactions);
	};
	const partnerGet = async () => {
		const partner = await Partners.get().catch((ex) => {
			console.log(666, ex);
			if (ex.code === 403) ls('lastupdate', {date:Moment().toISOString(),result:false});
			else setIsError(true);
		});
		if (partner === undefined) return;
		setPartner(partner);
		return partner;
	};
	const profileEdit = () => {
		show(profile);
		setIsShow(false);
		setIsEditShow(true);
	};
	const profileShow = () => {
		show(profile);
		setIsEditShow(false);
		setIsShow(true);
	};
	const show = (profile) => {
		setType('profile');
		setProfile(profile);
		setId(profile?._id||null);
		setName(profile?.name||'');
		setComment(profile?.comment||'');
		setEmail(profile?.email||'');
		setPassword(profile?.password||'');
		setPhone(profile?.phone||'');
		setAvatar(profile ? imageUrlGet(partnerId, profile._id) : null);
		setIsEditShow(true);
	};
	const partnerEdit = () => {
		showPartner(partner);
		setIsShow(false);
		setIsEditShow(true);
	};
	const partnerShow = () => {
		showPartner(partner);
		setIsEditShow(false);
		setIsShow(true);
	};
	const showPartner = (partner) => {
		setType('partner');
		setPartner(partner);
		setId(partner?._id||null);
		setName(partner?.name||'');
		setDescription(partner?.description||'');
		setOfficialName(partner?.requisites?.name||'');
		setAddress(partner?.contacts?.address||'');
		setPhone(partner?.contacts?.phone||'');
		setOfficialAddress(partner?.requisites?.officialAddress||'');
		setInn(partner?.requisites?.inn||'');
		setKpp(partner?.requisites?.kpp||'');
		setOgrn(partner?.requisites?.ogrn||'');
		setOkpo(partner?.requisites?.okpo||'');
		setOkved(partner?.requisites?.okved||'');
		setBank(partner?.requisites?.bank?.name||'');
		setBik(partner?.requisites?.bank?.bik||'');
		setCorrespondent(partner?.requisites?.bank?.correspondent||'');
		setAccount(partner?.requisites?.bank?.account||'');
		setIsEditShow(true);
	};
	const tariffShow = () => {
		showTariff(partner);
		setIsEditShow(false);
		setIsShow(true);
	};
	const showTariff = (partner) => {
		setType('tariff');
		setPartner(partner);
		setIsEditShow(true);
	};
	const transactionsShow = () => {
		transactionsGet();
		showTransactions(partner);
		setIsEditShow(false);
		setIsShow(true);
	};
	const showTransactions = (partner) => {
		setType('transactions');
		setPartner(partner);
		setIsEditShow(true);
	};
	const cancel = () => {
		setIsEditShow(false);
		setIsShow(true);
		cancelPay();
	};
	const cancelPay = () => {
		modalConfirmClose();
		setTariffId(null);
		setTierIndex(null);
	};
	const cancelAll = () => {
		setIsEditShow(false);
		setIsShow(false);
		setType(null);
	}
	const savePartner = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			officialName:officialName,
			officialAddress:officialAddress,
			address:address,
			inn:inn,
			kpp:kpp,
			ogrn:ogrn,
			okpo:okpo,
			okved:okved,
			bankName:bank,
			bik:bik,
			correspondent:correspondent,
			account:account
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название компании');
			return;
		}
		const res = await Partners.update(id, data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			partnerGet();
			cancel();
		}
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			email:email,
			password:password,
			phone:Utils.phoneNormalize(phone),
			comment:comment
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить имя сотрудника');
			return;
		}
		if (Utils.empty(data.email)) {
			errorShow('Необходимо заполнить электронную почту');
			return;
		}
		if (!id) {
			if (Utils.empty(data.password)) {
				errorShow('Необходимо заполнить пароль');
				return;
			}
		}
		if (Utils.empty(data.phone)) {
			errorShow('Необходимо заполнить телефон');
			return;
		}
		const res = id ?
				await Users.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.code === 409 ? ex.message : null);
				})
			:
				await Users.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.code === 409 ? ex.message : null);
				});
		if (res) {
			avatarUpload(id||res.id);
			successShow(res.message);
			profileGet();
			cancel();
		}
	}
	const avatarUpload = async (id) => avatar && avatar.indexOf('data:image') === 0 ? await Users.imageAdd(id, {data:avatar.replace(/^.*,/gi,'')}).catch((ex) => console.log(666, ex)) : null;
	const imageError = (e) => e.target.src = imgNoPhoto;
	const imageUrlGet = (partnerId, userId) => `${API.assets}partners/${partnerId}/users/${userId}/avatar.jpg`;
	const avatarDelete = () => setAvatar(null);
	const handleImage = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setAvatar(r.target.result);
		reader.readAsDataURL(file);
	};
	const pay = async (id, index) => {
		const res = await Tariffs.pay({id,index}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) window.open(res.formUrl);
		cancelPay();
	};
	const payModal = () => pay(tariffId, tierIndex);
	const payPrepare = (id, index) => {
		const idx = tariffs.findIndex(f => f._id === id);
		const old = tariffs.findIndex(f => f._id === partner.tariff._id);
		if (old > idx) {
			setTariffId(id);
			setTierIndex(index);
			modalConfirmOpen();
		}
		else pay(id, index);
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
							<SideMenu title="Профиль" type={[{type:'account'},{type:'settings',title:'Настройки'}]} disabled={true} />
								<div className="products-list">
									<div className="container-inner">
										<table className="items items-next">
											<tbody>
												<tr onClick={() => profileShow()} className={type==='profile'?'row-selected':''}><td>{profile.name}<span>{userTypeName[profile.type]}</span></td></tr>
												<tr onClick={() => partnerShow()} className={type==='partner'?'row-selected':''}><td>Реквизиты компании</td></tr>
												<tr onClick={() => tariffShow()} className={['tariff','tarifffail','tariffsuccess'].includes(type)?'row-selected':''}><td className={isLicenseError?'tariff':''}>Тариф{isLicenseError ? <img src={imgWarning} className="warning" alt="Лицензия не оплачена" /> : null}</td></tr>
												<tr onClick={() => transactionsShow()} className={type==='transactions'?'row-selected':''}><td>История оплаты</td></tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													{type === 'profile' ?
															<>
																<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
																	<div className="product-view-row-oneline product-view-row-oneline-start">
																		<img src={imageUrlGet(partnerId, profile._id)} alt="" onError={imageError} className="avatar" />
																		<div>
																			<h3>{name}</h3>
																			<span className="user-type">{userTypeName[profile.type]}</span>
																		</div>
																	</div>
																	<img src={imgEdit} alt="" className="edit" onClick={() => profileEdit()} />
																</div>
																<h4>Телефон</h4>
																<div className="product-view-row-simple">
																	{Utils.phoneFormatter(phone)}
																</div>
																{comment ?
																	<>
																		<h4>Комментарий</h4>
																		<div className="product-view-row product-view-row-simple">
																			<p className="comment">{comment}</p>
																		</div>
																	</> : null}
															</>
														: null}
													{type === 'partner' ?
															<>
																<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
																	<div>
																		<h3>{partner.name}</h3>
																		<span className="user-type"> ID: {partner.number}</span>
																	</div>
																	<img src={imgEdit} alt="" className="edit" onClick={() => partnerEdit()} />
																</div>
																{partner.description ?
																	<>
																		<div className="product-view-row product-view-row-simple">
																			<p className="comment">{partner.description}</p>
																		</div>
																	</> : null}
																<h4>Контакты</h4>
																<div className="product-view-row">
																	<span><b>Адрес</b></span>
																	<div>{partner.contacts.address}</div>
																</div>
																<div className="product-view-row">
																	<span><b>Телефон</b></span>
																	<div>{Utils.phoneFormatter(partner.contacts.phone)}</div>
																</div>
																<h4>Реквизиты</h4>
																<div className="product-view-row">
																	<span>Официальное название</span>
																	<div><b>{partner.requisites?.name||'–'}</b></div>
																</div>
																<div className="product-view-row">
																	<span>Юридический адрес</span>
																	<div>{partner.requisites?.address||'–'}</div>
																</div>
																<div className="product-view-row product-view-row-oneline">
																	<span>ИНН</span>
																	<div>{partner.requisites?.inn||'–'}</div>
																</div>
																<div className="product-view-row product-view-row-oneline">
																	<span>КПП</span>
																	<div>{partner.requisites?.kpp||'–'}</div>
																</div>
																<div className="product-view-row product-view-row-oneline">
																	<span>ОГРН</span>
																	<div>{partner.requisites?.ogrn||'–'}</div>
																</div>
																<div className="product-view-row product-view-row-oneline">
																	<span>ОКПО</span>
																	<div>{partner.requisites?.okpo||'–'}</div>
																</div>
																<div className="product-view-row product-view-row-oneline">
																	<span>ОКВЕД</span>
																	<div>{partner.requisites?.okved||'–'}</div>
																</div>
															</>
														: null}
													{type === 'tariff' ?
															<>
																<div className="product-view-row product-view-row-simple">
																	<div>
																		<h3>{partner.tariff.name}</h3>
																	</div>
																</div>
																<div className="product-view-row product-view-row-simple">
																	<p className="comment">{partner.tariff.description}</p>
																</div>
																<div className="product-view-row product-view-row-oneline">
																	<span>Стоимость</span>
																	<div>{partner.tariff.options.isTrial ? 'Бесплатно' : <><b>{partner.tariff.price}</b> ₽/мес.</>}</div>
																</div>
																{partner.tariff.options.isTrial ?
																	<div className="product-view-row product-view-row-oneline">
																		<span>Продолжительность</span>
																		<div>{Orfo.counters.days(partner.tariff.period)}</div>
																	</div> : null}
																<div className="product-view-row product-view-row-oneline">
																	<span>Действует до</span>
																	<div>{Moment(partner.license.paidTill).format('DD.MM.YYYY')}</div>
																</div>
																<h4>Тарифы</h4>
																<div className="tariffs-container">
																	<div className="tariff-tabs">
																		{tariffs.map((v,i) => <div key={i} className={`tariff-tab${tariffTab===i?' tariff-tab-active':''}`} onClick={() => setTariffTab(i)}>
																			{v.name}
																		</div>)}
																	</div>
																	<div className="tariff-tiers">
																		{tariffs[tariffTab].tiers.map((v,i) => <div key={i} className="tariff-tier">
																				<div className="period">{Orfo.counters.months(v.period)}</div>
																				<div className="price"><b>{v.price}</b> ₽</div>
																				<div className="discount">{v.discount?`${v.discount}%`:'–'}</div>
																				<div><button type="button" onClick={() => payPrepare(tariffs[tariffTab]._id, i)}>Оплатить</button></div>
																			</div>)}
																	</div>
																</div>
															</>
														: null}
													{type === 'tariffsuccess' ?
															<>
																<div className="product-view-row product-view-row-simple">
																	<div>
																		<h3>Оплата прошла!</h3>
																	</div>
																	<div className="product-view-row product-view-row-simple">
																		Оплата тарифа прошла успешно. Теперь вы можете пользоваться всеми его преимуществами.
																	</div>
																</div>
															</>
														: null}
													{type === 'tarifffail' ?
															<>
																<div className="product-view-row product-view-row-simple">
																	<div>
																		<h3>Ошибка!</h3>
																	</div>
																	<div className="product-view-row product-view-row-simple">
																		Оплата тарифа не прошла. Попробуйте еще раз.
																	</div>
																</div>
															</>
														: null}
													{type === 'transactions' ?
															<>
																<div className="product-view-row product-view-row-simple">
																	<div>
																		<h3>История платежей</h3>
																	</div>
																	{transactions && transactions.length ?
																			<table className="table-transactions">
																				<thead>
																					<tr>
																						<th>Дата</th>
																						<th>Сумма</th>
																						<th>Тариф</th>
																						<th>Период</th>
																						<th>Статус</th>
																					</tr>
																				</thead>
																				<tbody>
																					{transactions.map((v,i) => <tr key={i}>
																						<td>{Moment(v.date).format('DD.MM.YYYY')}<br/>{Moment(v.date).format('HH:mm:ss')}</td>
																						<td className="price">{v.amount} ₽</td>
																						<td>{v.tariff.name}</td>
																						<td>{Orfo.counters.months(v.period)}</td>
																						<td className="status">{tariffTransactionStatusName[v.status]}</td>
																					</tr>)}
																				</tbody>
																			</table>
																		:
																			<div className="product-view-row product-view-row-simple">
																				<p className="comment">Платежей не найдено</p>
																			</div>
																	}
																</div>
															</>
														: null}
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancelAll} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>

									: null}
								{isEditShow ?
									<>
										{type === 'profile' ?
											<form className="product-edit-list-container" onSubmit={save}>
												<div className="container-inner">
													<div className="list">
														<div className="product-edit-row">
															<input type="text" {...bindName} placeholder="Ваше имя" className="input-title" autoFocus={true} required />
														</div>
														<div className="product-edit-row">
															<textarea placeholder="Комментарий" {...bindComment} className="lite"></textarea>
														</div>
														<h4>Фотография</h4>
														<div className="product-edit-row">
															{avatar ?
																	<div className="product-edit-images">
																		<div className="product-edit-image">
																			<img src={avatar} alt="" onError={imageError} />
																			<div className="delete" onClick={avatarDelete}></div>
																		</div>
																	</div>
																: null}
															<label>
																<div className="product-edit-btn">
																	Добавить фото
																	<input type="file" accept="image/jpeg,image/png,image/webp" onChange={handleImage} />
																</div>
															</label>
														</div>
														<h4>Параметры доступа</h4>
														<div className="product-edit-row">
															<input type="email" {...bindEmail} placeholder="Электронная почта" required />
														</div>
														<div className="product-edit-row">
															<input type="password" {...bindPassword} placeholder="Пароль" required={id==null} />
														</div>
														<div className="product-edit-row">
															<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindPhone} required />
														</div>
													</div>
												</div>
												<div className="product-edit-footer">
													<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
													<button type="submit" className="btn-save">Сохранить</button>
												</div>
											</form> : null}
										{type === 'partner' ?
											<form className="product-edit-list-container" onSubmit={savePartner}>
												<div className="container-inner">
													<div className="list">
														<div className="product-edit-row">
															<input type="text" {...bindName} placeholder="Название" className="input-title" autoFocus={true} required />
														</div>
														<div className="product-edit-row">
															<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
														</div>
														<h4>Контакты</h4>
														<div className="product-edit-row">
															<input type="text" {...bindAddress} placeholder="Адрес" />
														</div>
														<div className="product-edit-row">
															<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindPhone} />
														</div>
														<h4>Реквизиты</h4>
														<div className="product-edit-row">
															<input type="text" {...bindOfficialName} placeholder="Официальное название" />
														</div>
														<div className="product-edit-row">
															<input type="text" {...bindOfficialAddress} placeholder="Юридический адрес" />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="inn" className="label-middle">ИНН</label>
															<input id="inn" type="text" {...bindInn} placeholder="ИНН" maxLength={12} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="kpp" className="label-middle">КПП</label>
															<input id="kpp" type="text" {...bindKpp} placeholder="КПП" maxLength={9} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="ogrn" className="label-middle">ОГРН</label>
															<input id="ogrn" type="text" {...bindOgrn} placeholder="ОГРН" maxLength={15} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="okpo" className="label-middle">ОКПО</label>
															<input id="okpo" type="text" {...bindOkpo} placeholder="ОКПО" maxLength={10} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="okved" className="label-middle">ОКВЕД</label>
															<input id="okved" type="text" {...bindOkved} placeholder="ОКВЕД" />
														</div>
														<h4>Банковские реквизиты</h4>
														<div className="product-edit-row">
															<input type="text" {...bindBank} placeholder="Наименование банка" />
														</div>
														<div className="product-edit-row">
															<input type="text" {...bindAddress} placeholder="Юридический адрес" />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="bik" className="label-middle">БИК</label>
															<input id="bik" type="text" {...bindBik} placeholder="БИК" maxLength={9} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="correspondent" className="label-middle">Корр.счет</label>
															<input id="correspondent" type="text" {...bindCorrespondent} placeholder="Корреспондентский счет" maxLength={20} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="account" className="label-middle">Расчетный счет</label>
															<input id="account" type="text" {...bindAccount} placeholder="Расчетный счет" maxLength={20} />
														</div>
													</div>
												</div>
												<div className="product-edit-footer">
													<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
													<button type="submit" className="btn-save">Сохранить</button>
												</div>
											</form> : null}
									</> : null}
								{isShow || isEditShow ? null : <Empty image={'settings'} text={<>Для просмотра или редактирования,<br />выберите одну позицию из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalConfirm>
				<div className="modal">
					<div className="header">
						<h4>Смена тарифа</h4>
						<img src={imgClose} alt="" onClick={cancelPay} className="btn-close" />
					</div>
					<p>
						Ваш текущий тарифный план выше, чем тот что вы хотите выбрать.<br/>
						Продолжить и перейти к оплате?
					</p>
					<div className="buttons">
						<button type="button" onClick={cancelPay} className="btn-cancel">Отменить</button>
						<button type="button" onClick={payModal} className="btn-accept">Да</button>
					</div>
				</div>
			</ModalConfirm>
		</>
	);
};

export default AccountScreen;