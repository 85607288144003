import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import ComponentList from '../../../Components/ComponentList';
import ComponentSemifinishInline from '../../../Components/ComponentSemifinishInline';
import ComponentPopup from '../../../Components/ComponentPopup';
import Accordion from '../../../Components/Accordion';

// models
import {Components, Semifinishes} from '../../../Models';

// constants
import { measureTypeSmall } from '../../../Globals/Constants';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';


// start
const SemifinishesScreen = () => {
	const [loading, setLoading] = useState(true);
	const [semifinishes, setSemifinishes] = useState(null);
	const [semifinishesAll, setSemifinishesAll] = useState([]);
	const [components, setComponents] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:total, setValue:setTotal, bind:bindTotal} = useInput('');
	const {value:minimum, setValue:setMinimum, bind:bindMinimum} = useInput('');
	const {value:requestCount, setValue:setRequestCount, bind:bindRequestCount} = useInput('');
	const [brutto, setBrutto] = useState(0);
	const [netto, setNetto] = useState(0);
	const [loss, setLoss] = useState(0);
	const [items, setItems] = useState([]);
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await componentsGet();
			await semifinishesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const componentsGet = async () => {
		const components = await Components.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (components === undefined) return;
		setComponents(components);
	};
	const semifinishesGet = async () => {
		const semifinishes = await Semifinishes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (semifinishes === undefined) return;
		setSemifinishes(semifinishes);
		setSemifinishesAll(semifinishes);
	};
	const semifinishAdd = () => show();
	const semifinishEdit = (semifinish) => {
		semifinish = semifinish || semifinishes.find(f => f._id === id);
		show(semifinish);
	};
	const semifinishShow = (semifinish) => show(semifinish);
	const show = (semifinish) => {
		setId(semifinish?._id||null);
		setName(semifinish?.name||'');
		setDescription(semifinish?.description||'');
		setTotal(semifinish?.total||'');
		setMinimum(semifinish?.options?.minimum||'');
		setRequestCount(semifinish?.options?.requestCount||'');
		setBrutto(semifinish?.properties?.brutto||0);
		setNetto(semifinish?.properties?.netto||0);
		setLoss(semifinish?.properties?.loss||0);
		setItems(semifinish?.items||[]);
	};
	const cancel = () => semifinishShow(null);
	const save = async () => {
		const data = {
			name:name,
			description:description,
			total:calcPriceGet(),
			brutto:calcBruttoGet(),
			netto:calcNettoGet(),
			measure:measureTypeSmall.GRAM,
			items:items,
			requestCount:requestCount,
			minimum:minimum
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название полуфабриката');
			return;
		}
		if (!data.items || data.items.length === 0) {
			errorShow('Необходимо добавить компоненты');
			return;
		}
		const res = id ?
				await Semifinishes.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Semifinishes.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			semifinishesGet();
			cancel();
			return true;
		}
	}
	const searchSemifinish = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setSemifinishes(semifinishesAll);
		else setSemifinishes(semifinishesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const semifinishDelete = (id) => {
		Semifinishes.remove(id).then((res) => {
			successShow(res.message);
			semifinishesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const calcPriceGet = () => Number(items.reduce((acc, v) => acc += parseFloat(v.total), 0).toFixed(2));
	const calcBruttoGet = () => Number(items.reduce((acc, v) => acc += parseFloat(v.brutto), 0).toFixed(3));
	const calcNettoGet = () => Number(items.reduce((acc, v) => acc += parseFloat(v.netto), 0).toFixed(3));
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = semifinishes.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					description:s.description,
					total:s.total,
					brutto:s.properties.brutto,
					netto:s.properties.netto,
					items:s.items,
					requestCount:s.options.requestCount,
					minimum:s.options.minimum
				};
				await Semifinishes.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		semifinishesGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await Semifinishes.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		semifinishesGet();
		cancel();
		return true;
	};
	const componentChange = (items) => setItems([...items]);
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Полуфабрикаты"
							type="books"
							data={semifinishes}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'},
								{title:'Выход',field:'weight',class:'weight',func:(v) => v.properties.netto,sort:'properties.netto'},
								{title:'Цена',field:'total',class:'prices'}
							]}
							rowsShort={['name','total']}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первый<br/>полуфабрикат</>}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<ComponentList items={items} />
								<Accordion title="Выход">
									<div className="product-view-row product-view-row-oneline">
										<span>Брутто</span>
										<div>{brutto} г</div>
									</div>
									<div className="product-view-row product-view-row-oneline">
										<span>Нетто</span>
										<div>{netto} г</div>
									</div>
									<div className="product-view-row product-view-row-oneline">
										<span>Потери</span>
										<div>{loss} г</div>
								</div>
								</Accordion>
								<Accordion title="Складской учет">
									<div className="product-view-row product-view-row-oneline">
										<span>Минимальный остаток на складе</span>
										<div>{minimum?`${minimum} шт.`:'–'}</div>
									</div>
									<div className="product-view-row product-view-row-oneline">
										<span>Количество для заявки по умолчанию</span>
										<div>{requestCount?`${requestCount} шт.`:'–'}</div>
									</div>
								</Accordion>
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название полуфабриката" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
								</div>
								<ComponentSemifinishInline
									title="Состав"
									data={{components,semifinishes}}
									selected={items}
									onUpdate={componentChange}
									additionButtonComponent={<>
										<ComponentPopup title={'Найти позиции'} data={{components,semifinishes}} selected={items} allow={['component','semifinish']} onUpdate={componentChange} />
									</>} />
								<Accordion title="Складской учет">
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="minimum" className="label-middle">Минимальный остаток на складе</label>
										<input id="minimum" {...bindMinimum} type="text" placeholder="0" maxLength={4} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="requestcount" className="label-middle">Количество для заявки по умолчанию</label>
										<input id="requestcount" {...bindRequestCount} type="text" placeholder="0" maxLength={4} />
									</div>
								</Accordion>
							</>}
							onDelete={semifinishDelete}
							onEdit={semifinishEdit}
							onAdd={semifinishAdd}
							onSave={save}
							onSearch={searchSemifinish}
							onShow={semifinishShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default SemifinishesScreen;