import React, {useEffect,useState} from 'react';

// pluh-ins
import { hexToCSSFilter } from 'hex-to-css-filter';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import PointsSelect from '../../../Components/PointsSelect';

// models
import {Tags, Points} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { API } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgNoImage from './Images/no-image.svg';
import iconCheck from './Images/icons/icon-check.svg';
import iconFire from './Images/icons/icon-fire.svg';
import iconAsterisk from './Images/icons/icon-asterisk.svg';
import iconHeart from './Images/icons/icon-heart.svg';
import iconStar from './Images/icons/icon-star.svg';
import iconThumb from './Images/icons/icon-thumb.svg';

// icons
const icons = [
	{img:iconCheck,id:'icon-check.svg'},
	{img:iconFire,id:'icon-fire.svg'},
	{img:iconAsterisk,id:'icon-asterisk.svg'},
	{img:iconHeart,id:'icon-heart.svg'},
	{img:iconStar,id:'icon-star.svg'},
	{img:iconThumb,id:'icon-thumb.svg'}
];


// start
const TagsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [partnerId, setPartnerId] = useState(null);
	const [tags, setTags] = useState(null);
	const [tagsAll, setTagsAll] = useState(null);
	const [id, setId] = useState(null);
	const {value:text, setValue:setText, bind:bindText} = useInput('');
	const {value:color, setValue:setColor, bind:bindColor} = useInput('');
	const {value:colorText, setValue:setColorText, bind:bindColorText} = useInput('');
	const [icon, setIcon] = useState(null);
	const [image, setImage] = useState(null);
	const [imageName, setImageName] = useState(null);
	const [points, setPoints] = useState([]);
	const [tagPoints, setTagPoints] = useState([]);
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await pointsGet();
			await tagsGet();
			const point = ls('point');
			setPartnerId(point.partnerId);
		};
		await dataGet();
		setLoading(false);
	}, []);
	const tagsGet = async () => {
		const tags = await Tags.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (tags === undefined || tags.length === 0) return;
		setTags(tags);
		setTagsAll(tags);
	};
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const tagAdd = () => show();
	const tagEdit = (tag) => {
		tag = tag || tags.find(f => f._id === id);
		show(tag);
	};
	const tagShow = (tag) => show(tag);
	const show = (tag) => {
		setId(tag?._id||null);
		setText(tag?.text||'');
		setColor(tag?.options.color||'#eeeeee');
		setColorText(tag?.options.colorText||'#000000');
		setTagPoints(tag ? tag.points : points.map(v => ({id:v._id,name:v.name})));
		setIcon(tag?.icon||null);
		setImage(tag && tag.image ? imageUrlGet(partnerId, tag._id, tag.image) : null);
		setImageName(tag?.image||null);
	};
	const cancel = () => tagShow(null);
	const save = async () => {
		const data = {
			text:text,
			color:color||'#eeeeee',
			colorText:colorText||'#000000',
			points:tagPoints,
			icon:icon
		};
		if (Utils.empty(data.text) && !data.icon && !image) {
			errorShow('Необходимо заполнить хотя бы одно поле (текст, иконка или изображение)');
			return;
		}
		if (data.points.length === 0) {
			errorShow('Необходимо выбрать филиалы');
			return;
		}
		const res = id ?
				await Tags.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Tags.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			if (image) imageUpload(id||res.id);
			successShow(res.message);
			tagsGet();
			cancel();
			return true;
		}
	}
	const imageUpload = async (id) => {
		const data = imagePrepare(image);
		if (data) {
			const type = Utils.imageExtGet(image);
			Tags.image.add(id, {data,type}).catch((ex) => console.log(666, ex));
		}
	}
	const imagePrepare = (image) => Utils.isImage(image) ? image.replace(/^.*,/gi,'') : null;
	const imageUrlGet = (partnerId, id, filename) => `${API.assets}partners/${partnerId}/producttags/${id}/${filename}`;
	const imageError = (e) => {
		e.target.src = imgNoImage;
		e.target.className = 'image image-error';
	};
	const imageDelete = () => setImage(null);
	const handleImage = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setImage(r.target.result);
		reader.readAsDataURL(file);
	};
	const searchTag = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setTags(tagsAll);
		else setTags(tagsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const tagDelete = (id) => {
		Tags.remove(id).then((res) => {
			successShow(res.message);
			tagsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const pointSelect = (point) => {
		const p = tagPoints.find(f => f.id === point._id);
		if (p) setTagPoints(tagPoints.filter(f => f.id !== point._id));
		else setTagPoints([...tagPoints, {id:point._id,name:point.name}]);
	};
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = tags.find(f => f._id === v);
			if (s) {
				const data = {
					text:`${s.text} (копия)`,
					color:s.options.color,
					colorText:s.options.colorText,
					points:s.points,
					icon:s.icon,
					image:s.image
				};
				const res = await Tags.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
				if (res) {
					if (s.image) Tags.image.copy(s._id, res.id).catch((ex) => console.log(666, ex));
				}
			}
		};
		successShow('Операция прошла успешно');
		tagsGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await Tags.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		tagsGet();
		cancel();
		return true;
	};
	const iconSet = (v) => {
		if (icon === v) setIcon(null);
		else setIcon(v);
	};
	const imageGet = (v) => v.image ? <img src={imageUrlGet(partnerId, v._id, v.image)} alt="" onError={imageError} /> : (v.icon ? <img src={icons.find(f => f.id === v.icon).img} alt="" /> : null);
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Ярлыки товаров"
							type="books"
							data={tags}
							id={id}
							sortIndex={1}
							search={search}
							rows={[
								{title:'',field:'image',class:'icon',func:imageGet},
								{title:'Название',field:'name',func:(v) => v.text||'Ярлык'},
							]}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первый<br/>ярлыки</>}
							contentShowTitle={text}
							contentShow={<>
								{text || icon || image ?
									<>
										<h4>Пример отображения</h4>
										<div className="product-view-row product-view-row-simple">
											<span className="tag-icon" style={{backgroundColor:color}}>
												{text && !image && !icon ? <span style={{color:colorText}}>{text}</span> : null}
												{icon ?
													<>
														<img id="tagimage" src={icons.find(f => f.id === icon).img} alt="" />{text ? <span style={{color:colorText}}>{text}</span> : null}
													</> : null}
												{image ? <>
														<img src={image} alt="" />
														{text ? <span style={{color:colorText}}>{text}</span> : null}
													</> : null}
											</span>
											<style>{`#tagimage{filter:${hexToCSSFilter(colorText).filter}}`}</style>
										</div>
									</> : null}
								<PointsSelect isShow={true} selected={tagPoints} />
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindText} placeholder="Текст ярлыка" className="input-title" autoFocus={true} required />
								</div>
								{text || icon || image ?
									<div className="product-edit-row">
										<label><b>Пример отображения</b></label>
										<div>
											<span className="tag-icon" style={{backgroundColor:color}}>
												{text && !image && !icon ? <span style={{color:colorText}}>{text}</span> : null}
												{icon ?
													<>
														<img id="tagimage" src={icons.find(f => f.id === icon).img} alt="" />{text ? <span style={{color:colorText}}>{text}</span> : null}
													</> : null}
												{image ? <>
														<img src={image} alt="" />
														{text ? <span style={{color:colorText}}>{text}</span> : null}
													</> : null}
											</span>
										</div>
										<style>{`#tagimage{filter:${hexToCSSFilter(colorText).filter}}`}</style>
									</div> : null}
								<h4>Иконка</h4>
								<div className="tags-icon-container">
									<ul className="icons">
										{icons.map((v,i) => <li key={i} className={`icon${icon===v.id?' select':''}`} onClick={() => iconSet(v.id)}>
											<img src={icons.find(f => f.id === v.id).img} alt="" />
										</li>)}
									</ul>
								</div>
								<h4>Свое изображение</h4>
								<div className="info">Рекомендуемый формат изображения SVG (16x16 пикс.)</div>
								<div className="product-edit-row">
									{image ?
										<div className="product-edit-images">
											<div className="product-edit-image">
												<img src={image} alt="" onError={imageError} />
												<div className="delete" onClick={imageDelete}></div>
											</div>
										</div> : null}
									<label>
										<div className="product-edit-btn">
											Добавить фото
											<input type="file" accept="image/jpeg,image/png,image/webp,image/svg+xml" onChange={handleImage} />
										</div>
									</label>
								</div>
								<h4>Параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="color" className="label-middle">Цвет фона</label>
									<div className="input">
										<input type="color" id="color" {...bindColor} className="color" />
									</div>
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="colorText" className="label-middle">Цвет текста</label>
									<div className="input">
										<input type="color" id="colorText" {...bindColorText} className="color" />
									</div>
								</div>
								<PointsSelect isEdit={true} points={points} selected={tagPoints} onSelect={pointSelect} />
							</>}
							onDelete={tagDelete}
							onEdit={tagEdit}
							onAdd={tagAdd}
							onSave={save}
							onSearch={searchTag}
							onShow={tagShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default TagsScreen;