import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {ExpenseCategories, Expenses} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { cashboxPaymentType, commonStatus } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgClose from './Images/close.svg';


// start
const ExpensesScreen = () => {
	const [ModalCategoryAdd, modalCategoryAddOpen, modalCategoryAddClose] = useModal('root');
	const [ModalPay, modalPayOpen, modalPayClose] = useModal('root');
	const [ModalUnpay, modalUnpayOpen, modalUnpayClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [expensesAll, setExpensesAll] = useState([]);
	const [expenses, setExpenses] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:amount, setValue:setAmount, bind:bindAmount} = useInput('');
	const {value:date, setValue:setDate, bind:bindDate} = useInput('');
	const [categoryId, setCategoryId] = useState(null);
	const [categories, setCategories] = useState([]);
	const {value:categoryName, setValue:setCategoryName, bind:bindCategoryName} = useInput('');
	const [status, setStatus] = useState(null);
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await categoriesGet();
			await expensesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const categoriesGet = async () => {
		const categories = await ExpenseCategories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setCategories(categories);
	};
	const expensesGet = async () => {
		const expenses = await Expenses.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (expenses === undefined || expenses.length === 0) return;
		setExpenses(expenses);
		setExpensesAll(expenses);
	};
	const expenseAdd = () => show();
	const expenseEdit = (expense) => {
		expense = expense || expenses.find(f => f._id === id);
		show(expense);
	};
	const expenseShow = (expense) => show(expense);
	const show = (expense) => {
		setId(expense?._id||null);
		setName(expense?.name||'');
		setAmount(expense?.amount||'');
		setDate(Utils.dateTimeNormalize(expense, 'date'));
		setCategoryId(expense?expense.category.id:'');
		setStatus(expense?expense.status:null);
	};
	const cancel = () => {
		expenseShow(null);
		modalCategoryAddClose();
		modalPayClose();
		modalUnpayClose();
	}
	const save = async () => {
		const data = {
			name:name,
			amount:amount,
			date:date,
			categoryId:categoryId
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название причины расхода');
			return;
		}
		if (Utils.empty(data.amount)) {
			errorShow('Необходимо заполнить сумму');
			return;
		}
		if (Utils.empty(data.date)) {
			errorShow('Необходимо заполнить дату');
			return;
		}
		if (Utils.empty(data.categoryId)) {
			errorShow('Необходимо выбрать категорию');
			return;
		}
		const res = id ?
				await Expenses.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Expenses.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			expensesGet();
			cancel();
			return true;
		}
	}
	const searchExpense = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setExpenses(expensesAll);
		else setExpenses(expensesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const expenseDelete = (id) => {
		Expenses.remove(id).then((res) => {
			successShow(res.message);
			expensesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const handleCategory = (e) => setCategoryId(e.target.value);
	const categoryAddShow = () => {
		setCategoryName('');
		modalCategoryAddOpen();
	}
	const categorySave = async () => {
		if (Utils.empty(categoryName)) return;
		const data = {name:categoryName};
		const res = await ExpenseCategories.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			categoriesGet();
			modalCategoryAddClose();
		}
	}
	const payShow = (item) => expensePayShow(item, 'pay');
	const unpayShow = (item) => expensePayShow(item, 'unpay');
	const expensePayShow = (item, type) => {
		setId(item._id);
		setAmount(expenses.find(f => f._id === item._id).amount);
		if (type === 'pay') modalPayOpen();
		if (type === 'unpay') modalUnpayOpen();
	}
	const expensePay = (type, paymentType) => {
		Expenses.pay[type](id, paymentType).then((res) => {
			successShow(res.message);
			expensesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = expenses.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					amount:s.amount,
					date:s.date,
					categoryId:s.category.id
				};
				await Expenses.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		expensesGet();
		cancel();
		return true;
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Прочие расходы"
							type="money"
							data={expenses}
							id={id}
							search={search}
							rows={[
								{title:'Дата',field:'date',class:'datetime datetime-start',func:(v) => moment(v.date).format('DD.MM.YYYY HH:mm')},
								{title:'Название',field:'name'},
								{title:'Сумма',field:'amount',class:'prices'},
								{title:'Статус',field:'status',class:'status status-small',func:statusGet}
							]}
							rowsShort={['name','amount','status']}
							rowControlItems={(v) => v.status === commonStatus.ACTIVE ? [{title:'Оплатить',class:'check',action:payShow}] : [{title:'Отозвать оплату',class:'uncheck',action:unpayShow}]}
							footerItems={[
								{title:'Сделать копию',action:massCopy}
							]}
							empty={<>Добавьте первую<br/>расходную запись</>}
							contentShowTitle={name}
							contentShow={<>
								<div className="product-view-row product-view-row-oneline">
									<span>Категория</span>
									<div>{categories.find(f => f._id === categoryId)?.name}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Дата</span>
									<div>{moment(date).format('DD.MM.YYYY HH:mm')}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Сумма</span>
									<div><b>{amount} ₽</b></div>
								</div>
								<h4>Оплата</h4>
								<div className="product-edit-row">
									{status === commonStatus.ACTIVE ?
											<div className="product-edit-btn" onClick={() => expensePayShow(expenses.find(f => f._id === id), 'pay')}>Оплатить из кассы</div>
										:
											<div className="product-edit-btn" onClick={() => expensePayShow(expenses.find(f => f._id === id), 'unpay')}>Отозвать оплату</div>
									}
								</div>
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Наименование" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
									<div className="select select-wide">
										<select onChange={handleCategory} value={categoryId} required>
											<option value="">Выберите категорию</option>
											{categories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
									<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => categoryAddShow()} />
								</div>
								<h4>Параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="date" className="label-middle">Дата операции</label>
									<input id="date" {...bindDate} type="datetime-local" required />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="amount" className="label-middle">Сумма, ₽</label>
									<input id="amount" {...bindAmount} type="text" placeholder="0" required />
								</div>
							</>}
							onDelete={expenseDelete}
							onEdit={expenseEdit}
							onAdd={expenseAdd}
							onSave={save}
							onSearch={searchExpense}
							onShow={expenseShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalCategoryAdd>
				<div className="modal modal-component-add">
					<div className="header">
						<h4>Добавить категорию</h4>
						<img src={imgClose} alt="" onClick={modalCategoryAddClose} className="btn-close" />
					</div>
					<input type="text" {...bindCategoryName} placeholder="Название категории" autoFocus={true} />
					<div className="buttons">
						<button type="button" onClick={modalCategoryAddClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={categorySave} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalCategoryAdd>
			<ModalPay>
				<div className="modal modal-expense">
					<div className="header">
						<h4>Оплата расходной операции</h4>
						<img src={imgClose} alt="" onClick={modalPayClose} className="btn-close" />
					</div>
					<p>Будет произведена операция списания из кассы на сумму <b>{amount}₽</b></p>
					<div className="buttons">
						<button type="button" onClick={modalPayClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={() => expensePay('pay', cashboxPaymentType.CASH)} className="btn-accept">Наличные</button>
						<button type="button" onClick={() => expensePay('pay', cashboxPaymentType.CARD)} className="btn-accept">Безналичные</button>
					</div>
				</div>
			</ModalPay>
			<ModalUnpay>
				<div className="modal">
					<div className="header">
						<h4>Отзыв расходной операции</h4>
						<img src={imgClose} alt="" onClick={modalUnpayClose} className="btn-close" />
					</div>
					<p>
						Если смена открыта, изъятие из кассы на сумму оплаты <b>{amount}₽</b> будет удалено.<br/>
						Если смена закрыта, то будет произведен возврат средств (внесение) за оплату в кассу.
					</p>
					<div className="buttons">
						<button type="button" onClick={modalUnpayClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={() => expensePay('unpay')} className="btn-accept btn-delete">Отозвать</button>
					</div>
				</div>
			</ModalUnpay>
		</>
	);
};

export default ExpensesScreen;