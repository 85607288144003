// styles
import './styles.css';

const NotFound = () => (
	<div className="notfound">
		<h4>Не найдено</h4>
		<p>
			Cмягчите условия поиска или фильтра<br/>
			Задайте поиск по-другому или установите более мягкие ограничения
		</p>
	</div>
);
export default NotFound;