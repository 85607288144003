import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import { useHistory } from "react-router-dom";

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import Moment from 'moment';
import {YMaps,Map,ZoomControl,Placemark} from 'react-yandex-maps';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import OrderInfo from '../../../Components/OrderInfo';

// models
import { Addresses, Clients, Orders, OrderStatuses, Points, Settings, Users } from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Orfo from '../../../Globals/Orfo';

// constants
import { orderStatus, orderStatusName, sourceTypeName, paymentTypeName, userType, userTypeName, MAP, TIMERS, promoCodeType } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClock from './Images/clock-black.svg';
import imgClockRed from './Images/clock-red.svg';
import imgPayment from './Images/payment.svg';
import imgDiscount from './Images/discount.svg';
import imgUser from './Images/user.svg';
import imgBtnCall from './Images/call-btn.svg';
import imgBtnPrint from './Images/print-btn.svg';
import imgBtnPrintList from './Images/print-btn2.svg';
import imgClose from './Images/close.svg';
import imgCutlery from './Images/cutlery.svg';
import imgCutleryMain from './Images/cutlery-main.svg';
import imgPlus from './Images/plus-minus-plus.svg';
import imgMinus from './Images/plus-minus-minus.svg';
import imgLupe from './Images/lupe-btn.svg';
import imgClient from './Images/client-profile.svg';
import imgClientNo from './Images/client-no.svg';
import imgTime from './Images/clock.svg';
import imgEdit from './Images/edit-btn.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgOrder from './Images/check-btn.svg';
import imgDelete from './Images/delete.svg';
import imgCalendar from './Images/calendar.svg';

// variables
let timerId = null;


// start
const OrdersScreen = (props) => {
	const history = useHistory();
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root');
	const [ModalMap, modalMapOpen, modalMapClose] = useModal('root');
	const [ModalUsers, modalUsersOpen, modalUsersClose] = useModal('root');
	const [ModalOrders, modalOrdersOpen, modalOrdersClose] = useModal('root');
	const [ModalAddressAdd, modalAddressAddOpen, modalAddressAddClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [loadingInner, setLoadingInner] = useState(false);
	const [orders, setOrders] = useState([]);
	const [ordersAll, setOrdersAll] = useState(null);
	const [ordersSearch, setOrdersSearch] = useState(null);
	const [points, setPoints] = useState([]);
	const [order, setOrder] = useState(null);
	const [orderStatuses, setOrderStatuses] = useState([]);
	const [addressId, setAddressId] = useState(null);
	const {value:addressName, setValue:setAddressName, bind:bindAddressName} = useInput('');
	const {value:addressRoom, setValue:setAddressRoom, bind:bindAddressRoom} = useInput('');
	const {value:addressFloor, setValue:setAddressFloor, bind:bindAddressFloor} = useInput('');
	const {value:addressEntrance, setValue:setAddressEntrance, bind:bindAddressEntrance} = useInput('');
	const {value:addressIntercom, setValue:setAddressIntercom, bind:bindAddressIntercom} = useInput('');
	const {value:addressComment, setValue:setAddressComment, bind:bindAddressComment} = useInput('');
	const [addresses, setAddresses] = useState([]);
	const [address, setAddress] = useState(null);
	const [client, setClient] = useState(null);
	const [cutlery, setCutlery] = useState(1);
	const [status, setStatus] = useState(null);
	const {value:comment, setValue:setComment, bind:bindComment} = useInput('');
	const [users, setUsers] = useState([]);
	const [usersAll, setUsersAll] = useState([]);
	const [workers, setWorkers] = useState([]);
	const {value:date, setValue:setDate, bind:bindDate} = useInput('');
	const {value:shipping, setValue:setShipping, bind:bindShipping} = useInput('');
	const [orderCurrent, setOrderCurrent] = useState(null);
	const [orderCurrentId, setOrderCurrentId] = useState(null);
	const [orderCurrentStatusId, setOrderCurrentStatusId] = useState(null);
	const [orderCurrentStatus, setOrderCurrentStatus] = useState(null);
	const [pointCurrent, setPointCurrent] = useState(null);
	const [ordersSelected, setOrdersSelected] = useState([]);
	const [search, setSearch] = useState(null);
	const [tab, setTab] = useState(0);
	const [settings, setSettings] = useState(null);
	const [filter, setFilter] = useState({status:0,paymentType:0,point:'',user:''});
	const [isClientShow, setIsClientShow] = useState(false);
	const [isStatusChange, setIsStatusChange] = useState(false);
	const [isSearchShow, setIsSearchShow] = useState(false);
	const [isShow, setIsShow] = useState(false);
	const [isError, setIsError] = useState(false);
	let mapRef = null, ymaps = null;
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await pointsGet();
			await settingsGet();
			await usersGet();
			await ordersGet();
			await orderStatusesGet();
		};
		await dataGet();
		setLoading(false);
		timer();
		return () => {
			clearInterval(timerId);
			timerId = null;
		};
	}, []);
	useEffect(() => {
		const id = props.match.params.id;
		if (id) {
			if (orders.length > 0) {
				const order = orders.find(f => f._id === props.match.params.id);
				if (order) orderInfoShow(order);
			}
		}
	}, [orders]);
	const ordersGet = async (id) => {
		const orders = await Orders.get.actual().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (orders === undefined) return;
		filterApply(filter, orders);
		setOrdersAll(orders);
		if (id) {
			const order = orders.find(f => f.id === id);
			orderInfoShow(order);
		}
	};
	const settingsGet = async () => {
		const codes = ['order-new-max-time','order-cook-max-time','order-delivery-max-time','order-max-time'];
		const settings = await Settings.get.bulk(codes).catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (settings === undefined) return;
		setSettings(settings);
	};
	const usersGet = async () => {
		const users = await Users.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (users === undefined) return;
		setUsers(users);
		setUsersAll(users);
	};
	const orderStatusesGet = async () => {
		const statuses = await OrderStatuses.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (statuses === undefined) {
			const statuses = Object.keys(orderStatusName).map((v,i) => ({code:i,name:orderStatusName[v],code:v,color:'#000'})).slice(1);
			setOrderStatuses(statuses);
		} else setOrderStatuses(statuses);
	};
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const settingGet = (code) => settings.find(f => f.code === code);
	const statusGet = (code) => {
		const status = orderStatuses.find(f => f.code === code);
		return status || {code,name:orderStatusName[code],color:{background:'#f2f2f3',text:'#000'}};
	}
	const timer = () => {
		timerId = setInterval(async () => {
			const id = ls('lastorder');
			if (ordersAll && ordersAll.length) {
				const lastorder = ls('lastorder');
				if (lastorder === null || lastorder !== orders[0]._id) ordersGet();
			}
		}, TIMERS.ORDERS);
	};
	const handleStatus = (e) => {
		const s = parseInt(e.target.value);
		setIsStatusChange(s !== order.status);
		setStatus(s);
	}
	const orderInfoShow = async (order) => {
		setOrder(order);
		setStatus(order.status);
		setIsShow(true);
		if (order.client) {
			setLoadingInner(true);
			await clientsGet(order.client.id).catch((ex) => console.log(666, ex));
			setLoadingInner(false);
		}
	}
	const orderInfoClose = () => {
		setOrder(null);
		if (isSearchShow) {
			setIsShow(false);
			setIsClientShow(false);
		} else {
			setSearch('');
			setOrdersSearch(null);
			setIsStatusChange(false);
			setIsShow(false);
			setIsSearchShow(false);
			setIsClientShow(false);
			setClient(null);
		}
	}
	const orderStatusChange = async () => {
		const id = order._id;
		const data = {
			status: isStatusChange ? status : statusNextGet(order.status)
		};
		const res = await Orders.status(id, data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			ordersGet();
			orderInfoClose();
		}
	}
	const clientsGet = async (id) => {
		const client = await Clients.get.id(id).catch((ex) => console.log(666, ex));
		setClient(client||null);
		if (client) setComment(client.comment||'');
	}
	const modalAddressAddShow = (address) => {
		setAddress(address);
		setAddressId(address?._id||null);
		setAddressName(address?.address||'');
		setAddressRoom(address?.room||'');
		setAddressFloor(address?.floor||'');
		setAddressEntrance(address?.entrance||'');
		setAddressIntercom(address?.intercom||'');
		setAddressComment(address?.comment||'');
		setAddresses([]);
		modalAddressAddOpen();
	}
	const addressAdd = async () => {
		if (Utils.empty(addressName)) {
			errorShow('Необходимо заполнить адрес');
			return;
		}
		if (Utils.empty(addressRoom)) {
			errorShow('Необходимо заполнить квартиру / офис');
			return;
		}
		if (Utils.empty(address) || Utils.empty(address.latitude) || Utils.empty(address.longitude)) {
			errorShow('Необходимо выбрать адрес из списка');
			return;
		}
		const id = order.client.id;
		const data = {
			address:addressName,
			room:addressRoom,
			floor:addressFloor,
			entrance:addressEntrance,
			intercom:addressIntercom,
			comment:addressComment,
			latitude:address.latitude,
			longitude:address.longitude
		};
		const addresses = addressId ? client.addresses.map(v => v._id === addressId ? data : v) : [...client.addresses, data];
		const res = await Clients.address(id, {addresses}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			clientsGet(id);
			addressClose();
		}
	}
	const addressClose = () => {
		setAddress(null);
		setAddressName('');
		setAddressRoom('');
		setAddressFloor('');
		setAddressEntrance('');
		setAddressIntercom('');
		setAddressComment('');
		modalAddressAddClose();
	}
	const addressGet = async (e) => {
		const address = e.target.value;
		const addresses = await Addresses.get(address).catch((ex) => console.log(666, ex));
		setAddressName(address);
		setAddresses(addresses);
	}
	const selectAddress = (address) => {
		setAddress(address);
		setAddressName(address.address);
		setAddresses([]);
	}
	const ordersTotalGet = (orders, isdiscount) => orders.reduce((a,v) => a + (isdiscount ? v.amounts.amountFull : v.amounts.amount), 0);
	const ordersAverageGet = (orders) => orders.length ? Math.round(ordersTotalGet(orders, true) / orders.length) : 0;
	const ordersMedianGet = (orders) => {
		if (orders.length === 0) return 0;
		const prices = orders.map(v => v.amounts.amountFull);
		prices.sort((a, b) => a - b);
		const mid = Math.floor(prices.length / 2);
		if (prices.length % 2 !== 0) return prices[mid];
		return (prices[mid - 1] + prices[mid]) / 2;
	}
	const countCutleryMinus = () => {
		const cutleries = cutlery > 1 ? cutlery - 1 : 0;
		setCutlery(cutleries);
		patchOrder(order._id, {cutleries});
	};
	const countCutleryPlus = () => {
		const cutleries = cutlery + 1;
		setCutlery(cutleries);
		patchOrder(order._id, {cutleries});
	}
	const modalOrderHistoryShow = () => modalOrdersOpen();
	const searchPanelShow = () => {
		setOrdersSearch(null);
		setIsSearchShow(!isSearchShow);
	}
	const searchOrder = async (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setOrdersSearch(null);
		else {
			const orders = await Orders.get.search(search).catch((ex) => console.log(666, ex));
			setOrdersSearch(orders||[]);
		}
	}
	const statusNextGet = (status) => {
		if ([orderStatus.COMPLETED,orderStatus.CANCELED,orderStatus.REFUND,orderStatus.HOLD,orderStatus.HIDE,orderStatus.ARCHIVE].includes(status)) return null;
		if (status === orderStatus.NEW || status === orderStatus.PAID) {
			const s = orderStatuses.filter(f => f.code !== orderStatus.NEW && f.code !== orderStatus.PAID);
			return s[0].code;
		}
		const s = orderStatuses.findIndex(f => f.code === status);
		return orderStatuses[s+1].code;
	}
	const nextStatusCreate = (current) => {
		if (isStatusChange) {
			const name = orderStatuses.find(f => f.code === status).name;
			return <button type="button" onClick={orderStatusChange} className="btn-save">{name}</button>;
		}
		const s = statusNextGet(current);
		if (s === null) {
			const name = orderStatuses.find(f => f.code === current).name;
			return <button type="button" className="btn-disable">
				{name}
				<span>Финальный статус</span>
			</button>;
		}
		const name = orderStatuses.find(f => f.code === s).name;
		let description = '';
		switch (s) {
			case orderStatus.ACCEPTED:
				description = 'Заказ будет принят в работу';
				break;
			case orderStatus.COOK_READY:
				description = 'Заказ готов к доставке';
				break;
			case orderStatus.COOK_START:
				description = 'Начать готовить заказ';
				break;
			case orderStatus.COOK_END:
				description = 'Закончить готовить заказ';
				break;
			case orderStatus.LOGIST_READY:
				description = 'Заказ готов к доставке';
				break;
			case orderStatus.LOGIST_START:
				description = 'Начать доставку заказа';
				break;
			case orderStatus.LOGIST_END:
				description = 'Завершить доставку заказа';
				break;
			case orderStatus.DELIVERY_READY:
				description = 'Заказ готов к доставке';
				break;
			case orderStatus.DELIVERY_START:
				description = 'Начать доставку заказа';
				break;
			case orderStatus.DELIVERY_END:
				description = 'Завершить доставку заказа';
				break;
			case orderStatus.COMPLETED:
				description = 'Завершить заказ';
				break;
		}
		return <button type="button" onClick={orderStatusChange} className="btn-save">
					{name}
					<span>{description}</span>
				</button>;
	}
	const handleStatusUpdate = async (e, id, status) => {
		const s = parseInt(e.target.value);
		if ((s === orderStatus.NEW && status === orderStatus.PAID) || (s === orderStatus.PAID && status === orderStatus.NEW) || s === orderStatus.CANCELED || s === orderStatus.REFUND) {
			modalChangeStatusOpen();
			setOrderCurrentStatusId(id);
			setOrderCurrentStatus(s);
			return
		}
		statusUpdate(id, s);
	};
	const statusUpdateModal = async () => statusUpdate(orderCurrentStatusId, orderCurrentStatus);
	const statusUpdate = async (id, status) => {
		const res = await Orders.status(id, {status}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			ordersGet();
			orderInfoClose();
			setOrderCurrentStatusId(null);
			setOrderCurrentStatus(null);
			modalChangeStatusClose();
		}
	};
	const handlePaymentTypeUpdate = async (e, id) => {
		const paymentType = parseInt(e.target.value);
		const res = await Orders.patch(id, {paymentType}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			ordersGet();
			orderInfoClose();
		}
	};
	const patchOrder = async (id, data) => {
		const res = await Orders.patch(id, data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			ordersGet();
		}
	};
	const cutleryAdd = (order) => {
		const cutlery = order.options.cutleries;
		const cutleries = cutlery === 9 ? 1 : cutlery + 1;
		patchOrder(order._id, {cutleries});
	};
	const clientShow = async (order) => {
		setLoadingInner(true);
		setIsClientShow(true);
		await clientsGet(order.client.id).catch((ex) => console.log(666, ex));
		setLoadingInner(false);
	};
	const orderItemCreate = (v,i) => <div key={i} className="order-item">
			<div className="controls-row">
				<div className="number">
					<span>
						№ {v.number}
						<div className="tooltip">
							<ul>
								{v.statuses.map((s,i) => <li key={i}>
									<div className="row">
										<div>
											<div className="point" style={{background:statusGet(s.status).color.background}}></div>
											{orderStatusName[s.status]}
										</div>
										<div>{Moment(s.date).format('DD.MM.YYYY HH:mm:ss')}</div>
									</div>
									{s.user ? <div className="user">{s.user.name}</div> : null}
								</li>)}
								<li className="noborder creator">
									<div className="row">
										<div>Заказ создал</div>
										<div>{v.user?.name}</div>
									</div>
								</li>
								<li className="noborder">
									<div className="row">
										<div>Дата создания</div>
										<div>{Moment(v.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
									</div>
								</li>
							</ul>
						</div>
					</span>
				</div>
				<div>
					<div className={`block time${isDateTimeExpired(v)?' time-alert':''}`} onClick={() => dateTimeShow(v)}>
						<img src={v.options.date?(isToday(v)?(isDateTimeExpired(v)?imgClockRed:imgTime):imgCalendar):imgTime} alt="" />
						<div>{dateTimeGet(v)}</div>
					</div>
					{v._id === orderCurrentId ?
							<div className="datetime">
								{v.options.date ?
										<>
											<b>{isToday(v) ? 'Заказ на время' : 'Заказ на дату'}</b>
											<input type="datetime-local" {...bindDate} onChange={dateChange} />
										</>
									:
										<>
											<b>Должно быть готово к</b>
											<input type="datetime-local" {...bindShipping} onChange={shippingChange} />
										</>
								}
								<img src={imgClose} alt="" onClick={() => setOrderCurrentId(null)} />
							</div>
						: null}
				</div>
				<div className="status">
					<div className="select" style={{backgroundColor:statusGet(v.status).color.background}}>
						<select onChange={(e) => handleStatusUpdate(e, v._id, v.status)} value={v.status} style={{color:statusGet(v.status).color.text}}>
							{orderStatuses.map((v,i) => <option key={i} value={v.code}>{v.name}</option>)}
						</select>
					</div>
				</div>
				<div className="payment-type">
					<div className="select">
						<select onChange={(e) => handlePaymentTypeUpdate(e, v._id)} value={v.properties.paymentType}>
							{paymentTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
						</select>
					</div>
				</div>
				<div className="block cutlery" onClick={() => cutleryAdd(v)}>
					<img src={imgCutleryMain} alt="" />
					<div>{v.options.cutleries}</div>
				</div>
				{v.client ?
						<div className="block client" onClick={() => clientShow(v)}>
							<img src={imgClient} alt="" />
						</div>
					:
						<div className="block client client-no">
							<img src={imgClientNo} alt="" />
						</div>
				}
				<div className="workers">
					{v.workers && v.workers.length > 0 ?
							<>
								{v.workers.slice(0,3).map((w,i) => <div key={i} className="worker" onClick={() => userShow(v)}>
									<span>{userTypeName[w.type]}</span>
									<div>{w.name}</div>
								</div>)}
								{v.workers.length > 3 ? <div className="worker worker-more">+{v.workers.length - 3}</div> : null}
							</>
						:
							<div className="worker worker-empty">
								Нет сотрудников
							</div>
					}
					<div className="plus">
						<img src={imgPlus} alt="" onClick={() => userShow(v)} />
					</div>
				</div>
				<div className="controls">
					<img src={imgOrder} alt="" onClick={() => orderInfoShow(v)} />
					{v.inventory ? null : <img src={imgEdit} alt="" onClick={() => goto(`order-edit/${v._id}`)} />}
					<img src={imgBtnPrintList} alt="" onClick={() => printCheck()} />
				</div>
			</div>
			<div className="order-item-info">
				<div className="more" onClick={() => orderShowMore(v._id)}>
					{isShowMore(v._id) ? 'Скрыть состав' : 'Состав заказа'}
				</div>
				<div className="price">
					{Utils.moneyFormat(v.amounts.amountFull, true)} ₽
				</div>
				<div className="discount">
					{Utils.moneyFormat(v.discounts.discount, true)} ₽
				</div>
				<div className="delivery">
					{v.properties.isPickup ? 'Самовывоз' : <>Доставка <b>{Utils.moneyFormat(v.amounts.delivery, false)}₽</b></>}
				</div>
				<div className="address">
					{v.properties.isPickup ? <><span>Самовывоз с</span> {v.point?.name}</> : <>{v.address.address} <div className="map" onClick={() => mapShow(v)}>карта</div></>}
				</div>
				<div className="comment">
					{v.comment}
				</div>
			</div>
			{isShowMore(v._id) ?
					<div className="order-item-products">
						{v.products.map((p,i) => <React.Fragment key={i}>
							<div className="product">
								<div className="name">{p.name}</div>
								<div className="quantity">{p.quantity} шт.</div>
								<div className="price">{Utils.moneyFormat(p.priceDiscount===undefined?p.price:p.priceDiscount, true)} ₽</div>
							</div>
							{p.selectedOptions && p.selectedOptions.length > 0 ?
									p.selectedOptions.map((o,j) => <div key={j} className="product product-option">
										<div className="name">{o.name}</div>
										<div className="quantity">{p.quantity} шт.</div>
										<div className="price">{Utils.moneyFormat(o.price, true)} ₽</div>
									</div>)
								: null}
						</React.Fragment>)}
						<div className="product">
							<div className="name"><b>Итого</b></div>
							<div className="quantity"></div>
							<div className="price"><b>{Utils.moneyFormat(totalOrderGet(v.products), true)}</b> ₽</div>
						</div>
					</div>
				: null}
		</div>;
	const dateTimeGet = (order) => {
		const date = order.options.date;
		if (date) {
			if (isToday(order)) return Moment(date).format('HH:mm');
			return Moment(date).format('DD.MM');
		} else {
			const shipping = order.options.shipping;
			if (shipping) return Moment(shipping).format('HH:mm');
			return Moment(order.createdAt).add(settingGet('order-max-time').value, 'minutes').format('HH:mm');
		}
	};
	const isDateTimeExpired = (order) => {
		const date = order.options.date || order.options.shipping;
		if (!date) return false;
		const now = Moment();
		const orderDate = Moment(date);
		return now > orderDate;
	};
	const isToday = (order) => {
		const date = order.options.date;
		if (!date) return false;
		const today = Moment().format('YYYY-MM-DD');
		const orderDate = Moment(date).format('YYYY-MM-DD');
		return today === orderDate;
	};
	const dateTimeShow = (order) => {
		const date = order.options.date;
		if (order._id === orderCurrentId) {
			order = null;
			setOrderCurrentId(null);
		}
		else setOrderCurrentId(order._id);
		setOrderCurrent(order);
		if (order) {
			if (date) {
				setDate(Moment(date).format('YYYY-MM-DDTHH:mm'));
				setShipping('');
			} else {
				setDate('');
				const shipping = order.options.shipping ? Utils.dateFullNormalize(order.options, 'shipping') : Moment(order.createdAt).add(settingGet('order-max-time').value, 'minutes').format('YYYY-MM-DDTHH:mm');
				setShipping(shipping);
			}
		}
	};
	const dateChange = async (e) => {
		const date = e.target.value;
		patchOrder(orderCurrent._id, {date});
		setDate(date);
	};
	const shippingChange = async (e) => {
		const shipping = e.target.value;
		patchOrder(orderCurrent._id, {shipping});
		setShipping(shipping);
	};
	const totalOrderGet = (items) => {
		const price = items.reduce((a,v) => a + ((v.priceDiscount===undefined?v.price:v.priceDiscount) * v.quantity), 0);
		const options = items.reduce((a,v) => {
			if (v.selectedOptions) {
				v.selectedOptions.forEach((o) => {
					a += o.price * v.quantity;
				});
			}
			return a;
		}, 0);
		return price + options;
	};
	const mapShow = (order) => {
		const point = points.find(f => f._id === order.point.id);
		setPointCurrent(point);
		setOrderCurrent(order);
		modalMapOpen();
	}
	const orderShowMore = (id) => {
		if (ordersSelected.includes(id)) {
			const idx = ordersSelected.indexOf(id);
			ordersSelected.splice(idx, 1);
		} else ordersSelected.push(id);
		setOrdersSelected([...ordersSelected]);
	};
	const isShowMore = (id) => ordersSelected.includes(id);
	const clientCommentSave = async (e) => await Clients.patch(client._id, {comment:e.target.value}).catch((ex) => console.log(666, ex));
	const userShow = (order) => {
		const workers = order.workers?order.workers:[];
		const users = usersAll.sort((a,b) => workers.find(f => f.id === a._id) ? -1 : 1);
		setUsers(users);
		setWorkers(workers);
		setOrderCurrent(order);
		modalUsersOpen();
	}
	const userClose = () => {
		setUsers(usersAll);
		setWorkers([]);
		setOrderCurrent(null);
		modalUsersClose();
	};
	const searchUser = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setUsers(usersAll);
		else setUsers(usersAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	};
	const userSelect = async (user) => {
		let w = undefined;
		if (workers.find(f => f.id === user._id) === undefined) {
			if (user.type !== userType.UNKNOWN) {
				if (user.type === userType.COURIER) {
					if (workers.find(f => f.type === userType.COURIER) !== undefined) {
						workers.forEach((v,i) => {
							if (v.type === userType.COURIER) {
								workers[i] = {id:user._id,type:user.type};
							}
						});
						w = workers;
						successShow('Курьер переназначен');
					}
				}
				if (w === undefined) w = [...workers,{id:user._id,type:user.type}];
			}
		} else w = workers.filter(f => f.id !== user._id);
		await Orders.workers(orderCurrent._id, {workers:w}).catch((ex) => console.log(666, ex));
		await ordersGet();
		setWorkers(w);
	};
	const filterStatusSet = (e) => {
		const status = parseInt(e.target.value);
		filterApply({...filter,status});
	};
	const filterPaymentTypeSet = (e) => {
		const paymentType = parseInt(e.target.value);
		filterApply({...filter,paymentType});
	};
	const filterPointSet = (e) => {
		const point = e.target.value;
		filterApply({...filter,point});
	};
	const filterUserSet = (e) => {
		const user = e.target.value;
		filterApply({...filter,user});
	};
	const filterApply = (filter, orders) => {
		let result = orders || ordersAll;
		if (filter.status) result = result.filter(f => f.status === filter.status);
		if (filter.paymentType) result = result.filter(f => f.properties.paymentType === filter.paymentType);
		if (filter.point) result = result.filter(f => f.point?.id === filter.point);
		if (filter.user) result = result.filter(f => f.workers.find(w => w.id === filter.user));
		setFilter(filter);
		setOrders(result);
	};
	const mapLoad = (y) => ymaps = y;
	const mapRouteSet = () => {
		mapRef.geoObjects.removeAll();
		ymaps.ready([]).then(() => {
			const coords = [
				{type:'wayPoint',point:[orderCurrent.address.latitude,orderCurrent.address.longitude]},
				{type:'wayPoint',point:[pointCurrent.properties.latitude,pointCurrent.properties.longitude]}
			];
			ymaps.route(coords, {mapStateAutoApply:true,reverseGeocoding:true}).then((route) => mapRef.geoObjects.add(route));
		});
	};
	const goto = (link) => history.push(`/${link}`);
	const printCheck = () => {};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<>
							<div className="orders-container">
								<div>
									<div className="orders-info-block">
										<span>Заказы <b>{(isSearchShow?ordersSearch||[]:orders).length}</b> шт.</span>
										<span>Средний чек <b>{Utils.moneyFormat(ordersMedianGet(isSearchShow?ordersSearch||[]:orders))}</b> ₽</span>
										<span>Выручка <b>{Utils.moneyFormat(ordersTotalGet(isSearchShow?ordersSearch||[]:orders))}</b> ₽</span>
									</div>
									<div className="header">
										<div className="switchers">
											<div className="select">
												<select onChange={filterPointSet} value={filter.point}>
													<option value="">Все филиалы</option>
													{points.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
												</select>
											</div>
											<div className="select">
												<select onChange={filterStatusSet} value={filter.status}>
													<option value="0">Все статусы</option>
													{orderStatuses.map((v,i) => <option key={i} value={v.code}>{v.name}</option>)}
												</select>
											</div>
											<div className="select select-paymenttype">
												<select onChange={filterPaymentTypeSet} value={filter.paymentType}>
													<option value="0">Все оплаты</option>
													{paymentTypeName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
												</select>
											</div>
											<div className="select">
												<select onChange={filterUserSet} value={filter.user}>
													<option value="">Все сотрудники</option>
													{users.filter(f => f.properties?.isOrderAttach).map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
												</select>
											</div>
										</div>
										<div className="controls">
											{isSearchShow ?
													<input type="search" placeholder="Номер заказа, имя или телефон клиента" className="search" onChange={searchOrder} autoFocus={true} />
												: null}
											<img src={isSearchShow?imgDelete:imgLupe} alt="" className="orders-search-icon" onClick={() => searchPanelShow()} />
										</div>
									</div>
								</div>
								<div className={`main${isSearchShow?(orders.length?'':' main-empty'):(ordersSearch&&ordersSearch.length?'':' main-empty')}`}>
									{isSearchShow ?
											(
												ordersSearch === null ? null :
													(ordersSearch.length ?
															ordersSearch.map((v,i) => orderItemCreate(v,i))
														:
															<div className="empty">
																<Empty image={'orders'} text={'Ничего не найдено'} />
															</div>
													)
											)
										:
											(orders.length > 0 ?
													orders.map((v,i) => orderItemCreate(v, i))
												:
													<div className="empty">
														<Empty image={'orders'} text={'Заказов пока нет'} />
													</div>
											)
									}
								</div>
							</div>
							<div className={`order-overlay${isShow || isClientShow ?' order-overlay-show':''}`} onClick={() => orderInfoClose()}></div>
							{isClientShow ?
									<div className="order-info-panel order-info-panel-show">
										<div className="order-info-panel-inner">
											{loadingInner ? <Loader /> :
												<>
													<div className="order-header order-header-wide order-info-oneline-wide">
														<h3>{client.name}</h3>
														<div className="order-info-oneline">
															<a href={`tel:${Utils.phoneNormalize(client.phone)}`}><img src={imgBtnCall} alt="" /></a>
															<div className="order-info-panel-close" onClick={orderInfoClose}>
																<img src={imgClose} alt="" />
															</div>
														</div>
													</div>
													<div className="order-info-main order-info-main-client">
														<div className="order-info-main-inner">
															<div className="order-info-row order-info-oneline-wide">
																<label>Телефон</label>
																<div>{Utils.phoneFormatter(client.phone)}</div>
															</div>
															<div className="order-info-row order-info-oneline-wide">
																<label>Имя</label>
																<div>{client.name||'–'}</div>
															</div>
															{client.birthdate ?
																<div className="order-info-row order-info-oneline-wide">
																	<label>Дата рождения</label>
																	<div>{Moment(client.birthdate).format('D MMMM YYYY')} ({Orfo.counters.years(Moment().diff(client.birthdate, 'years', false))})</div>
																</div> : null}
															<div className="order-info-row order-info-oneline-wide">
																<label>Скидка</label>
																<div>{client.discount||0} %</div>
															</div>
															<div className="order-info-row order-info-oneline-wide">
																<label>Дата регистрации</label>
																<div>{Moment(client.createdAt).format('D MMMM YYYY')}</div>
															</div>
															<h4>Комментарий</h4>
															<div className="order-info-row order-info-row-noborder">
																<input type="text" {...bindComment} placeholder="Комментарий" className="input-client" value={comment} onBlur={clientCommentSave} />
															</div>
															<h4>Адреса доставки</h4>
															<div className="order-info-row order-info-row-noborder">
																{client.addresses.length ?
																		client.addresses.map((v,i) => <div key={i} className="order-info-address" onClick={() => modalAddressAddShow(v)}>
																			{v.address}
																		</div>)
																	:
																		<div>Адреса не заданы</div>
																}
															</div>
															<div className="order-info-row order-info-row-noborder">
																<button type="button" className="order-info-btn" onClick={modalAddressAddShow}>Добавить адрес</button>
															</div>
															<h4>Статистика заказов</h4>
															<div className="order-info-row order-info-row-noborder">
																<div className="order-info-orders">
																	<div className="order-info-orderinfo">
																		<span>Количество заказов</span>
																		<div>{client?.orders?.length}</div>
																	</div>
																	<div className="order-info-orderinfo">
																		<span>Скидка</span>
																		<div>{order?.client?.discount ? `${order?.client?.discount}%` : '–'}</div>
																	</div>
																	<div className="order-info-orderinfo">
																		<span>Сумма заказов</span>
																		<div>{Utils.moneyFormat(ordersTotalGet(client.orders, true))} ₽</div>
																	</div>
																	<div className="order-info-orderinfo">
																		<span>Срдений чек</span>
																		<div>{Utils.moneyFormat(ordersAverageGet(client.orders))} ₽</div>
																	</div>
																	<div className="order-info-orderinfo">
																		<span>Медианный чек</span>
																		<div>{Utils.moneyFormat(ordersMedianGet(client.orders))} ₽</div>
																	</div>
																	<div className="order-info-orderinfo">
																		<span>Сумма заказов без скидки</span>
																		<div>{Utils.moneyFormat(ordersTotalGet(client.orders, false))} ₽</div>
																	</div>
																</div>
															</div>
															<div className="order-info-row order-info-row-noborder order-info-row-oneline-start">
																<button type="button" className="order-info-btn" onClick={modalOrderHistoryShow}>История заказов</button>
																<Link to={`/order-add/${client._id}`} className="order-info-btn order-info-btn-second">Добавить заказ</Link>
															</div>
														</div>
													</div>
												</>
											}
										</div>
									</div>
								: null}
							{order ?
									<div className={`order-info-panel${isShow?' order-info-panel-show':''}`}>
										<div className="order-info-panel-inner">
											{loadingInner ? <Loader /> :
												<>
													<div className="order-header order-info-oneline-wide">
														<div>
															<h3>Заказ № {order.number}</h3>
															<span><span>{Moment(order.createdAt).format('D MMMM H:mm')}</span> • {order.properties.isPaid ? 'онлайн' : 'не оплачен'}</span>
														</div>
														<div className={`select select-status${isStatusChange?' select-border':''}`}>
															<div>Статус заказа</div>
															<select onChange={handleStatus} value={status}>
																{orderStatuses.map((v,i) => <option key={i} value={v.code}>{v.name}</option>)}
															</select>
														</div>
														<div className="order-info-oneline">
															{order.client ?
																<a href={`tel:${Utils.phoneNormalize(order.client.phone)}`}><img src={imgBtnCall} alt="" /></a> : null}
															<img src={imgBtnPrint} alt="" onClick={() => printCheck()} />
															<div className="order-info-panel-close" onClick={orderInfoClose}>
																<img src={imgClose} alt="" />
															</div>
														</div>
													</div>
													<div className="order-tabs order-info-oneline-wide">
														<div className={`order-tab${tab===0?' order-tab-active':''}`} onClick={() => setTab(0)}>Заказ</div>
														<div className={`order-tab${tab===1?' order-tab-active':''}`} onClick={() => setTab(1)}>Клиент</div>
														<div className={`order-tab${tab===2?' order-tab-active':''}`} onClick={() => setTab(2)}>Отметки</div>
													</div>
													<div className={`order-info-main${tab === 0?' order-info-main-order':''}`}>
														{tab === 0 ?
																<>
																	<div className="order-info-oneline order-info-oneline-wrap">
																		{order.client ?
																			<div className="order-props order-props-client order-info-oneline" onClick={() => setTab(1)}>
																				<img src={imgUser} alt="" />
																				{order.client.name ? <span>{order.client.name}</span> : null}
																			</div> : null}
																		{order.options.date ?
																			<div className="order-props order-props-date order-info-oneline">
																				<img src={imgClock} alt="" />
																				<span>Время доставки: {Moment(order.options.date).format('D MMM H:mm')}</span>
																			</div> : null}
																		<div className="order-props order-props-payment order-info-oneline">
																			<img src={imgPayment} alt="" />
																			<span>{order.properties.isPaid ? 'Оплата картой онлайн' : 'Оплата при получении'}</span>
																		</div>
																		<div className="order-props order-props-discount order-info-oneline">
																			<img src={imgDiscount} alt="" />
																			<span>Размер скидки: {order.discounts.discount||0} ₽</span>
																		</div>
																	</div>
																	<h4>Адрес доставки</h4>
																	<div className="order-info-row order-info-row-noborder">
																		{order.properties.isPickup ? <b>Самовывоз</b> : order.address.address}
																	</div>
																	{order.address.comment ? 
																		<div className="order-info-row order-info-row-noborder">
																			<p><i>{order.address.comment}</i></p>
																		</div> : null}
																	{order.comment ?
																		<>
																			<h4>Комментарий клиента</h4>
																			<div className="order-info-row order-info-row-noborder">
																				<p>{order.address.comment}</p>
																			</div>
																		</> : null}
																	<h4>Позиции</h4>
																	<div className="order-info-row order-info-oneline-wide">
																		<div className="order-info-oneline">
																			<img src={imgCutlery} alt="" />
																			<label>Приборы</label>
																		</div>
																		<div className="order-info-oneline-wide">
																			<div className="order-info-counter order-info-oneline-wide">
																				<img src={imgMinus} alt="" onClick={() => countCutleryMinus()} />
																				<div className="quantity">{cutlery}</div>
																				<img src={imgPlus} alt="" onClick={() => countCutleryPlus()} />
																			</div>
																			<div className="order-info-price"></div>
																		</div>
																	</div>
																	{order.products.map((v,i) => <React.Fragment key={i}>
																		<div key={i} className="order-info-row order-info-oneline-wide">
																			<div>{v.name}</div>
																			<div className="order-info-oneline-wide">
																				<div className="order-info-counter order-info-oneline-wide">
																					<img src={imgMinus} alt="" className="inactive" />
																					<div className="quantity">{v.quantity}</div>
																					<img src={imgPlus} alt="" className="inactive" />
																				</div>
																				<div className="order-info-price">{Utils.moneyFormat(v.priceDiscount===undefined?v.price:v.priceDiscount)} ₽</div>
																			</div>
																		</div>
																		{v.selectedOptions && v.selectedOptions.length > 0 ?
																			v.selectedOptions.map((o,j) => <div key={j} className="order-info-row order-info-row-option order-info-oneline-wide">
																				<div>{o.name}</div>
																				<div className="order-info-oneline-wide">
																					<div className="order-info-counter order-info-oneline-wide">
																						<div className="inactive"></div>
																						<div className="quantity">{v.quantity}</div>
																						<div className="inactive"></div>
																					</div>
																					<div className="order-info-price">{Utils.moneyFormat(o.price)} ₽</div>
																				</div>
																			</div>) : null}
																	</React.Fragment>)}
																</>
															: null}
														{tab === 1 ?
																<div className="order-info-main-inner">
																	<h4 className="first">Информация о клиенте</h4>
																	{order.client ?
																			<>
																				<div className="order-info-row order-info-oneline-wide">
																					<label>Телефон</label>
																					<div>{Utils.phoneFormatter(order.client.phone)}</div>
																				</div>
																				<div className="order-info-row order-info-oneline-wide">
																					<label>Имя</label>
																					<div>{order.client.name}</div>
																				</div>
																				{order.client.birthdate ?
																					<div className="order-info-row order-info-oneline-wide">
																						<label>Дата рождения</label>
																						<div>{Moment(order.client.birthdate).format('D MMMM YYYY')} ({Orfo.counters.years(Moment().diff(order.client.birthdate, 'years', false))})</div>
																					</div> : null}
																				<div className="order-info-row order-info-oneline-wide">
																					<label>Скидка</label>
																					<div>{order.client.discount||0} %</div>
																				</div>
																				<h4>Телефон в заказе</h4>
																				<div className="order-info-row order-info-row-noborder">
																					<div>{Utils.phoneFormatter(order.client.phone)}</div>
																				</div>
																				<h4>Комментарий</h4>
																				<div className="order-info-row order-info-row-noborder">
																					<input type="text" {...bindComment} placeholder="Комментарий" className="input-client" value={comment} onBlur={clientCommentSave} />
																				</div>
																				<h4>Адреса доставки</h4>
																				<div className="order-info-row order-info-row-noborder">
																					{client.addresses.length ?
																							client.addresses.map((v,i) => <div key={i} className="order-info-address" onClick={() => modalAddressAddShow(v)}>
																								{v.address}
																							</div>)
																						:
																							<div>Адреса не заданы</div>
																					}
																				</div>
																				<div className="order-info-row order-info-row-noborder">
																					<button type="button" className="order-info-btn" onClick={modalAddressAddShow}>Добавить адрес</button>
																				</div>
																				<h4>Статистика заказов</h4>
																				<div className="order-info-row order-info-row-noborder">
																					<div className="order-info-orders">
																						<div className="order-info-orderinfo">
																							<span>Количество заказов</span>
																							<div>{client.orders.length}</div>
																						</div>
																						<div className="order-info-orderinfo">
																							<span>Скидка</span>
																							<div>{order.client.discount ? `${order.client.discount}%` : '–'}</div>
																						</div>
																						<div className="order-info-orderinfo">
																							<span>Сумма заказов</span>
																							<div>{Utils.moneyFormat(ordersTotalGet(client.orders, true))} ₽</div>
																						</div>
																						<div className="order-info-orderinfo">
																							<span>Срдений чек</span>
																							<div>{Utils.moneyFormat(ordersAverageGet(client.orders))} ₽</div>
																						</div>
																						<div className="order-info-orderinfo">
																							<span>Медианный чек</span>
																							<div>{Utils.moneyFormat(ordersMedianGet(client.orders))} ₽</div>
																						</div>
																						<div className="order-info-orderinfo">
																							<span>Сумма заказов без скидки</span>
																							<div>{Utils.moneyFormat(ordersTotalGet(client.orders, false))} ₽</div>
																						</div>
																					</div>
																				</div>
																				<div className="order-info-row order-info-row-noborder order-info-row-oneline-start">
																					<button type="button" className="order-info-btn" onClick={modalOrderHistoryShow}>История заказов</button>
																					<Link to={`/order-add/${client._id}`} className="order-info-btn order-info-btn-second">Добавить заказ</Link>
																				</div>
																			</>
																		:
																			<div className="order-info-empty">
																				Клиент не указан в заказе
																			</div>
																	}
																</div>
															: null}
														{tab === 2 ?
																<div className="order-info-main-inner">
																	<div className="order-info-row order-info-oneline-wide">
																		<label>Филиал</label>
																		<div>{order.point.name}</div>
																	</div>
																	{order.area ?
																		<div className="order-info-row order-info-oneline-wide">
																			<label>Точка самовывоза</label>
																			<div>{order.area.name}</div>
																		</div> : null}
																	<div className="order-info-row order-info-oneline-wide">
																		<label>Оплата</label>
																		<div>{paymentTypeName[order.properties.paymentType]}</div>
																	</div>
																	{order.promoCode?.type !== promoCodeType.UNKNOWN ?
																		<div className="order-info-row order-info-oneline-wide">
																			<label>Промокод</label>
																			<div>{order.promoCode.name} <b><code>{order.promoCode.code}</code></b></div>
																		</div> : null}
																	{order.options.surrender ?
																		<div className="order-info-row order-info-oneline-wide">
																			<label>Сдача с суммы</label>
																			<div>{order.options.surrender} ₽</div>
																		</div> : null}
																	<div className="order-info-row order-info-oneline-wide">
																		<label>Источник</label>
																		<div>{sourceTypeName[order.properties.paymentSource]}</div>
																	</div>
																	{order.options?.source ? 
																		<div className="order-info-row order-info-oneline-wide">
																			<label>Откуда пришел заказ</label>
																			<div>{order.options.source.name}</div>
																		</div> : null}
																	{order.options?.shipping ?
																		<div className="order-info-row order-info-oneline-wide">
																			<label>Дата отгрузки</label>
																			<div>{Moment(order.options.shipping).format('DD.MM.YYYY HH:mm:ss')}</div>
																		</div> : null}
																	<div className="order-info-row order-info-oneline-wide">
																		<label>Создан</label>
																		<div>{Moment(order.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
																	</div>
																	{order.dateFinish ?
																		<div className="order-info-row order-info-oneline-wide">
																			<label>Завершен</label>
																			<div>{Moment(order.dateFinish).format('DD.MM.YYYY HH:mm:ss')}</div>
																		</div> : null}
																	{order.inventory ?
																		<div className="order-info-row order-info-oneline-wide">
																			<label>Инвентаризация</label>
																			<div>{Moment(order.inventory.date).format('DD.MM.YYYY HH:mm:ss')}</div>
																		</div> : null}
																</div>
															: null}
													</div>
													{tab === 0 ?
															<div className="order-info-summary">
																<div className="order-info-summary-row order-info-oneline-wide">
																	<label>Сумма заказа</label>
																	<div>{Utils.moneyFormat(order.amounts.amount)} ₽</div>
																</div>
																<div className="order-info-summary-row order-info-oneline-wide">
																	<label>Скидка</label>
																	<div className="discount">– {Utils.moneyFormat(order.discounts.discount)} ₽</div>
																</div>
																<div className="order-info-summary-row order-info-oneline-wide">
																	<label>Доставка{order.properties.isPickup?' (самовывоз)':''}</label>
																	<div>{order.amounts.delivery} ₽</div>
																</div>
																<div className="order-info-summary-row order-info-summary-total order-info-oneline-wide ">
																	<label>Общая сумма</label>
																	<div>{Utils.moneyFormat(order.amounts.amountFull)} ₽</div>
																</div>
																<div className="buttons order-info-oneline-wide">
																	<button type="button" onClick={orderInfoClose} className="btn-cancel">Отменить</button>
																	{nextStatusCreate(order.status)}
																</div>
															</div>
														: null}
												</>
											}
										</div>
									</div>
								: null}
						</>
					)
				}
			</Template>
			<Alert />
			<ModalAddressAdd>
				<div className="modal modal-address-add">
					<div className="header">
						<h4>{address ? 'Изменить адрес' : 'Добавить новый адрес'}</h4>
						<img src={imgClose} alt="" onClick={addressClose} className="btn-close" />
					</div>
					<div className="container-inner">
						<input type="text" {...bindAddressName} placeholder="Введите адрес (улица и дом)" onChange={addressGet} />
						{addresses.length ?
							<div className="addresses-list">
								{addresses.map((v,i) => <div key={i} onClick={() => selectAddress(v)}>{v.address}</div>)}
							</div> : null}
						<div className="address-form">
							<div>
								<input type="text" {...bindAddressRoom} placeholder="Квартира, офиc" maxLength={5} required />
								<span>кв/офис</span>
							</div>
							<div>
								<input type="text" {...bindAddressEntrance} placeholder="Подъезд" maxLength={3} />
								<span>подъезд</span>
							</div>
							<div>
								<input type="text" {...bindAddressFloor} placeholder="Этаж" maxLength={2} />
								<span>этаж</span>
							</div>
							<div>
								<input type="text" {...bindAddressIntercom} placeholder="Код домофона" maxLength={6} />
								<span>домофон</span>
							</div>
						</div>
						<div className="address-form">
							<textarea {...bindAddressComment} placeholder="Комментарий к адресу"></textarea>
						</div>
					</div>
					<div className="buttons">
						<button type="button" onClick={addressClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={addressAdd} className="btn-accept">Добавить</button>
					</div>
				</div>
			</ModalAddressAdd>
			<ModalOrders>
				<div className="modal modal-orders">
					<div className="header">
						<h4>История заказов</h4>
						<img src={imgClose} alt="" onClick={modalOrdersClose} className="btn-close" />
					</div>
					<div className="container-inner">
						{client?.orders.length > 0 ?
								client.orders.map((v,i) => <OrderInfo key={i} order={v} clientHide={isClientShow} />)
							:
								<div className="orders-empty">
									Заказов пока нет
								</div>
						}
					</div>
				</div>
			</ModalOrders>
			<ModalUsers>
				<div className="modal modal-users">
					<div className="header">
						<h4>Сотрудники</h4>
						<img src={imgClose} alt="" onClick={userClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchUser} autoFocus={true} />
					<div className="users">
						<div className="list">
							{users.length ?
									users.map((v,i) => <div key={i} className="user">
										<div>
											{v.name}
											<span>{userTypeName[v.type]}</span>
										</div>
										<img src={workers.find(f => f.id === v._id) ? imgCheckOn : imgCheckOff} alt="" onClick={() => userSelect(v)} />
									</div>)
								:
									<div className="user-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</ModalUsers>
			<ModalMap>
				<div className="modal modal-map">
					<div className="map-container">
						<YMaps query={{apikey:MAP.keys.Yandex}}>
							<Map
								instanceRef={(r) => mapRef = r}
								onLoad={mapLoad}
								state={{center:orderCurrent?[orderCurrent.address.latitude,orderCurrent.address.longitude]:MAP.defCoords,zoom:14}}
								modules={['route']}
								width={'100%'} height={'100%'}
							>
								<ZoomControl />
								{orderCurrent ?
										<Placemark
											geometry={[orderCurrent.address.latitude,orderCurrent.address.longitude]}
											properties={{balloonContent:`<div><b>Заказ №${orderCurrent.number}</b><br>${orderCurrent.address.address}</div>`,hintContent:orderCurrent.address.address}}
											modules={['geoObject.addon.hint','geoObject.addon.balloon']} />
									: null}
							</Map>
						</YMaps>
						{orderCurrent ?
								<div className="map-address">
									<div>{orderCurrent.address.address}</div>
									<div className="button" onClick={() => mapRouteSet()}>Построить маршрут</div>
								</div>
							: null}
						<div className="map-close">
							<img src={imgClose} alt="" onClick={modalMapClose} className="btn-close" />
						</div>
					</div>
				</div>
			</ModalMap>
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Смена статуса</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusClose} className="btn-close" />
					</div>
					<p>Вы уверены что хотите сменить статус заказа на <b>{orderStatusName[orderCurrentStatus]}</b>?</p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={statusUpdateModal} className="btn-accept btn-delete">Да</button>
					</div>
				</div>
			</ModalChangeStatus>
		</>
	);
};

export default OrdersScreen;