import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()			=> await Http.request('tags');

const add		= async (data)		=> await Http.request('tag', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`tag/${id}`, httpMethods.PUT, data);

const remove	= async (id)		=> await Http.request(`tag/${id}`, httpMethods.DELETE);

const image = {
	add			: async (id, data)	=> await Http.request(`tag/image/${id}`, httpMethods.POST, data),
	copy		: async (id, id2)	=> await Http.request(`tag/image/copy/${id}/${id2}`, httpMethods.POST)
};


export {
	get,
	add,
	update,
	remove,
	image
}