import React, {useEffect,useState} from 'react';

// styles
import './styles.css';

// start
const Calendar = (props) => {
	const [loading, setLoading] = useState(true);
	const [now] = useState(new Date());
	const data = {
		weekDays: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
		months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
	};
	useEffect(() => {
		setLoading(false);
	}, []);
	const dateSelect = (month, day) => props.onChange ? props.onChange(`${now.getFullYear()}-${month+1}-${day}`) : null;
	const dateClassGet = (month, day) => {
		const classname = ['day'];
		if (day) {
			const date = new Date(Date.UTC(now.getFullYear(), month, day));
			const isSelect = props.dates.some(f => {
				if (!f) return false;
				const arr = f.split('-');
				if (arr.length !== 3) return false;
				const d = new Date(Date.UTC(arr[0], arr[1]-1, arr[2]));
				return d.toISOString() === date.toISOString();
			});
			if (isSelect) classname.push('select');
		}
		if (day === now.getDate() && month === now.getMonth()) classname.push('today');
		return classname.join(' ');
	};
	const createCalendar = (month) => {
		const calendar = [];
		const firstDay = new Date(now.getFullYear(), month, 1);
		const lastDay = new Date(now.getFullYear(), month + 1, 0);
		const firstDayWeek = firstDay.getDay() === 0 ? 6 : firstDay.getDay() - 1;
		const lastDayWeek = lastDay.getDay() === 0 ? 6 : lastDay.getDay() - 1;
		const days = lastDay.getDate();
		let day = 1;
		let week = [];
		for (let i = 0; i < firstDayWeek; i++) week.push('');
		for (let i = 0; i < days; i++) {
			week.push(day);
			day++;
			if (week.length === 7) {
				calendar.push(week);
				week = [];
			}
		}
		for (let i = lastDayWeek; i < 6; i++) week.push('');
		calendar.push(week);
		return calendar;
	};
	const renderCalendar = () => {
		const months = [now.getMonth()];
		const calendars = [createCalendar(months[0])];
		return calendars.map((v,idx) => <div key={idx}>
			<div className="calendar-month">{data.months[months[idx]]}</div>
			<div className="calendar-week">
				{data.weekDays.map((v, i) => <div key={i} className="weekday">{v}</div>)}
			</div>
			<div className="calendar-body">
				{v.map((w, i) => <div key={i} className="calendar-week">
					{w.map((d, j) => d ? <div key={j} className={dateClassGet(months[idx], d)} onClick={() => dateSelect(months[idx], d)}>{d}</div> : <div key={j} className="day empty"></div>)}
				</div>)}
			</div>
		</div>);
	};
	return loading ? null :
		<div className="calendar">
			{renderCalendar()}
		</div>;
};
export default Calendar;