import React, {useEffect,useState} from 'react';

// plug-ins
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import ComponentInline from '../../../Components/ComponentInline';
import ComponentPositionList from '../../../Components/ComponentPositionList';
import ComponentPopupStorage from '../../../Components/ComponentPopupStorage';

// models
import {Components, Movements, Products, Semifinishes, Storages} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { movementType, movementTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';


// start
const MovementsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [movesAll, setMovesAll] = useState([]);
	const [moves, setMoves] = useState(null);
	const [storageId, setStorageId] = useState(null);
	const [storages, setStorages] = useState([]);
	const [storagesAll, setStoragesAll] = useState([]);
	const [id, setId] = useState(null);
	const [name, setName] = useState(null);
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:date, setValue:setDate, bind:bindDate} = useInput('');
	const [type, setType] = useState(null);
	const [items, setItems] = useState([]);
	const [inventory, setInventory] = useState([]);
	const [targetId, setTargetId] = useState(null);
	const [components, setComponents] = useState([]);
	const [products, setProducts] = useState([]);
	const [semifinishes, setSemifinishes] = useState([]);
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await storagesGet();
			await componentsGet();
			await productsGet();
			await semifinishesGet();
			await movesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const movesGet = async () => {
		const moves = await Movements.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (moves === undefined || moves.length === 0) {
			setMoves(null);
			return;
		}
		setMoves(moves);
		setMovesAll(moves);
	};
	const storagesGet = async () => {
		const storages = await Storages.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (storages === undefined) return;
		setStorages([]);
		setStoragesAll(storages);
	};
	const semifinishesGet = async () => {
		const semifinishes = await Semifinishes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (semifinishes === undefined) return;
		setSemifinishes(semifinishes);
	};
	const componentsGet = async () => {
		const components = await Components.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (components === undefined) return;
		setComponents(components);
	};
	const productsGet = async () => {
		const products = await Products.get.store().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		setProducts(products);
	};
	const moveAdd = () => show();
	const moveEdit = (move) => {
		move = move || moves.find(f => f._id === id);
		show(move);
	};
	const moveShow = (move) => show(move);
	const show = (move) => {
		setId(move?._id||null);
		setTargetId(move?.target?.id||null);
		setName(move ? moment(move.date).format('DD.MM.YYYY HH:mm') : '');
		setDate(Utils.dateTimeNormalize(move, 'date'));
		setDescription(move?.description||'');
		setItems(move?.items||[]);
		setInventory(move?.inventory||null);
		setType(move?move.type:null);
		setStorageId(move?.storage?.id||null);
	};
	const cancel = () => moveShow(null);
	const save = async () => {
		const data = {
			targetId:targetId,
			storageId:storageId,
			date:date,
			description:description,
			items:items,
			total:calcTotalGet()
		};
		if (id) {
			delete data.storageId;
			delete data.targetId;
		}
		else {
			if (Utils.empty(data.storageId)) {
				errorShow('Необходимо выбрать склад');
				return;
			}
			if (Utils.empty(data.targetId)) {
				errorShow('Необходимо выбрать склад для перемещения');
				return;
			}
		}
		if (Utils.empty(data.date)) {
			errorShow('Необходимо заполнить дату');
			return;
		}
		if (items.length === 0) {
			errorShow('Необходимо добавить компоненты');
			return;
		}
		const res = id ?
				await Movements.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Movements.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			movesGet();
			cancel();
			return true;
		}
	}
	const handleStorage = (e) => {
		const storageId = e.target.value;
		const storages = storagesAll.filter(f => f._id !== storageId);
		setStorages(storages);
		setStorageId(storageId);
		setTargetId('');
	};
	const handleTarget = (e) => setTargetId(e.target.value);
	const searchMove = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setMoves(movesAll);
		else setMoves(movesAll.filter(f => f.date.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const moveDelete = (id) => {
		Movements.remove(id).then((res) => {
			successShow(res.message);
			movesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	};
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = moves.find(f => f._id === v);
			if (s) {
				const data = {
					date:Utils.dateTimeNormalize(null, 'date'),
					targetd:s.target.id,
					storageId:s.storage.id,
					description:s.description,
					items:s.items,
					total:s.total
				};
				await Movements.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		movesGet();
		cancel();
		return true;
	};
	const calcTotalGet = () => items.reduce((acc, v) => acc += parseFloat(v.total), 0).toFixed(2);
	const typeGet = (v) => <div className={`type${v.type===movementType.INCOME?v.type:0}`}>
		{movementTypeName[v.type]}
	</div>;
	const storageGet = (v) => <><i>{v.storage.name}</i> → <i>{v.target.name}</i></>
	const totalSumGet = () => moves ? moves.reduce((acc, v) => acc + ((v.total ? parseFloat(v.total) : 0) * (v.type === movementType.INCOME ? 1 : -1)), 0).toFixed(2) : 0;
	const componentChange = (items) => setItems([...items]);
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Перемещение"
							type="warehouse"
							data={moves}
							id={id}
							search={search}
							rows={[
								{title:'Дата',field:'date',func:(v) => <>
									{moment(v.date).format('DD.MM.YYYY HH:mm')}
									{v.description ? <span>{v.description}</span> : null}
								</>},
								{title:'Движение',field:'storage.name',class:'workshop',func:storageGet},
								{title:'Тип',field:'type',class:'type',func:typeGet},
								{title:'Сумма',field:'total',class:'prices'}
							]}
							rowsShort={['date','type','total']}
							rowsFooter={<>
								<tr className="info-row">
									<td colSpan="3">Итого</td>
									<td className="prices">{totalSumGet()}</td>
									<td colSpan="2"></td>
								</tr>
							</>}
							footerItems={[
								{title:'Сделать копию',action:massCopy}
							]}
							empty={<>Добавьте первое<br/>перемещение</>}
							contentShowTitle={name}
							contentShow={<>
								{inventory ?
									<div className="product-view-row product-view-row-alert">
										Эту перемещение нельзя изменить или удалить! Оно используется в инвентаризации от {moment(inventory.date).format('DD.MM.YYYY HH:mm')}
									</div>
								: null}
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<div className="product-view-row product-view-row-oneline">
									<span>Дата</span>
									<div>{moment(date).format('DD.MM.YYYY HH:mm')}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Тип</span>
									<div>{movementTypeName[type]}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Списано с</span>
									<div>{storagesAll.find(f => f._id === storageId)?.name||'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Оприходовано на</span>
									<div>{storagesAll.find(f => f._id === targetId)?.name||'–'}</div>
								</div>
								{inventory ?
									<div className="product-view-row product-view-row-oneline">
										<span>Инвентаризация от</span>
										<div>{moment(inventory.date).format('DD.MM.YYYY HH:mm')}</div>
									</div> : null}
								<ComponentPositionList items={items} />
							</>}
							contentEdit={<>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-first">
									<label htmlFor="date" className="label-middle">Дата</label>
									<input id="date" {...bindDate} type="datetime-local" required />
								</div>
								<div className="product-edit-row">
									<div className="select select-wide">
										<select onChange={handleStorage} value={storageId} required disabled={!Utils.empty(id)}>
											<option value="">Выберите склад</option>
											{storagesAll.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
								</div>
								<div className="product-edit-row">
									<div className="select select-wide">
										<select onChange={handleTarget} value={targetId} required disabled={!Utils.empty(id)}>
											<option value="">Оприходовать на</option>
											{storages.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Примечание" {...bindDescription} className="lite"></textarea>
								</div>
								<ComponentInline
									title="Позиции"
									data={{components,semifinishes,products}}
									selected={items}
									isShow={items.length===0}
									isCountOnly={true}
									onUpdate={componentChange}
									info={<>
										{items.length ?
											items.map((v,i) => (v.name)).join(', ') : null}
									</>}
									additionButtonComponent={<>
										<ComponentPopupStorage title={'Найти позиции'} data={{components,semifinishes,products}} selected={items} allow={['component','semifinish','product']} isCountOnly={true} onUpdate={componentChange} />
									</>} />
							</>}
							deleteExpression={(v) => !v.inventory}
							editExpression={(v) => !v.inventory}
							hideEditExpression={() => inventory}
							onDelete={moveDelete}
							onEdit={moveEdit}
							onAdd={moveAdd}
							onSave={save}
							onSearch={searchMove}
							onShow={moveShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default MovementsScreen;