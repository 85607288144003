import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import PointsSelect from '../../../Components/PointsSelect';

// models
import {Labels, Points} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';


// start
const LabelsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [labels, setLabels] = useState(null);
	const [labelsAll, setLabelsAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:color, setValue:setColor, bind:bindColor} = useInput('');
	const {value:colorText, setValue:setColorText, bind:bindColorText} = useInput('');
	const {value:sortOrder, setValue:setSortOrder, bind:bindSortOrder} = useInput('');
	const [points, setPoints] = useState([]);
	const [labelPoints, setLabelPoints] = useState([]);
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await pointsGet();
			await labelsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const labelsGet = async () => {
		const labels = await Labels.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (labels === undefined) return;
		setLabels(labels);
		setLabelsAll(labels);
	};
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const labelAdd = () => show();
	const labelEdit = (label) => {
		label = label || labels.find(f => f._id === id);
		show(label);
	};
	const labelShow = (label) => show(label);
	const show = (label) => {
		setId(label?._id||null);
		setName(label?.name||'');
		setDescription(label?.description||'');
		setColor(label?.options.color||'#eeeeee');
		setColorText(label?.options.colorText||'#000000');
		setSortOrder(label?label.options.sortOrder:'');
		setLabelPoints(label ? label.points : points.map(v => ({id:v._id,name:v.name})));
	};
	const cancel = () => labelShow(null);
	const save = async () => {
		const data = {
			name:name,
			description:description,
			sortOrder:sortOrder,
			color:color||'#eeeeee',
			colorText:colorText||'#000000',
			points:labelPoints
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название метки');
			return;
		}
		if (data.points.length === 0) {
			errorShow('Необходимо выбрать филиалы');
			return;
		}
		const res = id ?
				await Labels.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Labels.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			labelsGet();
			cancel();
			return true;
		}
	}
	const searchLabel = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setLabels(labelsAll);
		else setLabels(labelsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const labelDelete = (id) => {
		Labels.remove(id).then((res) => {
			successShow(res.message);
			labelsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const pointSelect = (point) => {
		const p = labelPoints.find(f => f.id === point._id);
		if (p) setLabelPoints(labelPoints.filter(f => f.id !== point._id));
		else setLabelPoints([...labelPoints, {id:point._id,name:point.name}]);
	};
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = labels.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					description:s.description,
					sortOrder:s.options.sortOrder,
					color:s.options.color,
					colorText:s.options.colorText,
					points:s.points
				};
				await Labels.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		labelsGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await Labels.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		labelsGet();
		cancel();
		return true;
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Метки заказа"
							type="books"
							data={labels}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'}
							]}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первую<br/>метку</>}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<PointsSelect isShow={true} selected={labelPoints} />
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название метки" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
								</div>
								<PointsSelect isEdit={true} points={points} selected={labelPoints} onSelect={pointSelect} />
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="sortOrder" className="label-middle">Порядок сортировки</label>
									<input id="sortOrder" {...bindSortOrder} type="text" placeholder="0" maxLength={2} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="color" className="label-middle">Цвет фона</label>
									<div className="input">
										<input type="color" id="color" {...bindColor} className="color" />
									</div>
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="color" className="label-middle">Цвет текста</label>
									<div className="input">
										<input type="color" id="color" {...bindColorText} className="color" />
									</div>
								</div>
							</>}
							onDelete={labelDelete}
							onEdit={labelEdit}
							onAdd={labelAdd}
							onSave={save}
							onSearch={searchLabel}
							onShow={labelShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default LabelsScreen;