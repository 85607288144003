import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {OrderStatuses} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { orderStatus, orderStatusName } from '../../../Globals/Constants';

// images
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheck from './Images/check.svg';


// start
const StatusesScreen = () => {
	const [loading, setLoading] = useState(true);
	const [id, setId] = useState(null);
	const [statuses, setStatuses] = useState([]);
	const [statusesStandart, setStatusesStandart] = useState([]);
	const [statusesStandartAll, setStatusesStandartAll] = useState([]);
	const [status, setStatus] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:message, setValue:setMessage, bind:bindMessage} = useInput('');
	const {value:text, setValue:setText, bind:bindText} = useInput('');
	const {value:background, setValue:setBackground, bind:bindBackground} = useInput('');
	const [code, setCode] = useState(orderStatus.UNKNOWN);
	const [search, setSearch] = useState(null);
	const [isUsed, setIsUsed] = useState(0);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			statusesStandartGet();
			await statusesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const statusesStandartGet = async () => {
		const statuses = Object.keys(orderStatusName).map((v,i) => ({_id:i,code:i,name:orderStatusName[v],code:v,color:'#f2f2f3',description:'Стандартный статус системы'})).slice(1);
		setStatusesStandart(statuses);
		setStatusesStandartAll(statuses);
	};
	const statusesGet = async () => {
		const statuses = await OrderStatuses.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (statuses === undefined) return;
		statuses.forEach((v,i) => v._id = i);
		setStatuses(statuses);
	};
	const statusEdit = (status) => {
		if (!status) {
			status = statuses.find(f => f.code === code);
			if (!status) status = statusesStandartAll.find(f => f.code === code);
		}
		show(status);
	};
	const statusShow = (status) => show(status);
	const show = (status) => {
		if (status) {
			const s = statuses.find(f => f.code === parseInt(status.code));
			if (s) status = s;
		}
		setId(status?.code||null);
		setStatus(status);
		setName(status?.name||'');
		setCode(status?.code||'');
		setIsUsed(isUsedCheck(status?.code));
		setDescription(status?.description||'');
		setMessage(status?.message||'');
		setText(status?.color?.text||'');
		setBackground(status?.color?.background||'');
	};
	const cancel = () => statusShow(null);
	const save = async () => {
		const c = parseInt(code);
		if (isUsedCheck(c) && !isUsed) {
			statusDelete();
			return;
		}
		const data = {
			name:name,
			description:description==='Стандартный статус системы'?null:description,
			text:text,
			background:background,
			message:message
		};
		if (!isUsedCheck(c)) data.code = c;
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название');
			return;
		}
		const res = isUsedCheck(c) ?
				await OrderStatuses.update(c, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await OrderStatuses.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			statusesGet();
			cancel();
			return true;
		}
	}
	const searchStatuses = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setStatusesStandart(statusesStandartAll);
		else setStatusesStandart(statusesStandartAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const statusDelete = (id) => {
		OrderStatuses.remove(code).then((res) => {
			successShow(res.message);
			statusesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const isUsedCheck = (code) => statuses.find(f => f.code === parseInt(code)) ? 1 : 0;
	const usedGet = (code) => statuses.find(f => f.code === parseInt(code));
	const statusNameGet = (v) => isUsedCheck(v.code) ?
		<>
			{usedGet(v.code).name}
			<span>{v.name}</span>
		</> : v.name;
	const colorGet = (v) => isUsedCheck(v.code) ? <div className="color-example" style={{backgroundColor:usedGet(v.code)?.color.background||v.color}}></div> : null;
	const isUserdGet = (v) => isUsedCheck(v.code) ? <img src={imgCheck} alt="" /> : null;
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Статусы заказа"
							type="books"
							data={statusesStandart}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name',func:statusNameGet},
								{title:'Цвет',field:'code',class:'color',func:colorGet},
								{title:'Используется',field:'used',class:'current-wide',func:isUserdGet,sort:(v) => {}}
							]}
							rowsShort={['name','code']}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<div className="product-view-row product-view-row-simple">
									<i>Действие статуса, как <b>{orderStatusName[status?.code]}</b></i>
								</div>
								<div className="product-view-row product-view-row-simple">
									<label>Пример отображения</label>
									<div><span style={{color:text,backgroundColor:background}}>&nbsp;{orderStatusName[status?.code]}&nbsp;</span></div>
								</div>
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название статуса" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание, комментарий" {...bindDescription} className="lite"></textarea>
								</div>
								<div className="product-edit-row">
									<i>Действие статуса, как <b>{orderStatusName[status?.code]}</b></i>
								</div>
								<h4>Параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="text" className="label-middle">Цвет фона</label>
									<div className="input">
										<input type="color" id="text" {...bindBackground} className="color" />
									</div>
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="background" className="label-middle">Цвет текста</label>
									<div className="input">
										<input type="color" id="background" {...bindText} className="color" />
									</div>
								</div>
								<h4>Сообщение пользователю</h4>
								<div className="product-edit-row">
									<textarea placeholder="Текст сообщения" {...bindMessage} className="lite"></textarea>
								</div>
								<h4>Дополнительно</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label>Пример отображения</label>
									<div><span style={{color:text,backgroundColor:background}}>&nbsp;{orderStatusName[status?.code]}&nbsp;</span></div>
								</div>
								{isUsedCheck(code) ?
									<div className="product-edit-row product-edit-row-oneline">
										<label>Успользуется</label>
										<img src={isUsed ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsUsed(!isUsed)} />
									</div> : null}
							</>}
							onDelete={statusDelete}
							onEdit={statusEdit}
							onSave={save}
							onSearch={searchStatuses}
							onShow={statusShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default StatusesScreen;