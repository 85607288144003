import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import LoaderInline from '../../../Components/LoaderInline';

// models
import {Categories, ComponentCategories, Semifinishes, Storages, Warehouse} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { measureTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';


// start
const BalancesScreen = () => {
	const [loading, setLoading] = useState(true);
	const [loadingInline, setLoadingInline] = useState(false);
	const {value:date, setValue:setDate, bind:bindDate} = useInput('');
	const [categories, setCategories] = useState([]);
	const [componentCategories, setComponentCategories] = useState([]);
	const [semifinishes, setSemifinishes] = useState([]);
	const [storages, setStorages] = useState([]);
	const [balances, setBalances] = useState(null);
	const [categoryId, setCategoryId] = useState(null);
	const [storageId, setStorageId] = useState(null);
	const [isCategoryFilter, setIsCategoryFilter] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await storagesGet();
			await categoriesGet();
			await componentCategoriesGet();
			await semifinishesGet();
			setDate(Utils.dateTimeNormalize());
			show();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const storagesGet = async () => {
		const storages = await Storages.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (storages === undefined) return;
		setStorages(storages);
	};
	const semifinishesGet = async () => {
		const semifinishes = await Semifinishes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (semifinishes === undefined) return;
		setSemifinishes(semifinishes);
	};
	const componentCategoriesGet = async () => {
		const categories = await ComponentCategories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setComponentCategories(categories);
	};
	const categoriesGet = async () => {
		const categories = await Categories.get.store().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setCategories(categories);
	};
	const categoryHandler = (e) => setCategoryId(e.target.value);
	const storageHandler = (e) => setStorageId(e.target.value);
	const dateChange = async (e) => setDate(e.target.value);
	const show = async () => {
		setLoadingInline(true);
		setIsCategoryFilter(categoryId ? true : false);
		const data = {
			categoryId:categoryId,
			storageId:storageId,
			date:date
		};
		const res = await Warehouse.balance(data).catch((ex) => {
			console.log(666, ex);
			errorShow(ex.message);
			setLoadingInline(false);
		});
		if (res === undefined) return;
		balancePrepare(res);
		setLoadingInline(false);
	};
	const balancePrepare = (data) => {
		const balance = {};
		data.filter(f => f.isproduct).forEach((v) => {
			if (balance.products === undefined) balance.products = {};
			if (v.category) {
				const id = v.category.id;
				if (balance.products[id] === undefined) {
					balance.products[id] = {
						name:v.category.name,
						items:[]
					};
				}
				balance.products[id].items.push(v);
			}
		});
		data.filter(f => f.issemifinish).forEach((v) => {
			if (balance.semifinishes === undefined) balance.semifinishes = {};
			const id = 'sf';
			if (balance.semifinishes[id] === undefined) {
				balance.semifinishes[id] = {
					items:[]
				};
			}
			balance.semifinishes[id].items.push(v);
		});
		data.filter(f => !f.isproduct && !f.iscomponent).forEach((v) => {
			if (balance.components === undefined) balance.components = {};
			if (v.category) {
				const id = v.category.id;
				if (balance.components[id] === undefined) {
					balance.components[id] = {
						name:v.category.name,
						items:[]
					};
				}
				balance.components[id].items.push(v);
			}
		});
		setBalances(balance);
	};
	const rowGet = (v, i) => <tr key={i}>
		<td>{v.name}</td>
		<td className="status status-small">{measureTypeName[v.measure]}</td>
		<td className="amount">{v.inventory}</td>
		<td className="amount">{v.income}</td>
		<td className="amount">{v.release}</td>
		<td className="amount">{v.expense}</td>
		<td className="amount">{v.writeoff}</td>
		<td className="amount">{v.balance}</td>
		<td className="amount">{v.mininum}</td>
		<td className="prices">{v.price}</td>
		<td className="prices prices-mid"><b>{v.total}</b></td>
	</tr>;
	const totalSumGet = () => {
		let sum = 0;
		if (balances.products) Object.entries(balances.products).forEach(([k,v]) => v.items.forEach((v) => sum += v.total));
		if (balances.semifinishes) Object.entries(balances.semifinishes).forEach(([k,v]) => v.items.forEach((v) => sum += v.total));
		if (balances.components) Object.entries(balances.components).forEach(([k,v]) => v.items.forEach((v) => sum += v.total));
		return sum.toFixed(2);
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Остатки"
							type="warehouse"
							allHide={true}
							contentRows={<>
								<div className="balances-container">
									<div className="controls">
										<div className="select">
											<select onChange={categoryHandler} defaultValue={categoryId}>
												<option value="">Все остатки</option>
												{categories.length ?
													<optgroup label="Товары">
														{categories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
													</optgroup> : null}
												{semifinishes.length ?
													<optgroup label="Полуфабрикаты">
														{semifinishes.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
													</optgroup> : null}
												{componentCategories.length ?
													<optgroup label="Компоненты">
														{componentCategories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
													</optgroup> : null}
											</select>
										</div>
										<div className="select">
											<select onChange={storageHandler} defaultValue={storageId}>
												<option value="">Все склады</option>
													{storages.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
											</select>
										</div>
										<input type="datetime-local" {...bindDate} onChange={dateChange} />
										<button type="button" onClick={show}>Показать</button>
									</div>
									<div className="items-container">
										{loadingInline ? <LoaderInline /> :
											(balances ?
													<table className="items items-no-control">
														<thead>
															<tr>
																<th>Товар</th>
																<th className="status status-small">Ед.</th>
																<th className="amount">Инвент.</th>
																<th className="amount">Поступления</th>
																<th className="amount">Выпуск</th>
																<th className="amount">Расход</th>
																<th className="amount">Списано</th>
																<th className="amount">Остаток</th>
																<th className="amount">Мин.остаток</th>
																<th className="prices">Цена</th>
																<th className="prices prices-mid">Сумма</th>
															</tr>
														</thead>
														<tbody>
															{balances.products ?
																	<>
																		{isCategoryFilter ? null : <tr className="section"><td colSpan="11">Товары</td></tr>}
																		{Object.entries(balances.products).map(([k,v],i) => <React.Fragment key={i}>
																			{isCategoryFilter ? null : <tr className="section section-lite"><td colSpan="11"><b>{v.name}</b></td></tr>}
																			{v.items.map((v,i) => rowGet(v, i))}
																		</React.Fragment>)}
																	</>
																: null}
															{balances.semifinishes ?
																	<>
																		{isCategoryFilter ? null : <tr className="section"><td colSpan="11">Полуфабрикаты</td></tr>}
																		{Object.entries(balances.semifinishes).map(([k,v],i) => <React.Fragment key={i}>
																			{v.items.map((v,i) => rowGet(v, i))}
																		</React.Fragment>)}
																	</>
																: null}
															{balances.components ?
																	<>
																		{isCategoryFilter ? null : <tr className="section"><td colSpan="11">Компоненты (сырье)</td></tr>}
																		{Object.entries(balances.components).map(([k,v],i) => <React.Fragment key={i}>
																			{isCategoryFilter ? null : <tr className="section section-lite"><td colSpan="11"><b>{v.name}</b></td></tr>}
																			{v.items.map((v,i) => rowGet(v, i))}
																		</React.Fragment>)}
																	</>
																: null}
															<tr className="info-row">
																<td colSpan="10">Итого</td>
																<td className="prices">{totalSumGet()}</td>
															</tr>
														</tbody>
													</table>
												:
													<div className="empty">Нет данных</div>
											)
										}
									</div>
								</div>
							</>}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default BalancesScreen;