import React, {useEffect,useState} from 'react';

// images
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';

// styles
import './styles.css';


const PointsSelect = (props) => {
	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState(false);
	useEffect(() => {
		setLoading(false);
	}, []);
	const select = (point) => props.onSelect(point);
	const switcher = () => setShow(!show);
	return loading ? null :
		<>
			{props.isShow ?
					<div className="product-accordeon product-accordeon-view">
						<h4>Филиалы</h4>
						<div className="product-accordeon-content">
							<div>{props.selected.map((v) => v.name).join(', ')}</div>
						</div>
					</div>
				: null}
			{props.isEdit ?
					<div className={`product-accordeon${show?'':' product-accordeon-hide'}`}>
						<div className="product-accordeon-title" onClick={switcher}>
							<div>
								<h4>Филиалы</h4>
								{show ? null : <div>{props.selected.length === props.points.length ? 'Выбраны все филиалы' : (props.selected.length ? props.selected.map((v) => v.name).join(', ') : <span>Филиалы не выбраны</span>)}</div>}
							</div>
							<div className="product-accordeon-arrow"></div> 
						</div>
						<div className="product-accordeon-content">
							{props.points.map((v,i) => <div key={i} className="product-accordeon-item">
								<img src={props.selected.find(f => f.id === v._id)?imgCheckOn:imgCheckOff} alt="" onClick={() => select(v)} />
								{v.name}
							</div>)}
						</div>
					</div>
				: null}
		</>;
};

export default PointsSelect;