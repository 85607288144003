import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {ExpenseCategories} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';


// start
const ExpenseCategoriesScreen = () => {
	const [loading, setLoading] = useState(true);
	const [categoriesAll, setCategoriesAll] = useState([]);
	const [categories, setCategories] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await categoriesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const categoriesGet = async () => {
		const categories = await ExpenseCategories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setCategories(categories);
		setCategoriesAll(categories);
	};
	const categoryAdd = () => show();
	const categoryEdit = (e, category) => {
		category = category || categories.find(f => f._id === id);
		show(category);
	};
	const categoryShow = (category) => show(category);
	const show = (category) => {
		setId(category?._id||null);
		setName(category?.name||'');
	};
	const cancel = () => categoryShow(null);
	const save = async () => {
		const data = {
			name:name
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название причины');
			return;
		}
		const res = id ?
				await ExpenseCategories.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await ExpenseCategories.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			categoriesGet();
			cancel();
			return true;
		}
	}
	const searchCategory = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setCategories(categoriesAll);
		else setCategories(categoriesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const categoryDelete = (id) => {
		ExpenseCategories.remove(id).then((res) => {
			successShow(res.message);
			categoriesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = categories.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`
				};
				await ExpenseCategories.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		categoriesGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await ExpenseCategories.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		categoriesGet();
		cancel();
		return true;
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Кат.расходов"
							type="books"
							data={categories}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'}
							]}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первую<br/>категорию расходов</>}
							contentShowTitle={name}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название категории" className="input-title" autoFocus={true} required />
								</div>
							</>}
							onDelete={categoryDelete}
							onEdit={categoryEdit}
							onAdd={categoryAdd}
							onSave={save}
							onSearch={searchCategory}
							onShow={categoryShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default ExpenseCategoriesScreen;