import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()			=> await Http.request('tariffs');

const pay		= async (data)		=> await Http.request('tariff/payment', httpMethods.POST, data);


export {
	get,
	pay
}