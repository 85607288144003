import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get = {
	actual		: async ()			=> await Http.request('orders/actual'),
	dayshift	: async ()			=> await Http.request('orders/dayshift'),
	archive		: async (data)		=> await Http.request('orders/archive', httpMethods.POST, data),
	last		: async (id)		=> await Http.request(`orders/last/${id||0}`),
	id			: async (id)		=> await Http.request(`order/${id}`),
	search		: async (query)		=> await Http.request('orders/search', httpMethods.POST, {query}),
	client		: async (id)		=> await Http.request(`order/client/${id}`),
	feedaback	: async ()			=> await Http.request('orders/feedback')
};

const add		= async (data)		=> await Http.request('order', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`order/${id}`, httpMethods.PUT, data);

const status	= async (id, data)	=> await Http.request(`order/status/${id}`, httpMethods.PATCH, data);

const patch		= async (id, data)	=> await Http.request(`order/${id}`, httpMethods.PATCH, data);

const feedback	= {
	update		: async (id, data)	=> await Http.request(`order/feedback/${id}`, httpMethods.PATCH, data),
	view		: async (id)		=> await Http.request(`order/feedback/view/${id}`, httpMethods.PATCH)
};

const workers	= async (id, data)	=> await Http.request(`order/worker/${id}`, httpMethods.PATCH, data);


export {
	get,
	add,
	update,
	status,
	patch,
	feedback,
	workers
}