import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import ComponentList from '../../../Components/ComponentList';
import ComponentSemifinishInline from '../../../Components/ComponentSemifinishInline';
import ComponentPopup from '../../../Components/ComponentPopup';

// models
import {Components, Points, ProductOptionGroups, ProductOptions} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { API, measureTypeSmall, measureTypeSmallName } from '../../../Globals/Constants';

// images
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgNoImage from './Images/no-image.svg';


// start
const ProductOptionsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [partnerId, setPartnerId] = useState(null);
	const [optionsAll, setOptionsAll] = useState([]);
	const [options, setOptions] = useState(null);
	const [groups, setGroups] = useState([]);
	const [points, setPoints] = useState([]);
	const [components, setComponents] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:price, setValue:setPrice, bind:bindPrice} = useInput('');
	const [weight, setWeight] = useState('');
	const [measure, setMeasure] = useState(measureTypeSmall.UNKNOWN);
	const [optionGroups, setOptionGroups] = useState([]);
	const [search, setSearch] = useState(null);
	const [items, setItems] = useState([]);
	const [image, setImage] = useState(null);
	const [imageName, setImageName] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await pointsGet();
			await componentsGet();
			await optionGroupsGet();
			await optionsGet();
			const point = ls('point');
			setPartnerId(point.partnerId);
		};
		await dataGet();
		setLoading(false);
	}, []);
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const optionGroupsGet = async () => {
		const groups = await ProductOptionGroups.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (groups === undefined) return;
		setGroups(groups);
	};
	const componentsGet = async () => {
		const components = await Components.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (components === undefined) return;
		setComponents(components);
	};
	const optionsGet = async () => {
		const options = await ProductOptions.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (options === undefined || options.length === 0) {
			setOptions(null);
			return;
		}
		setOptions(options);
		setOptionsAll(options);
	};
	const optionAdd = () => show();
	const optionEdit = (option) => {
		option = option || options.find(f => f._id === id);
		show(option);
	};
	const optionShow = (option) => show(option);
	const show = (option) => {
		setId(option?._id||null);
		setName(option?.name||'');
		setDescription(option?.description||'');
		setPrice(option?.price||'');
		setWeight(option?.properties.weight||'');
		setMeasure(option?.properties.measure||'');
		setItems(option?.components||[]);
		setImage(option && option.image ? imageUrlGet(partnerId, option._id, option.image) : null);
		setImageName(option?.image||null);
		setOptionGroups(option?.groups||[]);
	};
	const cancel = () => optionShow(null);
	const save = async () => {
		const data = {
			groups:optionGroups.map(v => v.id),
			name:name,
			description:description,
			price:price,
			weight:calcWeightGet(),
			measure:measureTypeSmall.GRAM,
			total:calcPriceGet(),
			components:items
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название блюда');
			return;
		}
		if (Utils.empty(data.price)) {
			errorShow('Необходимо указать цену опции');
			return;
		}
		const res = id ?
				await ProductOptions.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.message);
				})
			:
				await ProductOptions.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.message);
				});
		if (res) {
			if (image) imageUpload(id||res.id);
			successShow(res.message);
			optionsGet();
			cancel();
			return true;
		}
	}
	const imageUpload = async (id) => {
		const data = imagePrepare(image);
		if (data) {
			const type = Utils.imageExtGet(image);
			ProductOptions.image.add(id, {data,type}).catch((ex) => console.log(666, ex));
		}
	}
	const imagePrepare = (image) => Utils.isImage(image) ? image.replace(/^.*,/gi,'') : null;
	const searchOption = (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setOptions(optionsAll);
		else setOptions(optionsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	}
	const optionDelete = (id) => {
		ProductOptions.remove(id).then((res) => {
			successShow(res.message);
			optionsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = options.find(f => f._id === v);
			if (s) {
				const data = {
					groups:s.groups.map(v => v.id),
					name:`${s.name} (копия)`,
					description:s.description,
					price:s.price,
					weight:s.properties.weight,
					measure:s.properties.measure,
					total:s.properties.total,
					components:s.components,
					image:s.image
				};
				const res = await ProductOptions.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
				if (res) ProductOptions.image.copy(s._id, res.id).catch((ex) => console.log(666, ex));
			}
		};
		successShow('Операция прошла успешно');
		optionsGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await ProductOptions.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		optionsGet();
		cancel();
		return true;
	};
	const pointGet = (item) => {
		const p = item.points;
		const out = [];
		if (p && p.length !== points.length) {
			for (let i = 0; i < p.length; i++) {
				out.push(points.find(f => f._id === p[i].id).name);
			}
		}
		return out.length === 0 ? 'Все филиалы' : out.join(', ');
	};
	const calcPriceGet = () => Number(items.reduce((acc, v) => acc += parseFloat(v.total), 0).toFixed(2));
	const calcWeightGet = () => Number(items.reduce((acc, v) => acc += parseFloat(v.brutto), 0).toFixed(3));
	const imageUrlGet = (partnerId, id, filename) => `${API.assets}partners/${partnerId}/productoptions/${id}/${filename}`;
	const imageError = (e) => e.target.src = imgNoImage;
	const imageDelete = () => setImage(null);
	const handleImage = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setImage(r.target.result);
		reader.readAsDataURL(file);
	};
	const imageGet = (v) => <img src={imageUrlGet(partnerId, v._id, v.image)} alt="" onError={imageError} />;
	const inGroup = (id) => optionGroups.find(f => f.id === id);
	const groupSelect = (group) => {
		if (inGroup(group._id)) setOptionGroups(optionGroups.filter(f => f.id !== group._id));
		else {
			setOptionGroups([...optionGroups, {
				id:group._id,
				name:group.name
			}]);
		}
	};
	const componentChange = (items) => setItems([...items]);
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Опции"
							type="books"
							data={options}
							id={id}
							search={search}
							sortIndex={1}
							rows={[
								{title:'',field:'image',class:'avatar',func:imageGet},
								{title:'Название',field:'name'},
								{title:'Филиал',field:'points',class:'area',func:pointGet},
								{title:'Цена',field:'price',class:'prices'},
								{title:'Объем',field:'weight',class:'weight',func:(v) => v.properties.weight,sort:'properties.weight'}
							]}
							rowsShort={['image','name','prices']}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первую<br/>позицию опцию</>}
							contentShowTitle={name}
							contentShow={<>
								<div className="product-view-row product-view-row-simple">
									<p className="description">{description}</p>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Цена</span>
									<div>{price} ₽</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Объем / Вес</span>
									<div>{weight} {measureTypeSmallName[measure]}</div>
								</div>
								<ComponentList items={items} />
								{image ?
									<>
										<h4>Фотография</h4>
										<div className="product-view-row product-view-row-simple product-edit-image">
											<img src={imageUrlGet(partnerId, id, imageName)} alt="" onError={imageError} className="image-small" />
										</div>
									</> : null}
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название опции" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription}></textarea>
								</div>
								<h4>Группа опций</h4>
								<div className="product-edit-row">
									{groups.length ?
										<div className="product-edit-area">
											{groups.map((v,i) => <div key={i} className="product-edit-area product-edit-row-oneline-start">
												<img src={inGroup(v._id) ? imgCheckOn : imgCheckOff} alt="" className="checkbox" onClick={() => groupSelect(v)} />
												{v.name}
											</div>)}
										</div> : null}
								</div>
								<h4>Цена</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="productPrice" className="label-middle">Цена блюда, ₽</label>
									<input id="productPrice" {...bindPrice} type="text" placeholder="0" required />
								</div>
								<h4>Фотографии блюда</h4>
								<div className="product-edit-row">
									{image ?
										<div className="product-edit-images">
											<div className="product-edit-image">
												<img src={image} alt="" onError={imageError} />
												<div className="delete" onClick={imageDelete}></div>
											</div>
										</div> : null}
									<label>
										<div className="product-edit-btn">
											Добавить фото
											<input type="file" accept="image/jpeg,image/png,image/webp" onChange={handleImage} />
										</div>
									</label>
								</div>
								<ComponentSemifinishInline
									title="Состав"
									data={{components}}
									selected={items}
									onUpdate={componentChange}
									additionButtonComponent={<>
										<ComponentPopup title={'Найти позиции'} data={{components}} selected={items} allow={['component']} onUpdate={componentChange} />
									</>} />
							</>}
							onDelete={optionDelete}
							onEdit={optionEdit}
							onAdd={optionAdd}
							onSave={save}
							onSearch={searchOption}
							onShow={optionShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default ProductOptionsScreen;