import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {Reasons} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';


// start
const ReasonsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [reasonsAll, setReasonsAll] = useState([]);
	const [reasons, setReasons] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await reasonsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const reasonsGet = async () => {
		const reasons = await Reasons.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (reasons === undefined) return;
		setReasons(reasons);
		setReasonsAll(reasons);
	};
	const reasonAdd = () => show();
	const reasonEdit = (reason) => {
		reason = reason || reasons.find(f => f._id === id);
		show(reason);
	};
	const reasonShow = (reason) => show(reason);
	const show = (reason) => {
		setId(reason?._id||null);
		setName(reason?.name||'');
		setDescription(reason?.description||'');
	};
	const cancel = () => reasonShow(null);
	const save = async () => {
		const data = {
			name:name,
			description:description
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название причины');
			return;
		}
		const res = id ?
				await Reasons.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Reasons.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			reasonsGet();
			cancel();
			return true;
		}
	}
	const searchReason = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setReasons(reasonsAll);
		else setReasons(reasonsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const reasonDelete = (id) => {
		Reasons.remove(id).then((res) => {
			successShow(res.message);
			reasonsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	};
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = reasons.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					description:s.description
				};
				await Reasons.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		reasonsGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await Reasons.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		reasonsGet();
		cancel();
		return true;
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Причины cписания"
							type="books"
							data={reasons}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'}
							]}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первую<br/>причину списания</>}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название причины" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
								</div>
							</>}
							onDelete={reasonDelete}
							onEdit={reasonEdit}
							onAdd={reasonAdd}
							onSave={save}
							onSearch={searchReason}
							onShow={reasonShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default ReasonsScreen;