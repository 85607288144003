import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()			=> await Http.request('inventories');

const prepare	= async (data)		=> await Http.request('inventory/prepare', httpMethods.POST, data);

const add		= async (data)		=> await Http.request('inventory', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`inventory/${id}`, httpMethods.PUT, data);

const remove	= async (id)		=> await Http.request(`inventory/${id}`, httpMethods.DELETE);


export {
	get,
	prepare,
	add,
	update,
	remove
}