import React, {useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Salaries, Users} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { cashboxPaymentType, commonStatus, transactionType, userTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgClose from './Images/close.svg';
import imgDelete from './Images/uncheck.svg';
import imgPlus from './Images/plus-green.svg';
import imgMinus from './Images/minus-red.svg';


// start
const SalaryScreen = (props) => {
	const history = useHistory();
	const [ModalOperationAdd, modalOperationAddOpen, modalOperationAddClose] = useModal('root');
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root');
	const [ModalPay, modalPayOpen, modalPayClose] = useModal('root');
	const [ModalUnpay, modalUnpayOpen, modalUnpayClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [usersAll, setUsersAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:amount, setValue:setAmount, bind:bindAmount} = useInput('');
	const [user, setUser] = useState(null);
	const [type, setType] = useState(transactionType.CREDIT);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await usersGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	useEffect(() => {
		if (users.length > 0) {
			if (props.match.params.id) {
				const user = users.find(f => f._id === props.match.params.id);
				if (user) userShow(user);
			}
		}
	}, [users]);
	const usersGet = async (id) => {
		const users = await Users.getSalary().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (users === undefined) return;
		const u = [];
		for (let i = 0; i < users.length; i++) {
			const t = users[i].type;
			if (!u[t]) u[t] = [];
			u[t].push(users[i]);
		}
		const out = [];
		for (let i = 0; i < u.length; i++) {
			if (u[i]) out.push(...u[i]);
		}
		setUsers(out);
		setUsersAll(out);
		if (id) {
			const user = users.find(f => f.id === id);
			userShow(user);
		}
	};
	const userShow = (user) => {
		show(user);
		setIsShow(true);
	};
	const show = (user) => {
		setControlId(null);
		setUser(user);
		setId(user?.id||null);
	};
	const cancel = () => {
		userShow(null);
		setIsShow(false);
		modalDeleteHide();
		modalPayClose();
		modalUnpayClose();
		modalOperationAddClose();
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name||'Без названия',
			amount:amount,
			userId:id,
			type:type
		};
		if (Utils.empty(data.amount)) {
			errorShow('Необходимо заполнить сумму');
			return;
		}
		if (Utils.empty(data.type)) {
			errorShow('Необходимо выбрать тип операции');
			return;
		}
		const res = await Salaries.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			usersGet(user.id);
			modalOperationAddClose();
		}
	}
	const searchUser = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setUsers(usersAll);
		else setUsers(usersAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const userControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const salaryDelete = () => {
		Salaries.remove(id).then((res) => {
			successShow(res.message);
			usersGet(user.id);
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		modalDeleteClose();
	}
	const modalDeleteHide = () => modalDeleteClose();
	const modalDeleteShow = (e, id) => {
		e.stopPropagation();
		setControlId(null);
		setId(id);
		modalDeleteOpen();
	}
	const salaryPayShow = (e, id, type) => {
		e?.stopPropagation();
		setControlId(null);
		setId(id);
		setAmount(user.salary.find(f => f._id === id).amount);
		if (type === 'pay') modalPayOpen();
		if (type === 'unpay') modalUnpayOpen();
	}
	const salaryPay = (type, paymentType) => {
		Salaries.pay[type](id, paymentType).then((res) => {
			successShow(res.message);
			usersGet(user.id);
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		modalPayClose();
		modalUnpayClose();
	}
	const operationShow = (e, user, type) => {
		e?.stopPropagation();
		setControlId(null);
		show(user);
		setType(type);
		setAmount(null);
		setName(null);
		modalOperationAddOpen();
	};
	const transactionsRender = (title, type) => {
		const data = user.salary.filter(f => f.type === type);
		return data.length > 0 ?
			<>
				<h4>{title}</h4>
				<div className="salary-list">
					{data.map((v,i) => <div key={i} className="salary-list-row">
						<div className="salary-list-row-name">
							{moment(v.createdAt).format('DD.MM.YYYY HH:mm:ss')}
							<span>{v.name}</span>
						</div>
						<div className="salary-list-row-amount">{v.amount}₽</div>
						<div className={`salary-list-row-control${v.status===commonStatus.IN_ACTIVE?' salary-list-row-control-unpay':''}`} onClick={(e) => salaryPayShow(e, v._id, v.status === commonStatus.ACTIVE ? 'pay' : 'unpay')}>{v.status === commonStatus.ACTIVE ? 'Оплатить' : 'Отозвать'}</div>
						<div className="salary-list-row-delete" onClick={(e) => modalDeleteShow(e, v._id)}><img src={imgDelete} alt="" /></div>
					</div>)}
					<div className="salary-list-row">
						<div className="salary-list-row-name">
							<b>Всего</b>
						</div>
						<div className="salary-list-row-amount">
							<b>{Utils.moneyFormat(data.reduce((a, b) => a + b.amount, 0))}</b>
						</div>
					</div>
				</div>
			</> : null;
	}
	const typeTotal = (type) => {
		const u = users.filter(f => f.type === type);
		const plus = u.reduce((a, b) => a + b.plus, 0);
		const minus = u.reduce((a, b) => a + b.minus, 0);
		const total = plus - minus;
		return <tr className="info-row">
				<td><b>Всего</b></td>
				<td className="money">{plus ? <><b>{plus}</b></> : ''}</td>
				<td className="money">{minus ? <><b>{minus}</b></> : ''}</td>
				<td className="money">{total ? <><b>{total}</b></> : ''}</td>
				<td className="control"></td>
			</tr>;
	};
	const goto = (link) => history.push(link);
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Заработная плата" type="money" disabled={true} />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search search-single" onChange={searchUser} value={search} />
									</div>
									<div className="container-inner">
										{users.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Сотрудник</th>
															<th className="money">Начислено</th>
															<th className="money">Выплачено</th>
															<th className="money">Остаток</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{users.map((v,i) => <React.Fragment key={i}>
															<tr onClick={() => userShow(v)} className={id===v.id?'row-selected':''}>
																<td>
																	{v.name}
																	<span>{userTypeName[v.type]}</span>
																</td>
																<td className="money">{v.plus ? `${v.plus}` : ''}</td>
																<td className="money">{v.minus ? `${v.minus}` : ''}</td>
																<td className="money">{v.total ? `${v.total}` : ''}</td>
																<td className="control">
																	<img src={imgMenuPoints} alt="" onClick={(e) => userControlSelect(e, v.id)} />
																	{controlId === v.id ?
																			<div className="control-block">
																				<div className="plus-operation" onClick={(e) => operationShow(e, v, transactionType.CREDIT)}>Начислить</div>
																				<div className="minus-operation" onClick={(e) => operationShow(e, v, transactionType.DEBIT)}>Выплатить</div>
																				<div className="worktime" onClick={() => goto(`/statistics/worktime/${v.id}`)}>Табель</div>
																				<div className="profile" onClick={() => goto(`/books/user/${v.id}`)}>Профиль</div>
																			</div>
																		: null}
																</td>
															</tr>
															{users.length - 1 === i || v.type !== users[i + 1].type ? typeTotal(v.type) : null}
														</React.Fragment>)}
														<tr className="info-row info-row-last">
															<td colSpan="4">
																<div className="row-oneline-wide">
																	<b>Фонд заработанной платы</b>
																	<div><b>{Utils.moneyFormat(users.reduce((a, b) => a + b.total, 0))}</b></div>
																</div>
															</td>
															<td className="control"></td>
														</tr>
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте первую<br/>расходную запись
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<h3>{user?.name}</h3>
													</div>
													<div className="product-view-row product-view-row-oneline">
														<span>Операции за <b className="date-upper">{moment().format('MMMM YYYY')}</b></span>
													</div>
													<div className="salary-list-buttons">
														<button type="button" onClick={(e) => operationShow(e, user, transactionType.CREDIT)}>
															<img src={imgPlus} alt="" />
															Начислить
														</button>
														<button type="button" onClick={(e) => operationShow(e, user, transactionType.DEBIT)}>
															<img src={imgMinus} alt="" />
															Выплатить
														</button>
													</div>
													{user?.salary && user?.salary.length > 0 ?
															<>
																{transactionsRender('Начисления', transactionType.CREDIT)}
																{transactionsRender('Выплаты', transactionType.DEBIT)}
															</>
														:
															<div className="product-view-row product-view-row-simple">
																<span>Нет операций</span>
															</div>
													}
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>
									:
										<Empty image={'caregories'} text={<>Для просмотра начислений и выплат<br />выберите сотрудника из списка</>} />
								}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalOperationAdd>
				<div className="modal modal-salary-operation">
					<div className="header">
						<h4>{type === transactionType.CREDIT ? 'Начисление' : 'Списание'}</h4>
						<img src={imgClose} alt="" onClick={modalOperationAddClose} className="btn-close" />
					</div>
					<form onSubmit={save}>
						<div className="container-inner">
							<div className="user-info">
								<b>{user?.name}</b>
								{user ? <span>{userTypeName[user.type]}</span> : null}
							</div>
							<div className="row row-oneline">
								<label htmlFor="amount" className="label-middle">Сумма{type===transactionType.CREDIT?' начисления сотруднику':null}{type===transactionType.DEBIT?' выплаты сотруднику':null}, ₽</label>
								<input id="amount" {...bindAmount} type="text" placeholder="0" maxLength={6} required />
							</div>
							<div className="row">
								<input type="text" {...bindName} placeholder="Комментарий" className="wide" />
							</div>
						</div>
						<div className="buttons">
							<button type="button" onClick={modalOperationAddClose} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalOperationAdd>
			<ModalPay>
				<div className="modal modal-expense">
					<div className="header">
						<h4>Выплата заработной платы</h4>
						<img src={imgClose} alt="" onClick={modalPayClose} className="btn-close" />
					</div>
					<p>Будет произведена операция списания из кассы на сумму <b>{amount}₽</b></p>
					<div className="buttons">
						<button type="button" onClick={modalPayClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={() => salaryPay('pay', cashboxPaymentType.CASH)} className="btn-accept">Наличные</button>
						<button type="button" onClick={() => salaryPay('pay', cashboxPaymentType.CARD)} className="btn-accept">Безналичные</button>
					</div>
				</div>
			</ModalPay>
			<ModalUnpay>
				<div className="modal">
					<div className="header">
						<h4>Отзыв расходной операции</h4>
						<img src={imgClose} alt="" onClick={modalUnpayClose} className="btn-close" />
					</div>
					<p>
						Если смена открыта, изъятие из кассы на сумму оплаты <b>{amount}₽</b> будет удалено.<br/>
						Если смена закрыта, то будет произведен возврат средств (внесение) за оплату в кассу.
					</p>
					<div className="buttons">
						<button type="button" onClick={modalUnpayClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={() => salaryPay('unpay')} className="btn-accept btn-delete">Отозвать</button>
					</div>
				</div>
			</ModalUnpay>
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удалить операцию</h4>
						<img src={imgClose} alt="" onClick={modalDeleteHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить операцию?</p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={salaryDelete} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
		</>
	);
};

export default SalaryScreen;