import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import Moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import LoaderInline from '../../../Components/LoaderInline';

// models
import {Inventories, Semifinishes, Storages} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { measureTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClose from './Images/close.svg';
import imgEdit from './Images/edit.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgPlus from './Images/plus-black.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckOff from './Images/checkbox-off.svg';


// start
const InventoriesScreen = () => {
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root');
	const [ModalAdd, modalAddOpen, modalAddClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [loadingInline, setLoadingInline] = useState(false);
	const {value:date, setValue:setDate, bind:bindDate} = useInput('');
	const [inventories, setInventories] = useState(null);
	const [storages, setStorages] = useState([]);
	const [semifinishes, setSemifinishes] = useState([]);
	const [inventory, setInventory] = useState(null);
	const [inventoryItems, setInventoryItems] = useState(null);
	const [id, setId] = useState(null);
	const [name, setName] = useState('');
	const [storageId, setStorageId] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [controlTop, setControlTop] = useState('');
	const [tab, setTab] = useState(1);
	const [isShow, setIsShow] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await storagesGet();
			await semifinishesGet();
			await inventoriesGet();
			setDate(Utils.dateTimeNormalize());
		};
		await dataGet();
		setLoading(false);
	}, []);
	const semifinishesGet = async () => {
		const semifinishes = await Semifinishes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (semifinishes === undefined) return;
		setSemifinishes(semifinishes);
	};
	const storagesGet = async () => {
		const storages = await Storages.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (storages === undefined) return;
		setStorages(storages);
	};
	const inventoriesGet = async () => {
		const inventories = await Inventories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (inventories === undefined || inventories.length === 0) {
			setInventories(null)
			return;
		}
		setInventories(inventories);
	};
	const dateChange = async (e) => setDate(e.target.value);

	const storageShow = () => {
		modalAddHide();
		modalAddOpen();
	};
	const prepare = async () => {
		if (!date) {
			errorShow('Выберите дату');
			return;
		}
		if (inventories && inventories.length) {
			const inv = inventories[0];
			if (Moment(date).isBefore(inv.date)) {
				errorShow('Дата инвентаризации не может быть раньше последней');
				return;
			}
		}
		if (!storageId) {
			errorShow('Выберите склад');
			return;
		}
		const data = {
			date:date,
			storageId:storageId
		};
		modalAddClose();
		setLoadingInline(true);
		const res = await Inventories.prepare(data).catch((ex) => {
			console.log(666, ex);
			errorShow(ex.message);
			setLoadingInline(false);
		});
		if (res === undefined) return;
		res.forEach((v) => {
			v.balanceReal = 0;
			v.balanceFact = 0;
			v.defference = 0;
			v.defferenceAmount = 0;
			v.balanceRealSemifinish = 0;
		});
		inventoryPrepare(res);
		setLoadingInline(false);
		setIsEdit(true);
	};
	const modalAddHide = () => {
		modalAddClose();
		setStorageId(null);
	};
	const close = () => {
		setIsEdit(false);
		setIsShow(false);
		setId(null);
		setInventoryItems(null);
		setInventory(null);
		modalDeleteClose();
		modalAddHide();
		setTab(1);
	};
	const inventoryPrepare = (data) => {
		const inv = {};
		data.filter(f => f.isproduct).forEach((v) => {
			if (inv.products === undefined) inv.products = {};
			if (v.category) {
				const id = v.category.id;
				if (inv.products[id] === undefined) {
					inv.products[id] = {
						name:v.category.name,
						items:[]
					};
				}
				inv.products[id].items.push(v);
			}
		});
		data.filter(f => f.issemifinish).forEach((v) => {
			if (inv.semifinishes === undefined) inv.semifinishes = {};
			const id = 'sf';
			if (inv.semifinishes[id] === undefined) {
				inv.semifinishes[id] = {
					items:[]
				};
			}
			inv.semifinishes[id].items.push(v);
		});
		data.filter(f => !f.isproduct && !f.iscomponent).forEach((v) => {
			if (inv.components === undefined) inv.components = {};
			if (v.category) {
				const id = v.category.id;
				if (inv.components[id] === undefined) {
					inv.components[id] = {
						name:v.category.name,
						items:[]
					};
				}
				inv.components[id].items.push(v);
			}
		});
		setInventoryItems(inv);
		setInventory(data);
	};
	const save = async () => {
		const data = {
			storageId:storageId,
			date:date,
			items:inventory,
			surplus:totalSurplus(),
			shortage:totalShortage(),
			total:totalGet()
		};
		const res = id ?
				await Inventories.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.message);
				})
			:
				await Inventories.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow(ex.message);
				});
		if (res === undefined) return;
		if (res) {
			successShow(res.message);
			inventoriesGet();
			close();
		}
	};
	const controlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) controlHide();
		else setControlId(id);
		setTimeout(() => {
			try {
				const elemHeight = document.querySelector('.control-block').clientHeight;
				const parentHeight = document.querySelector('.products-list').clientHeight;
				const y = e.clientY;
				let top = y + elemHeight > parentHeight ? `-${elemHeight-20}px` : '46px';
				setControlTop(top);
			} catch (ex) { }
		}, 100);
	};
	const controlHide = () => {
		setControlId(null);
		setControlTop('-100000px');
	};
	const balanceSemifinishHandlerSet = (e, id) => {
		let val = e.target.value;
		if (val === '') val = 0;
		val = val?.replace(',', '.');
		val = parseFloat(val);
		val = Math.abs(val);
		val = isNaN(val) ? 0 : val;
		val = parseFloat(val.toFixed(val%1===0?'':2));
		semifinishCollect(id, val);
	};
	const balanceHandler = (e, id) => {
		inventory.forEach((v) => {
			if (v.id === id) v.balanceReal = e.target.value;
		});
		inventoryPrepare(inventory);
	};
	const balanceHandlerSet = (e, id) => {
		inventory.forEach((v) => {
			if (v.id === id) {
				let val = e.target.value;
				if (val === '') val = 0;
				val = val?.replace(',', '.');
				val = parseFloat(val);
				val = Math.abs(val);
				val = isNaN(val) ? 0 : val;
				val = val.toFixed(val%1===0?'':2);
				v.balanceReal = parseFloat(val);
				v.balanceFact = v.balanceReal + (v.balanceRealSemifinish||0);
				v.defference = v.balance - v.balanceReal;
				v.defferenceAmount = v.defference * (id ? v.price : v.total);
			}
		});
		inventoryPrepare(inventory);
	};
	const focusHandle = (e) => e.target.select();
	const show = (e, inv) => {
		e.stopPropagation();
		inventoryPrepare(inv.items);
		setId(inv._id);
		setIsEdit(false);
		setIsShow(true);
		controlHide();
		setStorageId(inv?.storage?.id||null);
	};
	const editInner = () => {
		inventoryPrepare(inventory);
		setIsEdit(true);
		setIsShow(false);
	};
	const edit = (e, inv) => {
		e.stopPropagation();
		inventoryPrepare(inv.items);
		setId(inv._id);
		setIsEdit(true);
		setIsShow(false);
		controlHide();
		setStorageId(inv?.storage?.id||null);
	};
	const stop = (e) => {
		e.stopPropagation();
		controlHide();
	};
	const deleteShow = (e, id) => {
		e.stopPropagation();
		setId(id);
		setName(Moment(inventories.find(f => f._id === id)?.date).format('DD.MM.YYYY HH:mm'));
		controlHide();
		modalDeleteOpen();
	};
	const remove = async () => {
		await Inventories.remove(id).catch((ex) => {
			console.log(666, ex);
			errorShow(ex.message);
		});
		inventoriesGet();
		close();
	};
	const storageSelect = (id) => setStorageId(id);
	const tabSet = (tab) => setTab(tab);
	const semifinishCollect = (id, count) => {
		const s = semifinishes.find(f => f._id === id);
		if (!s) return;
		const percent = (count * 100) / s.properties.brutto;
		if (s.items && s.items.length) {
			s.items.forEach((c) => {
				if (c.issemifinish) {
					semifinishCollect(c.id, semifinshComponentQuantityGet(c, percent));
				} else {
					inventory.forEach((v) => {
						if (c.id === v.id) {
							v.balanceRealSemifinish = semifinshComponentQuantityGet(c, percent) + (v.balanceRealSemifinish ? v.balanceRealSemifinish : 0);
							v.balanceFact = v.balanceReal + (v.balanceRealSemifinish||0);
							v.defference = v.balance - v.balanceReal;
							v.defferenceAmount = v.defference * (id ? c.price : c.total);
						}
					});
				}
			});
		}
		inventoryPrepare(inventory);
	};
	const semifinshComponentQuantityGet = (component, percent) => component.pieses || ((component.brutto * percent) / 100);
	const rowGet = (title, data) => <>
		<tr className="section"><td colSpan="12">{title}</td></tr>
		{Object.entries(data).map(([k,v],i) => <React.Fragment key={i}>
			<tr><td colSpan="12"><b>{v.name}</b></td></tr>
			{v.items.map((v,i) => <tr key={i}>
				<td>{v.name} ({measureTypeName[v.measure]})</td>
				<td className="amount">{v.inventory}</td>
				<td className="amount">{v.income}</td>
				<td className="amount">{v.release}</td>
				<td className="amount">{v.expense}</td>
				<td className="amount">{v.writeoff}</td>
				<td className="amount">{v.balance}</td>
				<td className="amount">
					<input type="text" placeholder="0" value={v.balanceReal} maxLength={8} onChange={(e) => balanceHandler(e, v.id)} onBlur={(e) => balanceHandlerSet(e, v.id)} onFocus={focusHandle} />
				</td>
				<td className="amount">{Utils.numFix(v.balanceRealSemifinish)}</td>
				<td className="amount">{Utils.numFix(v.balanceFact)}</td>
				<td className="amount">{Utils.numFix(v.defference)}</td>
				<td className="amount">{Utils.numFix(v.defferenceAmount)}</td>
			</tr>)}
		</React.Fragment>)}
	</>;
	const rowViewGet = (title, data, issemifinish) => <>
		<tr className="section"><td colSpan="8">{title}</td></tr>
		{Object.entries(data).map(([k,v],i) => <React.Fragment key={i}>
			{issemifinish ? null : <tr className="section section-lite"><td colSpan="8"><b>{v.name}</b></td></tr>}
			{v.items.map((v,i) => <tr key={i}>
				<td>{v.name}</td>
				<td className="status status-small">{measureTypeName[v.measure]}</td>
				<td className="amount">{Utils.numFix(v.balance)}</td>
				<td className="amount">{Utils.numFix(v.balanceReal)}</td>
				<td className="amount">{Utils.numFix(v.defference)}</td>
				<td className="amount">{Utils.numFix(v.defferenceAmount)}</td>
			</tr>)}
		</React.Fragment>)}
	</>;
	const storageGet = (id) => storages.find(f => f._id === id)?.name || '–';
	const totalSumGet = () => inventories ? inventories.reduce((acc, v) => acc + (v.total ? v.total : 0), 0).toFixed(2) : 0;
	const totalSurplus = () => {
		let sum = 0;
		if (inventoryItems.products) Object.entries(inventoryItems.products).forEach(([k,v]) => v.items.forEach((v) => sum += v.defferenceAmount > 0 ? v.defferenceAmount : 0));
		if (inventoryItems.semifinishes) Object.entries(inventoryItems.semifinishes).forEach(([k,v]) => v.items.forEach((v) => sum += v.defferenceAmount > 0 ? v.defferenceAmount : 0));
		if (inventoryItems.components) Object.entries(inventoryItems.components).forEach(([k,v]) => v.items.forEach((v) => sum += v.defferenceAmount > 0 ? v.defferenceAmount : 0));
		return sum.toFixed(2);
	};
	const totalShortage = () => {
		let sum = 0;
		if (inventoryItems.products) Object.entries(inventoryItems.products).forEach(([k,v]) => v.items.forEach((v) => sum += v.defferenceAmount < 0 ? v.defferenceAmount : 0));
		if (inventoryItems.semifinishes) Object.entries(inventoryItems.semifinishes).forEach(([k,v]) => v.items.forEach((v) => sum += v.defferenceAmount < 0 ? v.defferenceAmount : 0));
		if (inventoryItems.components) Object.entries(inventoryItems.components).forEach(([k,v]) => v.items.forEach((v) => sum += v.defferenceAmount < 0 ? v.defferenceAmount : 0));
		return sum.toFixed(2);
	};
	const totalGet = () => {
		let sum = 0;
		if (inventoryItems.products) Object.entries(inventoryItems.products).forEach(([k,v]) => v.items.forEach((v) => sum += v.defferenceAmount||0));
		if (inventoryItems.semifinishes) Object.entries(inventoryItems.semifinishes).forEach(([k,v]) => v.items.forEach((v) => sum += v.defferenceAmount||0));
		if (inventoryItems.components) Object.entries(inventoryItems.components).forEach(([k,v]) => v.items.forEach((v) => sum += v.defferenceAmount||0));
		return sum.toFixed(2);
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Инвентаризации"
							type="warehouse"
							allHide={true}
							contentRows={<>
								<div className="inventory-container" onClick={stop}>
									<div className="controls">
										<input type="datetime-local" {...bindDate} onChange={dateChange} />
										{isEdit ?
												<>
													<div className={`tab${tab===0?' tab-active':''}`} onClick={() => tabSet(0)}>Полуфабрикаты</div>
													<div className={`tab${tab===1?' tab-active':''}`} onClick={() => tabSet(1)}>Ингредиенты и товары</div>
												</>
											: null}
										{isShow && storageId ? <div className="title">{storageGet(storageId)}</div> : null}
										<div className="buttons">
											{isEdit ?
													<>
														<button type="button" onClick={save}>Сохранить</button>
														<img src={imgClose} alt="" onClick={close} />
													</>
												: null}
											{isShow ?
													<>
														{inventories.findIndex(f => f._id === id) ? null : <img src={imgEdit} alt="" onClick={editInner} />}
														<img src={imgClose} alt="" onClick={close} />
													</>
												: null}
											{!isShow && !isEdit ? <img src={imgPlus} alt="" onClick={storageShow} /> : null}
										</div>
									</div>
									<div className="items-container">
										{!isShow && !isEdit ?
											(inventories === null ? 
													<div className="products-empty">
														<div>
															Добавьте<br/>первую инвентаризацию
														</div>
														<img src={imgArrowCurveNE} alt="" />
													</div>
												:
													(loadingInline ? <LoaderInline /> :
														(inventories.length > 0 ?
																<table className="items items-invenrory-list">
																	<thead>
																		<tr>
																			<th>Склад</th>
																			<th>Дата</th>
																			<th className="prices prices-mid">Результат</th>
																			<th className="control"></th>
																		</tr>
																	</thead>
																	<tbody>
																		{inventories.map((v,i) => <tr key={i} onClick={(e) => show(e, v)}>
																			<td>{v.storage? v.storage.name : '–'}</td>
																			<td>{Moment(v.date).format('DD.MM.YYYY HH:mm')}</td>
																			<td className="prices prices-mid">{v.total||0}</td>
																			<td className="control">
																				<div className="control-inner">
																					<img src={imgMenuPoints} alt="" onClick={(e) => controlSelect(e, v._id)} />
																					{controlId === v._id ?
																						<div className="control-block" style={{top:controlTop}}>
																							{i === 0 ? <div className="edit" onClick={(e) => edit(e, v)}>Редактировать</div> : null}
																							{i === 0 ? <div className="delete" onClick={(e) => deleteShow(e, v._id)}>Удалить</div> : null}
																							{i > 0 ? <div className="empty" onClick={stop}>Нет доступных действий</div> : null}
																						</div>
																					: null}
																				</div>
																			</td>
																		</tr>)}
																		<tr className="info-row">
																			<td colSpan="2">Итого</td>
																			<td className="prices">{totalSumGet()}</td>
																			<td></td>
																		</tr>
																	</tbody>
																</table>
															:
																<div className="empty">Нет данных</div>
														)
													)
											) : null}
										{(isShow || isEdit) && inventoryItems ?
											<>
												{isShow ?
														<>
															<table className="items items-no-control">
																<thead>
																	<tr>
																		<th>Товар</th>
																		<th className="status status-small">Ед.</th>
																		<th className="amount">Остаток (план)</th>
																		<th className="amount">Остаток (факт)</th>
																		<th className="amount">Разница (кол-во)</th>
																		<th className="amount">Разница (сумма)</th>
																	</tr>
																</thead>
																<tbody>
																	{inventoryItems.products ? rowViewGet('Товары', inventoryItems.products, false) : null}
																	{inventoryItems.semifinishes ? rowViewGet('Полуфабрикаты', inventoryItems.semifinishes, true) : null}
																	{inventoryItems.components ? rowViewGet('Компоненты', inventoryItems.components, false) : null}
																</tbody>
															</table>
															<div className="inventory-results">
																<div>Излишек: <b>{totalSurplus()}</b></div>
																<div>Недостача: <b>{totalShortage()}</b></div>
																<div>Итого: <b>{totalGet()}</b></div>
															</div>
														</>
													: null}
												{isEdit ?
													(tab === 0 ?
															<table className="items items-no-control">
																<thead>
																	<tr>
																		<th>Товар</th>
																		<th className="amount">Инвент.</th>
																		<th className="amount">Поступления</th>
																		<th className="amount">Выпуск</th>
																		<th className="amount">Расход</th>
																		<th className="amount">Списано</th>
																		<th className="amount">План.остаток</th>
																		<th className="amount">Факт.остаток</th>
																	</tr>
																</thead>
																<tbody>
																	{inventoryItems.semifinishes ?
																			<>
																				{Object.entries(inventoryItems.semifinishes).map(([k,v],i) => <React.Fragment key={i}>
																					{v.items.map((v,i) => <tr key={i}>
																						<td>{v.name} ({measureTypeName[v.measure]})</td>
																						<td className="amount">{v.inventory}</td>
																						<td className="amount">{v.income}</td>
																						<td className="amount">{v.release}</td>
																						<td className="amount">{v.expense}</td>
																						<td className="amount">{v.writeoff}</td>
																						<td className="amount">{v.balance}</td>
																						<td className="amount">
																							<input type="text" placeholder="0" value={v.balanceReal} maxLength={8} onChange={(e) => balanceHandler(e, v.id)} onBlur={(e) => balanceSemifinishHandlerSet(e, v.id)} onFocus={focusHandle} />
																						</td>
																					</tr>)}
																				</React.Fragment>)}
																			</>
																		: null}
																</tbody>
															</table>
														:
															<>
																<table className="items items-no-control items-no-control-full">
																	<thead>
																		<tr>
																			<th>Товар или компонент<br/>&nbsp;</th>
																			<th className="amount">Последняя<br/>инвент.</th>
																			<th className="amount">Поступления<br/>на склад</th>
																			<th className="amount">Выпуск<br/>&nbsp;</th>
																			<th className="amount">Расход<br/>&nbsp;</th>
																			<th className="amount">Списано<br/>&nbsp;</th>
																			<th className="amount">План.<br/>остаток</th>
																			<th className="amount">Факт.<br/>остаток</th>
																			<th className="amount">Факт.остаток<br/>(блюды,п/ф)</th>
																			<th className="amount">Сумма факт.<br/>остатка</th>
																			<th className="amount">Разница<br/>(кол-во)</th>
																			<th className="amount">Разница<br/>(сумма)</th>
																		</tr>
																	</thead>
																	<tbody>
																		{inventoryItems.products ? rowGet('Товары', inventoryItems.products) : null}
																		{inventoryItems.components ? rowGet('Компоненты', inventoryItems.components) : null}
																	</tbody>
																</table>
																<div className="inventory-results">
																	<div>Излишек: <b>{totalSurplus()}</b></div>
																	<div>Недостача: <b>{totalShortage()}</b></div>
																	<div>Итого: <b>{totalGet()}</b></div>
																</div>
															</>
													) : null}
											</> : null}
									</div>
								</div>
							</>}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalDelete>
				<div className="modal">
					<div className="header">
						<h4>Удаление</h4>
						<img src={imgClose} alt="" onClick={modalDeleteClose} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить инвентаризацию от <b>{name}</b>?</p>
					<p>Внимание, эту операцию нельзя отменить!</p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={remove} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
			<ModalAdd>
				<div className="modal modal-storage">
					<div className="header">
						<h4>Выберите склад</h4>
						<img src={imgClose} alt="" onClick={modalAddClose} className="btn-close" />
					</div>
					<div className="storages">
						{storages.map((v,i) => <div key={i} className="item">
							<div>
								{v.name}
								<span>
									{v.points.map(v => v.name).join(', ')}
								</span>
							</div>
							<img src={storageId === v._id ? imgCheckOn : imgCheckOff} alt="" onClick={() => storageSelect(v._id)} />
						</div>)}
					</div>
					<div className="buttons">
						<button type="button" onClick={modalAddClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={prepare} className="btn-accept">Выбрать</button>
					</div>
				</div>
			</ModalAdd>
		</>
	);
};

export default InventoriesScreen;