import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import ComponentInline from '../../../Components/ComponentInline';
import ComponentPositionList from '../../../Components/ComponentPositionList';
import ComponentPopupStorage from '../../../Components/ComponentPopupStorage';

// models
import {Components, Products, Reasons, Semifinishes, Storages, Writeoffs} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// styles
import './styles.css';

// images
import imgClose from './Images/close.svg';
import imgPlusButton from './Images/plus-btn.svg';


// start
const WriteoffsScreen = () => {
	const [ModalReason, modalReasonOpen, modalReasonClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [writeoffsAll, setWriteoffsAll] = useState([]);
	const [writeoffs, setWriteoffs] = useState(null);
	const [storageId, setStorageId] = useState(null);
	const [storages, setStorages] = useState([]);
	const [storagesAll, setStoragesAll] = useState([]);
	const [id, setId] = useState(null);
	const [name, setName] = useState(null);
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:date, setValue:setDate, bind:bindDate} = useInput('');
	const [items, setItems] = useState([]);
	const [total, setTotal] = useState(0);
	const [inventory, setInventory] = useState([]);
	const [reasonId, setReasonId] = useState(null);
	const [reasons, setReasons] = useState([]);
	const [components, setComponents] = useState([]);
	const [products, setProducts] = useState([]);
	const [semifinishes, setSemifinishes] = useState([]);
	const {value:reasonName, setValue:setReasonName, bind:bindReasonName} = useInput('');
	const {value:reasonDescription, setValue:setReasonDescription, bind:bindReasonDescription} = useInput('');
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const point = ls('point');
			await storagesGet(point.id);
			await reasonsGet();
			await componentsGet();
			await productsGet();
			await semifinishesGet();
			await writeoffsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const writeoffsGet = async () => {
		const writeoffs = await Writeoffs.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (writeoffs === undefined || writeoffs.length === 0) return;
		setWriteoffs(writeoffs);
		setWriteoffsAll(writeoffs);
	};
	const storagesGet = async (pointId) => {
		const storages = await Storages.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (storages === undefined) return;
		const s = storages.filter(f => f.points.filter(f => f.id === pointId).length !== 0);
		setStorages(s);
		setStoragesAll(storages);
	};
	const componentsGet = async () => {
		const components = await Components.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (components === undefined) return;
		setComponents(components);
	};
	const semifinishesGet = async () => {
		const semifinishes = await Semifinishes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (semifinishes === undefined) return;
		setSemifinishes(semifinishes);
	};
	const productsGet = async () => {
		const products = await Products.get.active().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		setProducts(products);
	};
	const reasonsGet = async () => {
		const reasons = await Reasons.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (reasons === undefined) return;
		setReasons(reasons);
	};
	const writeoffAdd = () => show();
	const writeoffEdit = (writeoff) => {
		writeoff = writeoff || writeoffs.find(f => f._id === id);
		show(writeoff);
	};
	const writeoffShow = (writeoff) => show(writeoff);
	const show = (writeoff) => {
		setId(writeoff?._id||null);
		setName(writeoff ? moment(writeoff.date).format('DD.MM.YYYY HH:mm') : '');
		setDate(Utils.dateTimeNormalize(writeoff, 'date'));
		setDescription(writeoff?.description||'');
		setReasonId(writeoff?.reason?.id||null);
		setStorageId(writeoff?.storage?.id||null);
		setItems(writeoff?.items||[]);
		setTotal(writeoff?.total||0);
		setInventory(writeoff?.inventory||null);
	};
	const cancel = () => {
		writeoffShow(null);
		modalReasonClose();
	};
	const save = async () => {
		const data = {
			date:date,
			reasonId:reasonId,
			storageId:storageId,
			description:description,
			items:items,
			total:calcTotalGet()
		};
		if (Utils.empty(data.date)) {
			errorShow('Необходимо заполнить дату');
			return;
		}
		if (Utils.empty(data.storageId)) {
			errorShow('Необходимо выбрать склад');
			return;
		}
		if (Utils.empty(data.reasonId)) {
			errorShow('Необходимо выбрать причину списания');
			return;
		}
		if (items.length === 0) {
			errorShow('Необходимо добавить компоненты');
			return;
		}
		const res = id ?
				await Writeoffs.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Writeoffs.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			writeoffsGet();
			cancel();
			return true;
		}
	}
	const handleStorage = (e) => setStorageId(e.target.value);
	const searchWriteoff = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setWriteoffs(writeoffsAll);
		else setWriteoffs(writeoffsAll.filter(f => f.date.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const writeoffDelete = (id) => {
		Writeoffs.remove(id).then((res) => {
			successShow(res.message);
			writeoffsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const handleReason = (e) => setReasonId(e.target.value);
	const calcTotalGet = () => items.reduce((acc, v) => acc += parseFloat(v.total), 0).toFixed(2);
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = writeoffs.find(f => f._id === v);
			if (s) {
				const data = {
					date:Utils.dateTimeNormalize(null, 'date'),
					storageId:s.storage.id,
					reasonId:s.reason.id,
					description:s.description,
					items:s.items,
					total:s.total
				};
				await Writeoffs.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		writeoffsGet();
		cancel();
		return true;
	};
	const reasonAddShow = () => {
		setReasonName('');
		setReasonDescription('');
		modalReasonOpen();
	};
	const reasonSave = async (e) => {
		e.preventDefault();
		const data = {
			name:reasonName,
			description:reasonDescription
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название причины');
			return;
		}
		const res = await Reasons.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			reasonsGet();
			cancel();
			return true;
		}
	};
	const totalSumGet = () => writeoffs ? writeoffs.reduce((acc, v) => acc + v.total, 0).toFixed(2) : 0;
	const componentChange = (items) => setItems([...items]);
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Списания"
							type="warehouse"
							data={writeoffs}
							id={id}
							search={search}
							rows={[
								{title:'Дата',field:'date',func:(v) => <>
									{moment(v.date).format('DD.MM.YYYY HH:mm')}
									{v.description ? <span>{v.description}</span> : null}
								</>},
								{title:'Склад',field:'storage',class:'area',sort:'storage.name',func:(v) => v.storage ? v.storage.name : '–'},
								{title:'Причина',field:'reason',class:'area',sort:'reason.name',func:(v) => v.reason ? v.reason.name : '–'},
								{title:'Сумма',field:'total',class:'prices'}
							]}
							rowsShort={['date','total']}
							footerItems={[
								{title:'Сделать копию',action:massCopy}
							]}
							rowsFooter={<>
								<tr className="info-row">
									<td colSpan="3">Итого</td>
									<td className="prices">{totalSumGet()}</td>
									<td colSpan="2"></td>
								</tr>
							</>}
							empty={<>Добавьте первое<br/>списание</>}
							contentShowTitle={name}
							contentShow={<>
								{inventory ?
									<div className="product-view-row product-view-row-alert">
										Это списание нельзя изменить или удалить! Оно используется в инвентаризации от {moment(inventory.date).format('DD.MM.YYYY HH:mm')}
									</div>
								: null}
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<div className="product-view-row product-view-row-oneline">
									<span>Дата</span>
									<div>{moment(date).format('DD.MM.YYYY HH:mm')}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Склад</span>
									<div>{storagesAll.find(f => f._id === storageId)?.name||'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Причина</span>
									<div>{reasons.find(f => f._id === reasonId)?.name||'–'}</div>
								</div>
								{inventory ?
									<div className="product-view-row product-view-row-oneline">
										<span>Инвентаризация от</span>
										<div>{moment(inventory.date).format('DD.MM.YYYY HH:mm')}</div>
									</div> : null}
								<ComponentPositionList items={items} />
							</>}
							contentEdit={<>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-first">
									<label htmlFor="date" className="label-middle">Дата</label>
									<input id="date" {...bindDate} type="datetime-local" required />
								</div>
								<div className="product-edit-row">
									<div className="select select-wide">
										<select onChange={handleStorage} value={storageId} required>
											<option value="">Выберите склад</option>
											{storages.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
								</div>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
									<div className="select select-wide">
										<select onChange={handleReason} value={reasonId} required>
											<option value="">Выберите причину списания</option>
											{reasons.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
									<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => reasonAddShow()} />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Примечание" {...bindDescription} className="lite"></textarea>
								</div>
								<ComponentInline
									title="Позиции"
									data={{components,semifinishes,products}}
									selected={items}
									isShow={items.length===0}
									onUpdate={componentChange}
									info={<>
										{items.length ?
											items.map((v,i) => (v.name)).join(', ') : null}
									</>}
									notice={<>
										<p className="row-notice">
											Добавить можно только товары, имеющие складской остаток. 
											Сейчас таких товаров {products.length||'нет'} шт.
										</p>
									</>}
									additionButtonComponent={<>
										<ComponentPopupStorage title={'Найти позиции'} data={{components,semifinishes,products}} selected={items} allow={['component','semifinish','product']} onUpdate={componentChange} />
									</>} />
							</>}
							deleteExpression={(v) => !v.inventory}
							editExpression={(v) => !v.inventory}
							hideEditExpression={() => inventory}
							onDelete={writeoffDelete}
							onEdit={writeoffEdit}
							onAdd={writeoffAdd}
							onSave={save}
							onSearch={searchWriteoff}
							onShow={writeoffShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalReason>
				<div className="modal modal-writeoffs">
					<div className="header">
						<h4>Добавить причину списания</h4>
						<img src={imgClose} alt="" onClick={modalReasonClose} className="btn-close" />
					</div>
					<form className="product-edit" onSubmit={reasonSave}>
						<div className="container-inner">
							<div className="list">
								<div className="product-edit-row">
									<input type="text" {...bindReasonName} placeholder="Название причины" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindReasonDescription}></textarea>
								</div>
							</div>
						</div>
						<div className="product-edit-footer">
							<button type="button" onClick={modalReasonClose} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-save btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalReason>
		</>
	);
};

export default WriteoffsScreen;