import React, {useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import {useInput} from '../../../Components/Hooks/Input';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Users} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { userStatus, userStatusNameWork, userTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgArrowRight from './Images/arrow-right.svg';
import imgArrowLeft from './Images/arrow-left.svg';
import imgClose from './Images/close.svg';
import imgMenuPoints from './Images/menu-points.svg';
import NotFound from '../../../Components/NotFound';


// start
const WorkTimeScreen = (props) => {
	const history = useHistory();
	const [ModalDay, modalDayOpen, modalDayClose] = useModal('root');
	const [ModalSettings, modalSettingsOpen, modalSettingsClose] = useModal('root');
	const [ModalCopyUser, modalCopyUserOpen, modalCopyUserClose] = useModal('root');
	const [ModalCopyMonth, modalCopyMonthOpen, modalCopyMonthClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [usersAll, setUsersAll] = useState([]);
	const [user, setUser] = useState([]);
	const [id, setId] = useState(null);
	const [userId, setUserId] = useState(null);
	const [worktime, setWorktime] = useState([]);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [day, setDay] = useState(null);
	const {value:start, setValue:setStart, bind:bindStart} = useInput('');
	const {value:end, setValue:setEnd, bind:bindEnd} = useInput('');
	const {value:dateStart, setValue:setDateStart, bind:bindDateStart} = useInput('');
	const {value:dateEnd, setValue:setDateEnd, bind:bindDateEnd} = useInput('');
	const [search, setSearch] = useState(null);
	const [isShiftPressed, setIsShiftPressed] = useState(false);
	const [controlId, setControlId] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await usersGet();
			setMonth(moment().format('MM'));
			setYear(moment().format('YYYY'));
		};
		await dataGet();
		setLoading(false);
	}, []);
	useEffect(() => {
		if (users && users.length > 0) {
			const user = users.find(f => f._id === props.match.params.id);
			if (user) userShow(user);
		}
	}, [users]);
	const usersGet = async (id) => {
		const users = await Users.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (users === undefined) return;
		setUsers(users);
		setUsersAll(users);
		if (id) {
			const user = users.find(f => f.id === id);
			userShow(user);
		}
	};
	const searchUser = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setUsers(usersAll);
		else setUsers(usersAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const userShow = (u) => {
		if (user?._id === u?._id) u = null
		show(u);
	}
	const show = (user) => {
		setUser(user);
		setId(user?._id||null);
		setWorktime(user?.worktime||[]);
	};
	const cancel = () => {
		userShow(null);
		modalDayClose();
		modalSettingsClose();
		modalCopyUserClose();
		modalCopyMonthClose();
		setStart('');
		setEnd('');
		setDateStart('');
		setDateEnd('');
		setIsShiftPressed(false);
		setUserId(null);
	}
	const mass = async (e) => {
		e.preventDefault();
		if (Utils.empty(dateStart) || Utils.empty(dateEnd) || Utils.empty(start) || Utils.empty(end)) {
			errorShow('Заполните все поля');
			return;
		}
		if (moment(dateStart) > moment(dateEnd)) {
			errorShow('Дата начала не может быть больше даты окончания');
			return;
		}
		if (parseInt(start.replace(':','')) > parseInt(end.replace(':',''))) {
			errorShow('Дата начала не может быть больше даты окончания');
			return;
		}
		const data = worktime || [];
		const tmpStart = moment(dateStart);
		const tmpEnd = moment(dateEnd);
		while (tmpStart <= tmpEnd) {
			const date = tmpStart.format('YYYY-MM-D');
			data.push({date,start,end});
			tmpStart.add(1, 'day');
		}
		const res = await Users.worktime(user._id, {worktime:data}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			usersGet();
			cancel();
		}
	};
	const save = async (e) => {
		e.preventDefault();
		const date = dateGet(`${year}-${month}-${day}`);
		const data = worktime.find(f => dateGet(f.date) === date);
		if (data) {
			data.start = start;
			data.end = end;
			worktime[worktime.findIndex(f => dateGet(f.date) === date)] = data;
		} else worktime.push({date,start,end});
		if (Utils.empty(start) || Utils.empty(end)) {
			errorShow('Заполните начало и конец рабочего дня');
			return;
		}
		if (parseInt(start.replace(':','')) > parseInt(end.replace(':',''))) {
			errorShow('Дата начала не может быть больше даты окончания');
			return;
		}
		const res = await Users.worktime(id, {worktime}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			usersGet();
			cancel();
		}
	};
	const reset = async (e, userId, day) => {
		e.stopPropagation();
		const date = dateGet(`${year}-${month}-${day}`);
		const data = users.find(f => f._id === userId)?.worktime.filter(f => dateGet(f.date) !== dateGet(date));
		const res = await Users.worktime(userId, {worktime:data}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			usersGet();
			cancel();
		}
	};
	const monthPrev = () => {
		const m = moment(`${year}-${month}-01`).subtract(1, 'month');
		setMonth(m.format('MM'));
		setYear(m.format('YYYY'));
	};
	const monthNext = () => {
		const m = moment(`${year}-${month}-01`).add(1, 'month');
		setMonth(m.format('MM'));
		setYear(m.format('YYYY'));
	};
	const monthGetDays = () => {
		const days = [];
		const daysInMonth = moment(`${year}-${month}-01`).daysInMonth();
		for (let i = 1; i <= daysInMonth; i++) days.push(i);
		return days;
	}
	const checkDay = (userId, day) => {
		const date = dateGet(`${year}-${month}-${day}`);
		if (isShiftPressed && userId === id) {
			if (dateStart <= date && dateEnd >= date) return ' selected';
		}
		return users.find(f => f._id === userId)?.worktime.find(f => dateGet(f.date) === date) ? ' added' : '';
	};
	const isVacant = (num) => {
		const date = moment(`${year}-${month}-${num}`);
		const day = date.day();
		return day === 0 || day === 6 ? ' weekend' : '';
	};
	const dayShow = (e, user, day) => {
		e.stopPropagation();
		setControlId(null);
		if (e.shiftKey) {
			const date = dateGet(`${year}-${month}-${day}`);
			if (isShiftPressed) {
				setDateEnd(date);
				setControlId(null);
				setUser(user);
				setId(user._id);
				setWorktime(user.worktime||[]);
				modalSettingsOpen();
			}
			else {
				setIsShiftPressed(true);
				setDateStart(date);
			}
			return;
		}
		setIsShiftPressed(false);
		setDateStart('');
		setDateEnd('');
		setDay(day);
		setId(user._id);
		setWorktime(user.worktime||[]);
		setStart(user?.worktime?.find(f => dateGet(f.date) === dateGet(`${year}-${month}-${day}`))?.start||'');
		setEnd(user?.worktime?.find(f => dateGet(f.date) === dateGet(`${year}-${month}-${day}`))?.end||'');
		modalDayOpen();
	};
	const dateGet = (date) => moment(date).format('YYYY-MM-DD');
	const userControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const settingsShow = (e, user) => {
		e.stopPropagation();
		setControlId(null);
		setUser(user);
		setId(user._id);
		setWorktime(user.worktime||[]);
		modalSettingsOpen();
	}
	const copyUserShow = (e, user) => {
		e.stopPropagation();
		setControlId(null);
		setUserId(null);
		if (!user.worktime || user.worktime.length === 0) {
			errorShow('У сотрудника нет графика работы');
			return;
		}
		setUser(user);
		setId(user._id);
		modalCopyUserOpen();
	};
	const copyMonthShow = (e, user) => {
		e.stopPropagation();
		setControlId(null);
		setUserId(null);
		if (!user.worktime || user.worktime.length === 0) {
			errorShow('У сотрудника нет графика работы');
			return;
		}
		setUser(user);
		setId(user._id);
		modalCopyMonthOpen();
	};
	const copyUser = async () => {
		if (!userId) {
			errorShow('Выберите сотрудника');
			return;
		}
		if (userId === id) {
			errorShow('Нельзя копировать график на того же сотрудника');
			return;
		}
		const worktime = [...users.find(f => f._id === userId)?.worktime||[], ...users.find(f => f._id === id)?.worktime||[]];
		const res = await Users.worktime(userId, {worktime}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			usersGet();
			cancel();
		}
	};
	const copyMonth = async () => {
		const worktime = users.find(f => f._id === id)?.worktime || [];
		worktime.forEach((v) => {
			const date = moment(v.date);
			const prevMonth = moment(`${year}-${month}-01`).subtract(1, 'month');
			if (date.format('MM') === prevMonth.format('MM')) {
				worktime.push({
					date:dateGet(date.add(1, 'month')),
					start:v.start,
					end:v.end
				});
			}
		});
		if (worktime.length === 0) {
			errorShow('У сотрудника нет графика работы за прошлый месяц');
			return;
		}
		const res = await Users.worktime(id, {worktime}).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			usersGet();
			cancel();
		}
	};
	const handleUser = (e) => setUserId(e.target.value);
	const gotoUser = (id) => history.push(`/books/user${id?`/${id}`:'s'}`);
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="worktime-container">
							<div className="categories-container">
								<SideMenu title="Табель" type="statistics" disabled={true} />
								<div className="products-list">
									{users === null ?
										<div className="products-empty">
											<Empty image={'users'} text={
													<>
														Для просмотра табеля<br />нужно добавить сотрудников<br />
														<button type="button" onClick={() => gotoUser()} className="user-add">Добавить сотрудника</button>
													</>
												} />
										</div>
									:
										<>
											<div className="products-list-controls">
												<input type="search" placeholder="Поиск" className="search" onChange={searchUser} value={search} />
												<div className="months">
													<button type="button" onClick={() => monthPrev()}>
														<img src={imgArrowLeft} alt="" />
													</button>
													<div className="month-name">{moment(`${year}-${month}-01`).format('MMMM, Y')}</div>
													<button type="button" onClick={() => monthNext()}>
														<img src={imgArrowRight} alt="" />
													</button>
												</div>
											</div>
											<div className="container-inner">
												{users.length > 0 ?
														<table className="items">
															<thead>
																<tr>
																	<th>Имя</th>
																	<th className="status">Статус</th>
																	<th className="control"></th>
																	<th className="timetable">
																		<div className="timetable-days">
																			{monthGetDays().map((v,i) => <div key={i} className={`day${isVacant(v)}`}>{i+1}</div>)}
																		</div>
																	</th>
																</tr>
															</thead>
															<tbody>
																{users.map((v,i) => <tr key={i} onClick={() => userShow(v)} className={id===v._id?'row-selected':''}>
																	<td>
																		{v.name}
																		<span>{userTypeName[v.type]}</span>
																	</td>
																	<td className="status">
																		<div className={`status${v.status===userStatus.ACTIVE?(v.worktime.length?v.status:9):0}`}>
																			<div className="status-point"></div>{v.status===userStatus.ACTIVE?(v.worktime.length?userStatusNameWork[v.status]:'Нет графика'):userStatusNameWork[0]}
																		</div>
																	</td>
																	<td className="control">
																		<img src={imgMenuPoints} alt="" onClick={(e) => userControlSelect(e, v._id)} />
																		{controlId === v._id ?
																				<div className="control-block">
																					<div className="copy" onClick={(e) => copyUserShow(e, v)}>Копия графика</div>
																					<div className="copy" onClick={(e) => copyMonthShow(e, v)}>Копия прошлого месяца</div>
																					<div className="settings" onClick={(e) => settingsShow(e, v)}>Настройки</div>
																					<div className="profile" onClick={() => gotoUser(v._id)}>Профиль</div>
																				</div>
																			: null}
																	</td>
																	<td className="timetable">
																		<div className="timetable-days">
																			{monthGetDays().map((d,i) => <div key={i} className={`day${checkDay(v._id, d)}`} onClick={(e) => dayShow(e, v, d)}>
																				{v.worktime.find(f => dateGet(f.date) === dateGet(`${year}-${month}-${d}`)) ?
																					<div className="tooltip">
																						<div>{moment(`${year}-${month}-${d}`).format('ddd, DD.MM')}</div>
																						{v.worktime.find(f => dateGet(f.date) === dateGet(`${year}-${month}-${d}`))?.start||'-'} – {v.worktime.find(f => dateGet(f.date) === dateGet(`${year}-${month}-${d}`))?.end||'-'}
																						<div className="delete" onClick={(e) => reset(e, v._id, d)}>&times; удалить</div>
																					</div> : null}
																			</div>)}
																		</div>
																	</td>
																</tr>)}
															</tbody>
														</table>
													:
														<NotFound />
												}
											</div>
										</>
									}
								</div>
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
			<ModalDay>
				<div className="modal modal-day">
					{year && month && day ?
						<div className="day-info">
							{moment(`${year}-${month}-${day}`).format('D MMMM')} <span>{moment(`${year}-${month}-${day}`).format('dddd')}</span>
						</div> : null}
					<div className="header">
						<h4>Установка рабочего времени</h4>
						<img src={imgClose} alt="" onClick={cancel} className="btn-close" />
					</div>
					<form onSubmit={save}>
						<div className="container-inner">
							<div className="user-info">
								<b>{user?.name}</b>
								<div>{userTypeName[user?.type]}</div>
							</div>
							<div className="worktime">
								<div className="worktime-row">
									<label>Работает</label>
									с
									<div>
										<input type="time" {...bindStart} required />
									</div>
									до
									<div>
										<input type="time" {...bindEnd} required />
									</div>
								</div>
							</div>
						</div>
						{worktime.find(f => dateGet(f.date) === dateGet(`${year}-${month}-${day}`)) ?
							<div className="worktime-reset" onClick={(e) => reset(e, id, day)}>&times; Удалить время</div> : null}
						<p className="notice">Автозаполнение времени прибытия и убытия можно настроить в разделе <b>Сотрудники</b>.</p>
						<div className="buttons">
							<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalDay>
			<ModalSettings>
				<div className="modal modal-day modal-settings">
					<div className="header">
						<h4>Установка рабочего времени</h4>
						<img src={imgClose} alt="" onClick={cancel} className="btn-close" />
					</div>
					<form onSubmit={mass}>
						<div className="container-inner">
							<div className="user-info">
								<b>{user?.name}</b>
								<div>{userTypeName[user?.type]}</div>
							</div>
							<div className="worktime">
								<div className="worktime-row">
									<label>Начало</label>
									<div>
										<input type="date" {...bindDateStart} className="date"  required />
									</div>
								</div>
								<div className="worktime-row">
									<label>Конец</label>
									<div>
										<input type="date" {...bindDateEnd} className="date"  required />
									</div>
								</div>
							</div>
							<div className="worktime">
								<div className="worktime-row">
									<label>Работает</label>
									с
									<div>
										<input type="time" {...bindStart} required />
									</div>
									до
									<div>
										<input type="time" {...bindEnd} required />
									</div>
								</div>
							</div>
							<p className="notice">Ранее установленные значения времени будут заменены на новые.</p>
						</div>
						<div className="buttons">
							<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalSettings>
			<ModalCopyUser>
				<div className="modal modal-worktime-copy">
					<div className="header">
						<h4>Копирование графика</h4>
						<img src={imgClose} alt="" onClick={modalCopyUserClose} className="btn-close" />
					</div>
					<p>Выберите сотрудника, на которого будет скопирован график работы <b>{user?.name}</b>.</p>
					<p>Текущий график будет заменен на новый.</p>
					<div className="select">
						<select onChange={handleUser}>
							<option>Выберите сотрудника</option>
							{users?.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
						</select>
					</div>
					<div className="buttons">
						<button type="button" onClick={modalCopyUserClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={copyUser} className="btn-accept">Копировать</button>
					</div>
				</div>
			</ModalCopyUser>
			<ModalCopyMonth>
				<div className="modal modal-worktime-copy">
					<div className="header">
						<h4>Копирование графика</h4>
						<img src={imgClose} alt="" onClick={modalCopyMonthClose} className="btn-close" />
					</div>
					<p>Будет скопирован график работы сотрудника <b>{user?.name}</b> за прошлый месяц.</p>
					<p>Текущий график будет заменен на новый.</p>
					<div className="buttons">
						<button type="button" onClick={modalCopyMonthClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={copyMonth} className="btn-accept">Копировать</button>
					</div>
				</div>
			</ModalCopyMonth>
		</>
	);
};

export default WorkTimeScreen;