import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import PointsSelect from '../../../Components/PointsSelect';
import Accordion from '../../../Components/Accordion';

// models
import {Clients, Points, Products, Promocodes} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatus, commonStatusName, deliveryTypeName, measureTypeName, paymentTypeName, promoCodeType, promoCodeTypeName, sourceTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClose from './Images/close.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';

// start
const PromocodesScreen = () => {
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root');
	const [ModalProducts, modalProductsOpen, modalProductsClose] = useModal('root');
	const [ModalClients, modalClientsOpen, modalClientsClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [points, setPoints] = useState([]);
	const [promocodes, setPromocodes] = useState(null);
	const [promocodesAll, setPromocodesAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:code, setValue:setCode, bind:bindCode} = useInput('');
	const {value:dateStart, setValue:setDateStart, bind:bindDateStart} = useInput('');
	const {value:dateEnd, setValue:setDateEnd, bind:bindDateEnd} = useInput('');
	const {value:amountMin, setValue:setAmountMin, bind:bindAmountMin} = useInput('');
	const {value:amountMax, setValue:setAmountMax, bind:bindAmountMax} = useInput('');
	const {value:counts, setValue:setCounts, bind:bindCounts} = useInput('');
	const [isFirstOrder, setIsFirstOrder] = useState(false);
	const [isOneTime, setIsOneTime] = useState(false);
	const [isStop, setIsStop] = useState(false);
	const [orderDays, setOrderDays] = useState([]);
	const [preOrderDays, setPreOrderDays] = useState([]);
	const [deliveryType, setDeliveryType] = useState([]);
	const [paymentType, setPaymentType] = useState([]);
	const [sources, setSources] = useState([]);
	const {value:value, setValue:setValue, bind:bindValue} = useInput('');
	const [promocodePoints, setPromocodePoints] = useState([]);
	const [clients, setClients] = useState([]);
	const [clientsSearch, setClientsSearch] = useState('');
	const [clientsSelected, setClientsSelected] = useState([]);
	const [products, setProducts] = useState([]);
	const [productsAll, setProductsAll] = useState([]);
	const [productsSelected, setProductsSelected] = useState([]);
	const [useds, setUseds] = useState([]);
	const [search, setSearch] = useState(null);
	const [type, setType] = useState(promoCodeType.UNKNOWN);
	const [status, setStatus] = useState(commonStatus.ACTIVE);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await pointsGet();
			await productsGet();
			await promocodesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const promocodesGet = async () => {
		const promocodes = await Promocodes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (promocodes === undefined) return;
		setPromocodes(promocodes);
		setPromocodesAll(promocodes);
	};
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const productsGet = async () => {
		const products = await Products.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		setProducts(products);
		setProductsAll(products);
	};
	const promocodeAdd = () => show();
	const promocodeEdit = (promocode) => {
		promocode = promocode || promocodes.find(f => f._id === id);
		show(promocode);
	};
	const promocodeShow = (promocode) => show(promocode);
	const show = (promocode) => {
		setId(promocode?._id||null);
		setName(promocode?.name||'');
		setDescription(promocode?.description||'');
		setCode(promocode?.code||'');
		setDateStart(Utils.dateNormalize(promocode?.properties.period, 'start'));
		setDateEnd(Utils.dateNormalize(promocode?.properties.period, 'end'));
		setAmountMin(promocode?promocode.properties.amounts.min:'');
		setAmountMax(promocode?promocode.properties.amounts.max:'');
		setIsFirstOrder(promocode?promocode.options.isFirstOrder:false);
		setIsOneTime(promocode?promocode.options.isOneTime:false);
		setIsStop(promocode?promocode.options.isStop:false);
		setValue(promocode?promocode.value:null);
		setCounts(promocode?promocode.properties.counts:'');
		setOrderDays(promocode?promocode.properties.orderDays:[false,false,false,false,false,false,false]);
		setPreOrderDays(promocode?promocode.properties.preOrderDays:[false,false,false,false,false,false,false]);
		setDeliveryType(promocode?promocode.properties.deliveryType:[]);
		setPaymentType(promocode?promocode.properties.paymentType:[]);
		setSources(promocode?promocode.properties.sources:[]);
		setUseds(promocode?promocode.useds:[]);
		setType(promocode?promocode.type:promoCodeType.UNKNOWN);
		setProductsSelected(promocode ? promocode.products : []);
		setClients(promocode?.clients||[]);
		setClientsSelected(promocode?.clients||[]);
		setPromocodePoints(promocode ? promocode.points : points.map(v => ({id:v._id,name:v.name})));
		setStatus(promocode?promocode.status:commonStatus.ACTIVE);
	};
	const cancel = () => {
		promocodeShow(null);
		modalChangeStatusHide();
		modalProductsClose();
		setProducts(productsAll);
	}
	const save = async () => {
		const data = {
			points:promocodePoints,
			name:name,
			description:description,
			code:code.toUpperCase(),
			dateStart:dateStart,
			dateEnd:dateEnd,
			amountMin:amountMin,
			amountMax:amountMax,
			counts:counts,
			type:type,
			status:status
		};
		if (isFirstOrder) data.isFirstOrder = isFirstOrder;
		if (isOneTime) data.isOneTime = isOneTime;
		if (isStop) data.isStop = isStop;
		if (clientsSelected.length > 0) data.clients = clientsSelected.map(m => m._id);
		if (orderDays.length > 0) data.orderDays = orderDays;
		if (preOrderDays.length > 0) data.preOrderDays = preOrderDays;
		if (deliveryType.length > 0) data.deliveryType = deliveryType;
		if (paymentType.length > 0) data.paymentType = paymentType;
		if (sources.length > 0) data.sources = sources;
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название промокода');
			return;
		}
		if (Utils.empty(data.code)) {
			errorShow('Необходимо заполнить код промокода');
			return;
		}
		if (data.points.length === 0) {
			errorShow('Необходимо выбрать филиалы');
			return;
		}
		switch (data.type) {
			case promoCodeType.PERCENT:
				if (Utils.empty(value)) {
					errorShow('Необходимо заполнить скидку');
					return;
				}
				data.value = value;
				break;
			case promoCodeType.DISCOUNT:
				if (Utils.empty(value)) {
					errorShow('Необходимо заполнить сумму скидки');
					return;
				}
				data.value = value;
				break;
			case promoCodeType.GIFT:
			case promoCodeType.FREE:
				if (productsSelected.length === 0) {
					errorShow('Необходимо выбрать одну или несколько позиций');
					return;
				}
				data.products = productsSelected.map(v => ({id:v.id,name:v.name}));
				break;
			case promoCodeType.BONUSES:
				if (Utils.empty(value)) {
					errorShow('Необходимо заполнить количество бонусов');
					return;
				}
				data.value = value;
				break;
		}
		const res = id ?
				await Promocodes.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Promocodes.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			promocodesGet();
			cancel();
			return true;
		}
	}
	const searchPromocode = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setPromocodes(promocodesAll);
		else setPromocodes(promocodesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase()) || f.code.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const handleType = (e) => setType(parseInt(e.target.value));
	const promocodeChangeStatusOn = (id) => promocodeChangeStatus(id);
	const promocodeChangeStatusModal = () => promocodeChangeStatus(id);
	const promocodeChangeStatus = (id) => {
		const promocode = promocodes.find(f => f._id === id);
		const status = promocode.status === commonStatus.ACTIVE ? commonStatus.IN_ACTIVE : commonStatus.ACTIVE;
		Promocodes.status(id, {status}).then((res) => {
			successShow(res.message);
			promocodesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (id) => {
		setId(id);
		setName(promocodes.find(f => f._id === id).name);
		modalChangeStatusOpen();
	}
	const promocodeDelete = (id) => {
		Promocodes.remove(id).then((res) => {
			successShow(res.message);
			promocodesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const codeChange = (e) => {
		const val = e.target.value;
		setCode(/^[A-Za-zА-Яа-я0-9_-]{1,20}$/.test(val) ? val.toUpperCase() : val.slice(0, -1));
	}
	const productsShow = () => modalProductsOpen();
	const productSelect = (product) => {
		console.log(product, productsSelected);
		const item = productsSelected.find(f => f.id === product._id);
		if (item) setProductsSelected(productsSelected.filter(f => f.id !== product._id));
		else setProductsSelected([...productsSelected, {id:product._id,name:product.name}]);
	};
	const searchProduct = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setProducts(productsAll);
		else setProducts(productsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	};
	const productAdd = () => {
		modalProductsClose();
		setProducts(productsAll);
	};
	const clientsShow = () => modalClientsOpen();
	const clientSelect = (client) => {
		const i = clientsSelected.findIndex(f => f.id === client._id);
		if (i === -1) setClientsSelected([...clientsSelected, client]);
		else setClientsSelected(clientsSelected.filter(f => f.id !== client._id));
	};
	const clientSearch = async (e) => {
		const search = e.target.value;
		setSearch(search);
		if (Utils.empty(search)) setClientsSearch(null);
		else {
			const clients = await Clients.get.search(search).catch((ex) => console.log(666, ex));
			setClientsSearch(clients||[]);
		}
	};
	const clientAdd = () => {
		modalClientsClose();
		setClients(null);
	};
	const sourceSet = (item) => {
		if (sources.includes(item)) setSources(sources.filter(f => f !== item));
		else setSources([...sources, item]);
	}
	const deliveryTypeSet = (item) => {
		if (deliveryType.includes(item)) setDeliveryType(deliveryType.filter(f => f !== item));
		else setDeliveryType([...deliveryType, item]);
	}
	const paymentTypeSet = (item) => {
		if (paymentType.includes(item)) setPaymentType(paymentType.filter(f => f !== item));
		else setPaymentType([...paymentType, item]);
	}
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = promocodes.find(f => f._id === v);
			if (s) {
				const data = {
					points:s.points,
					name:`${s.name} (копия)`,
					description:s.description,
					code:`${s.code}_COPY`,
					dateStart:s.properties.period.start,
					dateEnd:s.properties.period.end,
					amountMin:s.properties.amounts.min,
					amountMax:s.properties.amounts.max,
					counts:s.properties.counts,
					type:s.type,
					status:s.status,
					isFirstOrder:s.options.isFirstOrder,
					isOneTime:s.options.isOneTime,
					isStop:s.options.isStop,
					clients:s.clients,
					orderDays:s.properties.orderDays,
					preOrderDays:s.properties.preOrderDays,
					deliveryType:s.properties.deliveryType,
					paymentType:s.properties.paymentType,
					sources:s.properties.sources,
					products:s.products,
					value:s.value,
				};
				await Promocodes.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		promocodesGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await Promocodes.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		promocodesGet();
		cancel();
		return true;
	};
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	const pointSelect = (point) => {
		const p = promocodePoints.find(f => f.id === point._id);
		if (p) setPromocodePoints(promocodePoints.filter(f => f.id !== point._id));
		else setPromocodePoints([...promocodePoints, {id:point._id,name:point.name}]);
	};
	const nameGet = (v) => <>
		{v.name}
		{v.properties.period?.start || v.properties.period?.end ?
				<span>
					{v.properties.period?.start ? `с ${moment(v.properties.period.start).format('DD.MM.YYYY')}` : null}
					{v.properties.period?.end ? (v.properties.period?.start ? ' до ' : 'до ') + moment(v.properties.period.end).format('DD.MM.YYYY'): null}
				</span>
			: null}
	</>;
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Промокоды"
							type="marketing"
							data={promocodes}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name',func:nameGet},
								{title:'Кол / Исп',field:'name',class:'weight',sort:'properties.counts',func:(v) => `${v.properties.counts} / ${v.useds.length}`},
								{title:'Статус',field:'status',class:'status status-small',func:statusGet}
							]}
							rowControlItems={[
								{title:(v) => v.status===commonStatus.ACTIVE?'Отключить':'Включить',class:(v) => v.status===commonStatus.ACTIVE?'inactive':'active',action:(v) => v.status === commonStatus.ACTIVE ? modalChangeStatusShow(v._id) : promocodeChangeStatusOn(v._id)}
							]}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первый<br/>промокод</>}
							contentShowTitle={name}
							contentShow={<>
								{description ? 
									<div className="product-view-row-simple">
										{description}
									</div> : null}
								<PointsSelect isShow={true} selected={promocodePoints} />
								<div className="product-view-row-simple">
									<code>{code}</code>
								</div>
								<h4>Результат</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Тип промокода</span>
									<div>{promoCodeTypeName[type]}</div>
								</div>
								{type === promoCodeType.PERCENT ?
									<div className="product-view-row product-view-row-oneline">
										<span>Скидка</span>
										<div>{value?`${value}%`:'–'}</div>
									</div> : null}
								{type === promoCodeType.DISCOUNT ?
									<div className="product-view-row product-view-row-oneline">
										<span>Сумма скидки</span>
										<div>{value?`${value} ₽`:'–'}</div>
									</div> : null}
								{type === promoCodeType.GIFT || type === promoCodeType.FREE ?
									<div className="product-view-row product-view-row-oneline">
										<span>Подарок</span>
										<div>{productsSelected.length ? productsSelected.map(v => v.name).join(', ') : '–'}</div>
									</div> : null}
								{type === promoCodeType.BONUSES ?
									<div className="product-view-row product-view-row-oneline">
										<span>Количество бонусов</span>
										<div>{value?`${value} ₽`:'–'}</div>
									</div> : null}
								<h4>Параметры</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Начало действия</span>
									<div>{dateStart?moment(dateStart).format('DD.MM.YYYY'):'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Окончание действия</span>
									<div>{dateEnd?moment(dateEnd).format('DD.MM.YYYY'):'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Минимальная сумма</span>
									<div>{amountMin?`${amountMin} ₽`:'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Максимальная сумма</span>
									<div>{amountMax?`${amountMax} ₽`:'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Количество кодов</span>
									<div>{counts?counts:'–'}</div>
								</div>
								{clients.length ?
									<>
										<h4>Персонализация</h4>
										<div className="product-view-row product-view-row-oneline">
											<span>Клиенты</span>
											<div>{clients.map(m => m.name).join(', ')}</div>
										</div>
									</> : null}
								<h4>Доступность</h4>
								<div className="product-view-row product-view-row-oneline">
									<label>Доступно для заказа</label>
									<div>{orderDays.map((v, i) => !v ? null : Utils.weekDayName.short[i]).filter(f => !Utils.empty(f)).join(', ')}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<label>Доступно для предзаказа</label>
									<div>{preOrderDays.map((v, i) => !v ? null : Utils.weekDayName.short[i]).filter(f => !Utils.empty(f)).join(', ')}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<label>Способ получения</label>
									<div>{deliveryType.map((v, i) => !v ? null : deliveryTypeName[v]).filter(f => !Utils.empty(f)).join(', ')}</div>
								</div>
								<div className="product-view-row">
									<label>Способ оплаты</label>
									<div>{paymentType.map((v, i) => !v ? null : paymentTypeName[v]).filter(f => !Utils.empty(f)).join(', ')}</div>
								</div>
								<div className="product-view-row">
									<label>Источники</label>
									<div>{sources.map((v, i) => i === 0 ? null : sourceTypeName[v]).filter(f => !Utils.empty(f)).join(', ')}</div>
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Первый заказ</span>
									<div>{isFirstOrder?'Да':'Нет'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Одноразовый</span>
									<div>{isOneTime?'Да':'Нет'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Не применять прочие акции и скидки</span>
									<div>{isStop?'Да':'Нет'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Активировано</span>
									<div>{useds.length}</div>
								</div>
								{useds.length ?
									<>
										<h4>Использовано</h4>
										{useds.map((v,i) => <div key={i} className="product-view-row product-view-row-oneline">
											{v.name}
											<span>{moment(v.date).format('DD.MM.YYYY')}</span>
										</div>)}
									</> : null}
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription}></textarea>
								</div>
								<div className="product-edit-row">
									<input type="text" {...bindCode} placeholder="Промокод" required pattern="^[A-Za-zА-Яа-я0-9_-]{1,20}$" maxLength={20} onChange={codeChange} />
								</div>
								<PointsSelect isEdit={true} points={points} selected={promocodePoints} onSelect={pointSelect} />
								<h4>Результат</h4>
								<div className="product-edit-row">
									<div className="select select-wide">
										<select id="status" onChange={handleType} value={type} required>
											<option value="">Выберите тип промокода</option>
											{promoCodeTypeName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
								{type === promoCodeType.PERCENT ?
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="value" className="label-middle">Скидка</label>
										<input type="text" id="value" {...bindValue} placeholder='0' maxLength={2} />
									</div> : null}
								{type === promoCodeType.DISCOUNT ?
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="value" className="label-middle">Сумма скидки</label>
										<input type="text" id="value" {...bindValue} placeholder='0' maxLength={4} />
									</div> : null}
								{type === promoCodeType.GIFT || type === promoCodeType.FREE ?
										<div className="product-edit-row">
											{productsSelected.length ?
													<div className="product-edit-components">
														{productsSelected.map((v,i) => <div key={i} className="product-edit-component">
															{v.name}
															<img src={imgClose} alt="" className="close-icon" onClick={() => setProductsSelected(productsSelected.filter(f => f.id !== v.id))} />
														</div>)}
													</div>
												: null
											}
											<div className="product-edit-btn" onClick={() => productsShow()}>Добавить товар</div>
										</div>
									: null}
								{type === promoCodeType.BONUSES ?
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="value" className="label-middle">Количество бонусов</label>
										<input type="text" id="value" {...bindValue} placeholder='0' maxLength={4} />
									</div> : null}
								<Accordion title="Параметры">
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="dateStart" className="label-middle">Начало действия</label>
										<input type="date" id="dateStart" {...bindDateStart} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="dateEnd" className="label-middle">Окончание действия</label>
										<input type="date" id="dateEnd" {...bindDateEnd} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="amountMin" className="label-middle">Минимальная сумма</label>
										<input type="text" id="amountMin" {...bindAmountMin} maxLength={4} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="amountMax" className="label-middle">Максимальная сумма</label>
										<input type="text" id="amountMax" {...bindAmountMax} maxLength={4} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="counts" className="label-middle">Количество кодов</label>
										<input type="text" id="counts" {...bindCounts} maxLength={4} />
									</div>
								</Accordion>
								<h4>Персонализация</h4>
								<div className="product-edit-row">
									<div className="product-edit-components">
										{clientsSelected.length ?
											clientsSelected.map((v,i) => <div key={i} className="product-edit-component">
												{v.name}
												<img src={imgClose} alt="" onClick={() => setClientsSelected(clientsSelected.filter(f => f._id !== v._id))} />
											</div>) : null}
									</div>
									<div className="product-edit-btn" onClick={() => clientsShow()}>Добавить клиента</div>
								</div>
								<Accordion title="Доступно для заказа" info={orderDays.length?orderDays.map((v,i) => v ? Utils.weekDayName.short[i] : null).filter(f => !!f).join(', ')||'не выбрано' : 'не выбрано'}>
									<div className="product-edit-row">
										{orderDays.map((v,i) => <div key={i} className="product-edit-row-simple product-edit-row-simple-noline product-edit-row-oneline-start">
											<img src={v ? imgCheckOn : imgCheckOff} alt="" onClick={() => setOrderDays(orderDays.map((m,j) => i === j ? !m : m))} />
											{Utils.weekDayName.full[i]}
										</div>)}
									</div>
								</Accordion>
								<Accordion title="Доступно для предзаказа" info={preOrderDays.length?preOrderDays.map((v,i) => v ? Utils.weekDayName.short[i] : null).filter(f => !!f).join(', ')||'не выбрано' : 'не выбрано'}>
									<div className="product-edit-row">
										{preOrderDays.map((v,i) => <div key={i} className="product-edit-row-simple product-edit-row-simple-noline product-edit-row-oneline-start">
											<img src={v ? imgCheckOn : imgCheckOff} alt="" onClick={() => setPreOrderDays(preOrderDays.map((m,j) => i === j ? !m : m))} />
											{Utils.weekDayName.full[i]}
										</div>)}
									</div>
								</Accordion>
								<Accordion title="Способ получения" info={deliveryType.length?deliveryType.map(v => v ? deliveryTypeName[v] : null).filter(f => !!f).join(', ')||'не выбрано' : 'не выбрано'}>
									<div className="product-edit-row">
										{deliveryTypeName.map((v,i) => i === 0 ? null : <div key={i} className="product-edit-row-simple product-edit-row-simple-noline product-edit-row-oneline-start">
											<img src={deliveryType.includes(i) ? imgCheckOn : imgCheckOff} alt="" onClick={() => deliveryTypeSet(i)} />
											{deliveryTypeName[i]}
										</div>)}
									</div>
								</Accordion>
								<Accordion title="Способ оплаты" info={paymentType.length?paymentType.map(v => v ? paymentTypeName[v] : null).filter(f => !!f).join(', ')||'не выбрано' : 'не выбрано'}>
									<div className="product-edit-row">
										{paymentTypeName.map((v,i) => i === 0 ? null : <div key={i} className="product-edit-row-simple product-edit-row-simple-noline product-edit-row-oneline-start">
											<img src={paymentType.includes(i) ? imgCheckOn : imgCheckOff} alt="" onClick={() => paymentTypeSet(i)} />
											{paymentTypeName[i]}
										</div>)}
									</div>
								</Accordion>
								<Accordion title="Источники" info={sources.length?sources.map(v => v ? sourceTypeName[v] : null).filter(f => !!f).join(', ')||'не выбрано' : 'не выбрано'}>
									<div className="product-edit-row">
										{sourceTypeName.map((v,i) => i === 0 ? null : <div key={i} className="product-edit-row-simple product-edit-row-simple-noline product-edit-row-oneline-start">
											<img src={sources.includes(i) ? imgCheckOn : imgCheckOff} alt="" onClick={() => sourceSet(i)} />
											{sourceTypeName[i]}
										</div>)}
									</div>
								</Accordion>
								<Accordion title="Дополнительные параметры">
									<div className="product-edit-row product-edit-row-oneline">
										<label className="label-middle">Первый заказ</label>
										<img src={isFirstOrder ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsFirstOrder(!isFirstOrder)} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label className="label-middle">Одноразовый</label>
										<img src={isOneTime ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsOneTime(!isOneTime)} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label className="label-middle">Не применять прочие акции и скидки</label>
										<img src={isStop ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsStop(!isStop)} />
									</div>
								</Accordion>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleStatus} value={status} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</>}
							onDelete={promocodeDelete}
							onEdit={promocodeEdit}
							onAdd={promocodeAdd}
							onSave={save}
							onSearch={searchPromocode}
							onShow={promocodeShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Отключить промокод</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите отключить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={promocodeChangeStatusModal} className="btn-accept btn-delete">Отключить</button>
					</div>
				</div>
			</ModalChangeStatus>
			<ModalProducts>
				<div className="modal modal-products">
					<div className="header">
						<h4>Добавить позицию</h4>
						<img src={imgClose} alt="" onClick={modalProductsClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchProduct} autoFocus={true} />
					<div className="products">
						<div className="list">
							{products.length ?
									products.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											<span>{v.prices.priceFull} ₽ • {v.properties.weight} {measureTypeName[v.properties.measureType]}</span>
										</div>
										<img src={productsSelected.find(f => f.id === v._id) ? imgCheckOn : imgCheckOff} alt="" onClick={() => productSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
						<div className="buttons">
							<button type="button" onClick={productAdd} className="btn-accept">Добавить</button>
						</div>
					</div>
				</div>
			</ModalProducts>
			<ModalClients>
				<div className="modal modal-products">
					<div className="header">
						<h4>Добавить клиента</h4>
						<img src={imgClose} alt="" onClick={modalClientsClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={clientSearch} autoFocus={true} />
					<div className="products">
						<div className="list">
							{clientsSearch.length ?
									clientsSearch.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											{v.phone ? <span>{Utils.phoneFormatter(v.phone)}</span> : null}
										</div>
										<img src={clientsSelected.find(f => f._id === v._id) ? imgCheckOn : imgCheckOff} alt="" onClick={() => clientSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Никого не найдено
										</div>
									</div>
							}
						</div>
						<div className="buttons">
							<button type="button" onClick={clientAdd} className="btn-accept">Добавить</button>
						</div>
					</div>
				</div>
			</ModalClients>
		</>
	);
};

export default PromocodesScreen;