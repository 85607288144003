import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import InputMask from 'react-input-mask';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import ComponentInline from '../../../Components/ComponentInline';
import ComponentPositionList from '../../../Components/ComponentPositionList';
import ComponentPopupStorage from '../../../Components/ComponentPopupStorage';
import Accordion from '../../../Components/Accordion';

// models
import {Components, Invoices as Inv, Products, Storages, Suppliers} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { cashboxPaymentType, cashboxPaymentTypeName, commonStatus } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClose from './Images/close.svg';
import imgPlusButton from './Images/plus-btn.svg';


// start
const InvoicesScreen = () => {
	const [ModalSupplier, modalSupplierOpen, modalSupplierClose] = useModal('root');
	const [ModalPay, modalPayOpen, modalPayClose] = useModal('root');
	const [ModalUnpay, modalUnpayOpen, modalUnpayClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [invoicesAll, setInvoicesAll] = useState([]);
	const [invoices, setInvoices] = useState(null);
	const [id, setId] = useState(null);
	const [name, setName] = useState(null);
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:number, setValue:setNumber, bind:bindNumber} = useInput('');
	const {value:date, setValue:setDate, bind:bindDate} = useInput('');
	const [amount, setAmount] = useState('');
	const [items, setItems] = useState([]);
	const [total, setTotal] = useState(0);
	const [components, setComponents] = useState([]);
	const [products, setProducts] = useState([]);
	const [inventory, setInventory] = useState([]);
	const [supplierId, setSupplierId] = useState(null);
	const [suppliers, setSuppliers] = useState([]);
	const [storageId, setStorageId] = useState(null);
	const [storages, setStorages] = useState([]);
	const [storagesAll, setStoragesAll] = useState([]);
	const [paymentType, setPaymentType] = useState(cashboxPaymentType.UNKNOWN);
	const [status, setStatus] = useState('');
	const [search, setSearch] = useState(null);
	const {value:supplierName, setValue:setSupplierName, bind:bindSupplierName} = useInput('');
	const {value:supplierDescription, setValue:setSupplierDescription, bind:bindSupplierDescription} = useInput('');
	const {value:supplierPhone, setValue:setSupplierPhone, bind:bindSupplierPhone} = useInput('');
	const {value:supplierEmail, setValue:setSupplierEmail, bind:bindSupplierEmail} = useInput('');
	const {value:supplierAddress, setValue:setSupplierAddress, bind:bindSupplierAddress} = useInput('');
	const {value:supplierContact, setValue:setSupplierContact, bind:bindSupplierContact} = useInput('');
	const {value:supplierInn, setValue:setSupplierInn, bind:bindSupplierInn} = useInput('');
	const {value:supplierKpp, setValue:setSupplierKpp, bind:bindSupplierKpp} = useInput('');
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const point = ls('point');
			await storagesGet(point.id);
			await suppliersGet();
			await componentsGet();
			await productsGet();
			await invoicesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const invoicesGet = async () => {
		const invoices = await Inv.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (invoices === undefined || invoices.length === 0) {
			setInvoices(null);
			return;
		}
		setInvoices(invoices);
		setInvoicesAll(invoices);
	};
	const componentsGet = async () => {
		const components = await Components.get.get().catch((ex) => console.log(666, ex));
		if (components === undefined) return;
		setComponents(components);
	};
	const productsGet = async () => {
		const products = await Products.get.store().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		setProducts(products);
	};
	const storagesGet = async (pointId) => {
		const storages = await Storages.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (storages === undefined) return;
		const s = storages.filter(f => f.points.filter(f => f.id === pointId).length !== 0);
		setStorages(s);
		setStoragesAll(storages);
	};
	const suppliersGet = async () => {
		const suppliers = await Suppliers.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (suppliers === undefined) return;
		setSuppliers(suppliers);
	};
	const invoiceAdd = () => show();
	const invoiceEdit = (invoice) => {
		invoice = invoice || invoices.find(f => f._id === id);
		show(invoice);
	};
	const invoiceShow = (invoice) => show(invoice);
	const show = (invoice) => {
		setId(invoice?._id||null);
		setName(invoice ? `№${invoice.number} от ${moment(invoice.date).format('DD.MM.YYYY HH:mm')}` : '');
		setNumber(invoice?.number||(invoices?invoices.length+1:1));
		setDate(Utils.dateTimeNormalize(invoice, 'date'));
		setDescription(invoice?.description||'');
		setSupplierId(invoice?.supplier?.id||null);
		setStorageId(invoice?.storage?.id||null);
		setItems(invoice?.items||[]);
		setTotal(invoice?.total||0);
		setInventory(invoice?.inventory||null);
		setStatus(invoice?.status||'');
	};
	const cancel = () => {
		invoiceShow(null);
		modalPayClose();
		modalUnpayClose();
		modalSupplierClose();
	}
	const save = async () => {
		const data = {
			number:number,
			date:date,
			supplierId:supplierId,
			storageId:storageId,
			description:description,
			items:items,
			total:calcTotalGet()
		};
		if (Utils.empty(data.date)) {
			errorShow('Необходимо заполнить дату');
			return;
		}
		if (Utils.empty(data.supplierId)) {
			errorShow('Необходимо выбрать поставщика');
			return;
		}
		if (Utils.empty(data.storageId)) {
			errorShow('Необходимо выбрать склад');
			return;
		}
		if (items.length === 0) {
			errorShow('Необходимо добавить компоненты');
			return;
		}
		const res = id ?
				await Inv.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Inv.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			if (paymentType !== cashboxPaymentType.UNKNOWN) invoicePay('pay', paymentType, res.id);
			successShow(res.message);
			invoicesGet();
			cancel();
			return true;
		}
	}
	const searchInvoice = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setInvoices(invoicesAll);
		else setInvoices(invoicesAll.filter(f => f.date.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const invoiceDelete = (id) => {
		Inv.remove(id).then((res) => {
			successShow(res.message);
			invoicesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const handleSupplier = (e) => setSupplierId(e.target.value);
	const handleStorage = (e) => setStorageId(e.target.value);
	const calcTotalGet = () => items.reduce((acc, v) => acc += parseFloat(v.total), 0).toFixed(2);
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	const supplierAddShow = () => {
		setSupplierName('');
		setSupplierDescription('');
		setSupplierPhone('');
		setSupplierEmail('');
		setSupplierContact('');
		setSupplierAddress('');
		setSupplierInn('');
		setSupplierKpp('');
		modalSupplierOpen();
	};
	const supplierSave = async (e) => {
		e.preventDefault();
		const data = {
			name:supplierName,
			description:supplierDescription,
			phone:supplierPhone,
			email:supplierEmail,
			contact:supplierContact,
			address:supplierAddress,
			inn:supplierInn,
			kpp:supplierKpp
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить наименование поставщика');
			return;
		}
		const res = await Suppliers.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			suppliersGet();
			cancel();
			return true;
		}
	}
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = invoices.find(f => f._id === v);
			if (s) {
				const data = {
					number:`${s.number} (копия)`,
					date:Utils.dateTimeNormalize(null, 'date'),
					supplierId:s.supplier.id,
					storageId:s.storage.id,
					description:s.description,
					items:s.items,
					total:s.total,
					status:commonStatus.ACTIVE
				};
				await Inv.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		invoicesGet();
		cancel();
		return true;
	};
	const debtGet = (v) => v.status === commonStatus.ACTIVE ? v.total : 0;
	const totalSumGet = () => invoices ? invoices.reduce((acc, v) => acc + (v.status === commonStatus.IN_ACTIVE ? v.total : 0), 0).toFixed(2) : 0;
	const totalDebtGet = () => invoices ? invoices.reduce((acc, v) => acc + (v.status === commonStatus.ACTIVE ? v.total : 0), 0) : 0;
	const componentChange = (items) => setItems([...items]);
	const payShow = (item) => invoicePayShow(item, 'pay');
	const unpayShow = (item) => invoicePayShow(item, 'unpay');
	const invoicePayShow = (item, type) => {
		setId(item._id);
		setAmount(invoices.find(f => f._id === item._id).items.reduce((acc, v) => acc + v.total, 0));
		if (type === 'pay') modalPayOpen();
		if (type === 'unpay') modalUnpayOpen();
	};
	const invoicePay = (type, paymentType, invoiceId) => {
		Inv.pay[type]((invoiceId||id), paymentType).then((res) => {
			successShow(res.message);
			invoicesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	};
	const paymentHandle = (e) => setPaymentType(e.target.value);
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Накладные"
							type="warehouse"
							data={invoices}
							id={id}
							search={search}
							rows={[
								{title:'Дата',field:'date',func:(v) => <>
									№{v.number} от {moment(v.date).format('DD.MM.YYYY HH:mm')}
									{v.description ? <span>{v.description}</span> : null}
								</>},
								{title:'Склад',field:'storage',class:'area',sort:'storage.name',func:(v) => v.storage ? v.storage.name : '–'},
								{title:'Поставщик',field:'supplier',sort:'supplier.name',class:'area',func:(v) => v.supplier ? v.supplier.name : '–'},
								{title:'Сумма',field:'total',class:'prices'},
								{title:'Долг',field:'debt',class:'prices',func:debtGet},
								{title:'Статус',field:'status',class:'status status-small',func:statusGet}
							]}
							rowsShort={['date','total','status']}
							rowsFooter={<>
								<tr className="info-row">
									<td colSpan="3">Итого</td>
									<td className="prices">{totalSumGet()}</td>
									<td className="prices">{totalDebtGet()}</td>
									<td colSpan="2"></td>
								</tr>
							</>}
							rowControlItems={(v) => (v.inventory && v.status === commonStatus.ACTIVE) || !v.inventory ? (v.status === commonStatus.ACTIVE ? [{title:'Оплатить',class:'check',action:payShow}] : [{title:'Отозвать оплату',class:'uncheck',action:unpayShow}]) : []}
							footerItems={[
								{title:'Сделать копию',action:massCopy}
							]}
							empty={<>Добавьте первую<br/>накладную</>}
							contentShowTitle={name}
							contentShow={<>
								{inventory ?
									<div className="product-view-row product-view-row-alert">
										Эту накладную нельзя изменить или удалить! Она используется в инвентаризации от {moment(inventory.date).format('DD.MM.YYYY HH:mm')}
									</div>
								: null}
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<div className="product-view-row product-view-row-oneline">
									<span>Номер накладной</span>
									<div>{number}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Дата</span>
									<div>{moment(date).format('DD.MM.YYYY HH:mm')}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Склад</span>
									<div>{storagesAll.find(f => f._id === storageId)?.name||'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Поставщик</span>
									<div>{suppliers.find(f => f._id === supplierId)?.name||'–'}</div>
								</div>
								{status !== commonStatus.ACTIVE ?
									<div className="product-view-row product-view-row-oneline">
										<span>Статус</span>
										<div>Проведено списание</div>
									</div> : null}
								{inventory ?
									<div className="product-view-row product-view-row-oneline">
										<span>Инвентаризация от</span>
										<div>{moment(inventory.date).format('DD.MM.YYYY HH:mm')}</div>
									</div> : null}
								<ComponentPositionList items={items} />
								{(inventory && status === commonStatus.ACTIVE) || !inventory ?
									<>
										<h4>Оплата накладной</h4>
										<div className="product-edit-row">
											{status === commonStatus.ACTIVE ?
													<div className="product-edit-btn" onClick={() => invoicePayShow(invoices.find(f => f._id === id), 'pay')}>Оплатить из кассы</div>
												:
													<div className="product-edit-btn" onClick={() => invoicePayShow(invoices.find(f => f._id === id), 'unpay')}>Отозвать оплату</div>
											}
										</div>
									</> : null}
							</>}
							contentEdit={<>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-first">
									<label htmlFor="number" className="label-middle">Номер накладной</label>
									<input id="number" {...bindNumber} type="text" placeholder="0" required />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="date" className="label-middle">Дата</label>
									<input id="date" {...bindDate} type="datetime-local" required />
								</div>
								<div className="product-edit-row">
									<div className="select select-wide">
										<select onChange={handleStorage} value={storageId} required>
											<option value="">Выберите склад</option>
											{storages.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
								</div>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
									<div className="select select-wide">
										<select onChange={handleSupplier} value={supplierId} required>
											<option value="">Выберите поставщика</option>
											{suppliers.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
									<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => supplierAddShow()} />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Примечание" {...bindDescription} className="lite"></textarea>
								</div>
								<ComponentInline
									title="Позиции"
									data={{components,products}}
									selected={items}
									allowAddComponent={true}
									isShow={items.length===0}
									onUpdate={componentChange}
									info={<>
										{items.length ?
											items.map((v,i) => (v.name)).join(', ') : null}
									</>}
									notice={<>
										<p className="row-notice">
											Добавить можно только товары, имеющие складской остаток. 
											Сейчас таких товаров {products.length||'нет'} шт.
										</p>
									</>}
									additionButtonComponent={<>
										<ComponentPopupStorage title={'Найти позиции'} data={{components,products}} selected={items} allow={['component','product']} onUpdate={componentChange} />
									</>} />
								<Accordion title="Добавить платеж">
									<p className="row-notice">
										Оплата накладной производится из кассы наличными или безналичными средствами.
									</p>
									<div className="product-edit-row product-edit-row-oneline">
										<label className="label-middle">Выбрать источник оплаты</label>
										<div className="select select-wide">
											<select onChange={paymentHandle}>
												{cashboxPaymentTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
											</select>
										</div>
									</div>
								</Accordion>
							</>}
							deleteExpression={(v) => v.status === commonStatus.ACTIVE && !v.inventory}
							editExpression={(v) => v.status === commonStatus.ACTIVE && !v.inventory}
							hideEditExpression={() => status === '' || status === commonStatus.IN_ACTIVE || inventory}
							onDelete={invoiceDelete}
							onEdit={invoiceEdit}
							onAdd={invoiceAdd}
							onSave={save}
							onSearch={searchInvoice}
							onShow={invoiceShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalPay>
				<div className="modal modal-invoice">
					<div className="header">
						<h4>Оплата накладной</h4>
						<img src={imgClose} alt="" onClick={modalPayClose} className="btn-close" />
					</div>
					<p>Будет произведена операция списания из кассы на сумму <b>{amount}₽</b></p>
					<div className="buttons">
						<button type="button" onClick={modalPayClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={() => invoicePay('pay', cashboxPaymentType.CASH)} className="btn-accept">Наличные</button>
						<button type="button" onClick={() => invoicePay('pay', cashboxPaymentType.CARD)} className="btn-accept">Безналичные</button>
					</div>
				</div>
			</ModalPay>
			<ModalUnpay>
				<div className="modal">
					<div className="header">
						<h4>Отзыв расходной операции</h4>
						<img src={imgClose} alt="" onClick={modalUnpayClose} className="btn-close" />
					</div>
					<p>
						Если смена открыта, изъятие из кассы на сумму оплаты <b>{amount}₽</b> будет удалено.<br/>
						Если смена закрыта, то будет произведен возврат средств (внесение) за оплату в кассу.
					</p>
					<div className="buttons">
						<button type="button" onClick={modalUnpayClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={() => invoicePay('unpay')} className="btn-accept btn-delete">Отозвать</button>
					</div>
				</div>
			</ModalUnpay>
			<ModalSupplier>
				<div className="modal modal-supplier">
					<div className="header">
						<h4>Добавить поставщика</h4>
						<img src={imgClose} alt="" onClick={modalSupplierClose} className="btn-close" />
					</div>
					<form className="product-edit" onSubmit={supplierSave}>
						<div className="container-inner">
							<div className="list">
								<div className="product-edit-row">
									<input type="text" {...bindSupplierName} placeholder="Наименование поставщика" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindSupplierDescription}></textarea>
								</div>
								<Accordion title="Контакты">
									<div className="product-edit-row">
										<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindSupplierPhone} />
									</div>
									<div className="product-edit-row">
										<input type="email" placeholder="E-mail" {...bindSupplierEmail} />
									</div>
									<div className="product-edit-row">
										<input type="text" placeholder="Контактное лицо" {...bindSupplierContact} />
									</div>
								</Accordion>
								<Accordion title="Реквизиты">
									<div className="product-edit-row">
										<input type="text" placeholder="Адрес" {...bindSupplierAddress} />
									</div>
									<div className="product-edit-row">
										<input type="text" placeholder="ИНН" {...bindSupplierInn} maxLength={12} />
									</div>
									<div className="product-edit-row">
										<input type="text" placeholder="КПП" {...bindSupplierKpp} maxLength={9} />
									</div>
								</Accordion>
							</div>
						</div>
						<div className="product-edit-footer">
							<button type="button" onClick={modalSupplierClose} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-save btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalSupplier>
		</>
	);
};

export default InvoicesScreen;