import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {Workshops} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';


// start
const WorkshopsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [workshopsAll, setWorkshopsAll] = useState([]);
	const [workshops, setWorkshops] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await workshopsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const workshopsGet = async () => {
		const workshops = await Workshops.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (workshops === undefined || workshops.length === 0) {
			setWorkshops(null);
			return;
		}
		setWorkshops(workshops);
		setWorkshopsAll(workshops);
	};
	const workshopAdd = () => show();
	const workshopEdit = (workshop) => {
		workshop = workshop || workshops.find(f => f._id === id);
		show(workshop);
	};
	const workshopShow = (workshop) => show(workshop);
	const show = (workshop) => {
		setId(workshop?._id||null);
		setName(workshop?.name||'');
		setDescription(workshop?.description||'');
	};
	const cancel = () => workshopShow(null);
	const save = async () => {
		const data = {
			name:name,
			description:description
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название склада');
			return;
		}
		const res = id ?
				await Workshops.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Workshops.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			workshopsGet();
			cancel();
			return true;
		}
	};
	const searchWorkshops = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setWorkshops(workshopsAll);
		else setWorkshops(workshopsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	};
	const workshopDelete = (id) => {
		if (workshopsAll.length === 1) {
			errorShow('Нельзя удалить последний цех');
			return;
		}
		Workshops.remove(id).then((res) => {
			successShow(res.message);
			workshopsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Цехи"
							type="books"
							data={workshops}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'}
							]}
							rowsShort={['name']}
							empty={<>Добавьте первый<br/>цех</>}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название цеха" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription}></textarea>
								</div>
							</>}
							onDelete={workshopDelete}
							onEdit={workshopEdit}
							onAdd={workshopAdd}
							onSave={save}
							onSearch={searchWorkshops}
							onShow={workshopShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default WorkshopsScreen;