import React, {useEffect,useState} from 'react';

// constants
import { measureTypeName } from '../../Globals/Constants';


const ComponentPositionList = (props) => {
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		setLoading(false);
	}, []);
	const totalGet = () => {
		const total = props.items.reduce((acc, v) => acc + (v.issemifinish ? parseFloat(v.total) : (v.price * v.quantity)), 0);
		return isNaN(total) ? 0 : total.toFixed(2);
	};
	return loading ? null :
		props.items.length ?
			<>
				<h4>{'Позиции'}</h4>
				<div className="product-edit-components-block">
					{props.items.map((v,i) => <div key={i} className="product-edit-component-item">
						<div className="product-edit-component-item-name">
							<div>
								<b>{v.name} • <span>{v.total}₽</span></b>
								{v.issemifinish ? <span className="type">п/ф</span> : null}
								{v.isproduct ? <span className="type">товар</span> : null}
							</div>
						</div>
						<div className="info">
							<div>кол-во: {v.quantity} {measureTypeName[v.measure]} • цена: {v.price}₽</div>
						</div>
					</div>)}
					<div className="product-edit-row">
						<div><b>Итого: {totalGet()} ₽</b></div>
					</div>
				</div>
			</> : null;
};

export default ComponentPositionList;