import React, {useEffect,useState} from 'react';

// helpers
import Utils from '../../Globals/Utils';

// constants
import { measureTypeSmall, measureTypeSmallName } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import imgDelete from './Images/delete-grey.svg';


const ComponentSemifinishInline = (props) => {
	const item = {name:'',price:'',brutto:'',netto:'',measure:''};
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [dataAll, setDataAll] = useState([]);
	const [items, setItems] = useState([]);
	const [index, setIndex] = useState(null);
	const [search, setSearch] = useState(null);
	const [isNameFocus, setIsNameFocus] = useState(false);
	useEffect(() => {
		const data = props.data.components ? Utils.deepCopy(props.data.components) : []	;
		data.forEach((v) => {
			const price = v.prices.manual||v.prices.invoice;
			v.id = v._id;
			v.price = price;
			v.pieces = 0;
			v.issemifinish = false;
		});
		if (props.data.semifinishes) {
			const p = [];
			props.data.semifinishes.forEach((v) => {
				p.push({
					id:v._id,
					name:v.name,
					price:v.total,
					brutto:v.properties.brutto,
					netto:v.properties.netto,
					loss:v.properties.loss,
					measure:v.properties.measure,
					pieces:0,
					isproduct:false,
					issemifinish:true
				});
			});
			data.push(...p);
		}
		if (props.data.products) {
			const p = [];
			props.data.products.filter(f => f.options.isStore).forEach((v) => {
				p.push({
					id:v._id,
					name:v.name,
					price:v.prices.price,
					quantity:1,
					measure:v.properties.measureType,
					total:v.prices.price,
					weight:v.properties.weight,
					isproduct:true,
					issemifinish:false
				});
			});
			data.push(...p);
		}
		setData(data);
		setDataAll(data);
		setItems([]);
		setLoading(false);
	}, []);
	useEffect(() => {
		const items = props.selected;
		if (items.length === 0) setItems([item]);
		else setItems([...props.selected]);
	}, [props.selected]);
	const bruttoHandle = (e, i, v) => fieldUpdate(i, e, v, 'brutto');
	const nettoHandle = (e, i, v) => fieldUpdate(i, e, v, 'netto');
	const fieldUpdate = (i, e, v, field) => {
		const value = e.target.value;
		if (!/^[0-9]*$/.test(value)) return;
		items[i][field] = parseInt(value);
		if (Utils.empty(value)) items[i][field] = '';
		else items[i][field] = value;
		if (field === 'brutto') {
			const t = items[i].price * items[i].brutto;
			if (isNaN(t)) items[i].total = '';
			else {
				if (v.issemifinish) {
					const s = props.data.semifinishes.find(f => f._id === v.id);
					items[i].total = ((t * 1000) / s.properties.brutto).toFixed(2);
				}
				else items[i].total = t.toFixed(2);
			}
			if (v.isproduct)  {
				items[i].pieces = value;
				items[i].brutto = items[i].weight * value;
				items[i].netto = items[i].brutto;
				items[i].total = items[i].price * value;
			} else {
				if (v.measure !== measureTypeSmall.PIECES) items[i].netto = value;
				else {
					items[i].pieces = value;
					items[i].brutto = items[i].weight * value;
					items[i].netto = items[i].brutto;
				}
			}
		}
		items[i].loss = items[i].brutto - items[i].netto;
		update(items);
	};
	const nameHandle = (e, i) => {
		if (i !== index) return;
		const search = e.target.value;
		if (Utils.empty(search)) setData(dataAll);
		else {
			let res = dataAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase()));
			if (res.length === 0) {
				const s = Utils.keyboardLayout(search);
				res = dataAll.filter(f => f.name.toLowerCase().includes(s.toLowerCase()));
			}
			setData(res);
		}
		setSearch(search);
	};
	const countFocusHandle = (e) => e.target.select();
	const nameFocusHandle = (e, i) => {
		setIndex(i);
		setSearch(e.target.value);
		setIsNameFocus(true);
	};
	const nameBlurHandle = () => clear();
	const add = () => update([...items,item]);
	const remove = (idx) => update(items.filter((v,i) => i !== idx));
	const update = (items) => {
		setItems(items);
		props.onUpdate(items);
		clear();
	};
	const select = (v) => {
		const item = {
			id:v.id,
			name:v.name,
			brutto:0,
			netto:0,
			loss:0,
			price:v.price/(v.isproduct?1:1000),
			measure:v.isproduct?measureTypeSmall.PIECES:v.measure,
			weight:v.weight,
			total:0,
			issemifinish:v.issemifinish,
			isproduct:v.isproduct
		};
		if (v.measure === measureTypeSmall.PIECES) item.price = v.price;
		items[index] = item;
		update(items);
	}
	const clear = () => {
		setIsNameFocus(false);
		setIndex(null);
		setSearch(null);
		setData(dataAll);
	};
	const totalCalc = () => {
		const total = items.reduce((acc, v) => acc + (v.total ? parseFloat(v.total) : 0), 0);
		return isNaN(total) ? 0 : total.toFixed(2);
	};
	const countCalc = () => {
		const weight = items.reduce((acc, v) => acc + (v.netto ? parseInt(v.netto) : 0), 0);
		return isNaN(weight) ? 0 : weight;
	};
	return loading ? null :
		<div className="component-semifinish-inline">
			<div className="component-semifinish-inline-title">
				<div className="component-semifinish-inline-title-title">
					<h4>{props.title}</h4>
				</div>
			</div>
			<table className="components">
				<thead>
					<tr>
						<th className="name">Наименование</th>
						<th className="count">Брутто</th>
						<th className="count">Нетто</th>
						<th className="sum">Сумма</th>
						<th className="delete"></th>
					</tr>
				</thead>
				<tbody>
					{items.map((v,i) => <tr key={i}>
							<td className="name">
								<input type="search" value={isNameFocus&&i===index?search:v.name} onChange={(e) => nameHandle(e, i)} onFocus={(e) => nameFocusHandle(e, i)} onBlur={nameBlurHandle} />
								{v && v.name ? <span className="measure">{v.issemifinish?'г':(v.isproduct?'шт':measureTypeSmallName[v.measure])}</span> : null}
								{isNameFocus && i === index ?
									<div className="components-list">
										{data.length ?
												data.map((v,i) => <div key={i} className="components-list-item" onMouseDown={() => select(v)}>{v.name} ({v.issemifinish?'г':measureTypeSmallName[v.measure]}){v.issemifinish?<span>п/ф</span>:(v.isproduct?<span>т</span>:null)}</div>)
											:
												<div className="components-list-empty">Не найдено</div>
										}
									</div> : null}
							</td>
							<td className="count">
								<input type="text" value={v.measure===measureTypeSmall.PIECES||v.isproduct?v.pieces:v.brutto} placeholder="0" onChange={(e) => bruttoHandle(e, i, v)} maxLength={v.measure===measureTypeSmall.PIECES?2:4} onFocus={countFocusHandle} />
								<span>{v.isproduct?'шт':measureTypeSmallName[v.measure]||'–'}</span>
							</td>
							<td className="count">
								<input type="text" value={v.netto} placeholder="0" onChange={(e) => nettoHandle(e, i, v)} maxLength={4} onFocus={countFocusHandle} />
								<span>г</span>
							</td>
							<td className="sum"><div className="input">{v.total||0}</div></td>
							<td className="delete"><img src={imgDelete} alt="" onClick={() => remove(i)} /></td>
						</tr>)}
					{items.length ?
							<tr className="total">
								<td colSpan="2"><b>Итого</b></td>
								<td className="sum"><b>{countCalc()}</b></td>
								<td className="sum"><b>{totalCalc()}</b></td>
								<td></td>
							</tr>
						: null}
				</tbody>
			</table>
			<div className="buttons">
				<button type="button" onClick={add}>＋ Добавить еще</button>
				{props.additionButtonComponent}
			</div>
		</div>;
};

export default ComponentSemifinishInline;