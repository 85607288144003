import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import {useInput} from '../Hooks/Input';
import Alert,{errorShow,successShow} from '../Alert';
import Accordion from '../Accordion';

// models
import {ComponentCategories, Components} from '../../Models';

// helpers
import Utils from '../../Globals/Utils';

// constants
import { measureType, measureTypeName } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgClose from './Images/close.svg';

// methods
let componentAddShow = null;


const ComponentAdd = (props) => {
	const [ModalComponent, modalComponentOpen, modalComponentClose] = useModal('root');
	const [ModalComponentCategory, modalComponentCategoryOpen, modalComponentCategoryClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const {value:componentCategoryName, setValue:setComponentCategoryName, bind:bindComponentCategoryName} = useInput('');
	const [components, setComponents] = useState([]);
	const [componentCategoryId, setComponentCategoryId] = useState(null);
	const [componentCategories, setComponentCategories] = useState([]);
	const {value:componentName, setValue:setComponentName, bind:bindComponentName} = useInput('');
	const {value:componentDescription, setValue:setComponentDescription, bind:bindComponentDescription} = useInput('');
	const {value:componentWeight, setValue:setComponentWeight, bind:bindComponentWeight} = useInput('');
	const {value:componentMeasureType, setValue:setComponentMeasureType, bind:bindComponentMeasureType} = useInput('');
	const {value:componentPrice, setValue:setComponentPrice, bind:bindComponentPrice} = useInput('');
	const {value:componentInvoicePrice, setValue:setComponentInvoicePrice, bind:bindComponentInvoicePrice} = useInput('');
	const {value:componentMinimum, setValue:setComponentMinimum, bind:bindComponentMinimum} = useInput('');
	const {value:componentRequestCount, setValue:setComponentRequestCount, bind:bindComponentRequestCount} = useInput('');
	const [propsData, setPropsData] = useState(null);
	useEffect(async () => {
		await componentCategoriesGet();
		await componentsGet();
		componentAddShow = (name, data) => {
			setComponentName(name);
			setPropsData(data);
			componentShow();
		}
		setLoading(false);
	}, []);
	const componentsGet = async () => {
		const components = await Components.get.get().catch((ex) => console.log(666, ex));
		if (components === undefined) return;
		setComponents(components);
	};
	const componentShow = () => {
		setComponentCategoryId('');
		setComponentCategoryName('');
		setComponentDescription('');
		setComponentWeight('');
		setComponentMeasureType('');
		setComponentPrice('');
		setComponentInvoicePrice('');
		setComponentMinimum('');
		setComponentRequestCount('');
		modalComponentOpen();
	};
	const componentCategoriesGet = async () => {
		const categories = await ComponentCategories.get().catch((ex) => console.log(666, ex));
		if (categories === undefined) return;
		setComponentCategories(categories);
	};
	const componentCategoryShow = () => {
		setComponentCategoryName('');
		modalComponentCategoryOpen();
	};
	const componentCategorySave = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (Utils.empty(componentCategoryName)) return;
		const data = {name:componentCategoryName};
		const res = await ComponentCategories.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			componentCategoriesGet();
			modalComponentCategoryClose();
		}
	};
	const handleComponentCategory = (e) => setComponentCategoryId(e.target.value);
	const handleComponentMeasureType = (e) => setComponentMeasureType(parseInt(e.target.value));
	const componentSave = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		const data = {
			categoryId:componentCategoryId,
			name:componentName,
			description:componentDescription,
			price:componentPrice,
			requestCount:componentRequestCount,
			minimum:componentMinimum,
			weight:componentWeight,
			measure:componentMeasureType
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название компонента');
			return;
		}
		if (data.measure === measureType.PIECES && Utils.empty(data.weight)) {
			errorShow('Необходимо заполнить вес компонента');
			return;
		}
		if (data.measure === null) {
			errorShow('Необходимо заполнить единицу измерения компонента');
			return;
		}
		const res = await Components.add(data).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		if (res) {
			successShow(res.message);
			componentsGet();
			modalComponentClose();
			if (props.callback) props.callback(res.data, propsData);
		}
	};
	return loading ? null :
		<>
			<Alert />
			<ModalComponent>
				<div className="modal modal-popup-component-add">
					<div className="header">
						<h4>Добавить компонент</h4>
						<img src={imgClose} alt="" onClick={modalComponentClose} className="btn-close" />
					</div>
					<form className="product-edit" onSubmit={componentSave}>
						<div className="container-inner">
							<div className="list">
								<div className="product-edit-row">
									<input type="text" {...bindComponentName} placeholder="Название компонента" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindComponentDescription} className="lite"></textarea>
								</div>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
									<div className="select select-wide">
										<select onChange={handleComponentCategory} value={componentCategoryId} required>
											<option value="">Выберите категорию</option>
											{componentCategories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
									<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => componentCategoryShow()} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="measure">Единица измерения</label>
									<div className="select">
										<select onChange={handleComponentMeasureType} value={componentMeasureType} required>
											{measureTypeName.map((v,i) => <option key={i} value={i===0?'':i}>{v}</option>)}
										</select>
									</div>
								</div>
								{componentMeasureType === measureType.PIECES ?
										<div className="product-edit-row product-edit-row-oneline">
											<label htmlFor="weight" className="label-middle">Вес компонента в граммах</label>
											<input id="weight" {...bindComponentWeight} type="text" placeholder="0" maxLength={4} required />
										</div>
									: null}
								<Accordion title="Цена">
									<div className="product-edit-row product-edit-row-oneline">
										<label className="label-middle">Цена из последней накладной</label>
										{componentInvoicePrice?`${componentInvoicePrice} ₽`:'–'}
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="price" className="label-middle">Цена (коррекция), ₽</label>
										<input id="price" {...bindComponentPrice} type="text" placeholder="0" maxLength={6} />
									</div>
								</Accordion>
								<Accordion title="Складской учет">
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="minimum" className="label-middle">Минимальный остаток на складе</label>
										<input id="minimum" {...bindComponentMinimum} type="text" placeholder="0" maxLength={4} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="requestcount" className="label-middle">Количество для заявки по умолчанию</label>
										<input id="requestcount" {...bindComponentRequestCount} type="text" placeholder="0" maxLength={4} />
									</div>
								</Accordion>
							</div>
						</div>
						<div className="product-edit-footer">
							<button type="button" onClick={modalComponentClose} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-save btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalComponent>
			<ModalComponentCategory>
				<div className="modal">
					<div className="header">
						<h4>Добавить категорию</h4>
						<img src={imgClose} alt="" onClick={modalComponentCategoryClose} className="btn-close" />
					</div>
					<input type="text" {...bindComponentCategoryName} placeholder="Название категории" autoFocus={true} />
					<div className="buttons">
						<button type="button" onClick={modalComponentCategoryClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={componentCategorySave} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalComponentCategory>
		</>;
};


export default ComponentAdd;
export {
	componentAddShow
};