const phoneNormalize = (phone) => {
	if (!empty(phone)) {
		phone = phone.replace(/\s+/gi, '');
		phone = phone.replace(/-/gi, '');
		phone = phone.replace(/\(/gi, '');
		phone = phone.replace(/\)/gi, '');
		phone = phone.replace(/\+/gi, '');
		phone = phone.replace(/[^0-9]+/, '');
		if (!empty(phone)) phone = phone.length < 10 ? '' : phone;
		if (!empty(phone) && phone[0] === '8') phone = phone.substr(1);
		if (!empty(phone)) phone = phone[0] !== '7' ? '7' + phone : phone;
	}
	return phone && phone.length === 11 ? phone : null;
};

const phoneFormatter = (phone) => empty(phone) ? phone : phone.replace('+', '').replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5');
const empty = (text) => text === undefined || text == null || text === '' || text.toString().trim() === '';

const moneyFormat = (amount, nofraction) => amount ? parseFloat(amount).toFixed(nofraction === undefined || nofraction === true ? 2 : 0).replace(/(\d)(?=(\d{3})+(?!\d)\.?)/g, '$1 ') : 0;
const moneyFormatMinus = (amount, nofraction) => {
	const total = moneyFormat(amount, nofraction);
	return total ? total.replace('-','– ') : total;
};

const zeroPad = (num, places) => String(num).padStart(places, '0');

const rn2br = (text) => text.toString().replace(/\r\n/gi, '<br/>').replace(/\r/gi, '<br/>').replace(/\n/gi, '<br/>');

const translit = (text) => {
	text = text.toLowerCase();
	const rus = 'абвгдеёжзийклмнопрстуфхцчшщъыьэюя',
		lat = 'a,b,v,g,d,e,e,zh,z,i,y,k,l,m,n,o,p,r,s,t,u,f,h,ts,ch,sh,shch, ,y, ,e,yu,ya',
		trans = {};
	rus.split('').forEach((el, i) => trans[el] = lat.split(',')[i]);
	return text.split('').map((el) => trans[el] || el).join('').replace(/\s+/g, '-');
};

const keyboardLayout = (text) => {
	const layout = {
		'q':'й','w':'ц','e':'у','r':'к','t':'е','y':'н','u':'г','i':'ш','o':'щ','p':'з','[':'х',']':'ъ',
		'a':'ф','s':'ы','d':'в','f':'а','g':'п','h':'р','j':'о','k':'л','l':'д',';':'ж',"'":'э',
		'z':'я','x':'ч','c':'с','v':'м','b':'и','n':'т','m':'ь',',':'б','.':'ю','/':'.','`':'ё',
		'Q':'Й','W':'Ц','E':'У','R':'К','T':'Е','Y':'Н','U':'Г','I':'Ш','O':'Щ','P':'З','{':'Х','}':'Ъ',
		'A':'Ф','S':'Ы','D':'В','F':'А','G':'П','H':'Р','J':'О','K':'Л','L':'Д',':':'Ж','"':'Э',
		'Z':'Я','X':'Ч','C':'С','V':'М','B':'И','N':'Т','M':'Ь','<':'Б','>':'Ю','?':',','~':'Ё'
	};
	return text.split('').map((el) => layout[el] || el).join('');
};

const dateNormalize = (object, key) => object && object[key] ? object[key].replace('T00:00:00.000Z', '') : '';
const dateFullNormalize = (object, key) => object && object[key] ? object[key].replace('.000Z', '') : '';
const dateTimeNormalize = (object, key, isdefault) => (object && object[key] ? object[key] : (isdefault === undefined || isdefault === true ? convertUTCDateToLocalDate(new Date()).toISOString() : '')).slice(0, 16);
const convertUTCDateToLocalDate = (date) => {
	const d = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
	const offset = date.getTimezoneOffset() / 60;
	const hours = date.getHours();
	d.setHours(hours - offset);
    return d;
};

const imageExtGet = (image) => {
	if (isImage(image)) {
		if (image.indexOf('data:image/jpeg') === 0) return 'jpg';
		if (image.indexOf('data:image/png') === 0) return 'png';
		if (image.indexOf('data:image/webp') === 0) return 'webp';
		if (image.indexOf('data:image/vnd.microsoft.icon') === 0 || image.indexOf('data:image/x-icon') === 0) return 'ico';
		if (image.indexOf('data:image/svg+xml') === 0) return 'svg';
	}
	return null;
};
const isImage = (image) => image && image.indexOf('data:image/') === 0;

const weekDayName = {
	short: ['Пн','Вт','Ср','Чт','Пт','Сб','Вс'],
	full: ['Понедельник','Вторник','Среда','Четверг','Пятница','Суббота','Воскресенье']
};

const linkFromText = (text) => text.replace(/(https?:\/\/[^\s]+)/g, (url) => '<a href="' + url + '" target="_blank">' + url + '</a>');

const deepCopy = (object) => JSON.parse(JSON.stringify(object));

const numFix = (num) => num ? parseFloat(num.toFixed(2)) : num;


export default {
	phoneNormalize,
	phoneFormatter,
	empty,
	moneyFormat,
	moneyFormatMinus,
	rn2br,
	zeroPad,
	translit,
	keyboardLayout,
	dateNormalize,
	dateFullNormalize,
	dateTimeNormalize,
	imageExtGet,
	isImage,
	weekDayName,
	linkFromText,
	deepCopy,
	numFix
};