import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {Settings} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { API, settingTarget, settingTargetCodes, settingTargetName, settingType, settingTypeName, timezones } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveNE from './Images/arrow-curve-ne.svg';
import imgMenuPoints from './Images/menu-points.svg';
import imgEdit from './Images/edit.svg';
import imgNoImage from './Images/no-image.svg';

// start
const SettingsAdminScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [partnerId, setPartnerId] = useState(null);
	const [settings, setSettings] = useState([]);
	const [settingsAll, setSettingsAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:code, setValue:setCode, bind:bindCode} = useInput('');
	const {value:value, setValue:setValue, bind:bindValue} = useInput('');
	const {value:prefix, setValue:setPrefix, bind:bindPrefix} = useInput('');
	const {value:postfix, setValue:setPostfix, bind:bindPostfix} = useInput('');
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [type, setType] = useState('');
	const [target, setTarget] = useState(settingTarget.COMMON);
	const [targetUrl, setTargetUrl] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			cancel();
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const targetUrl = props.match.params.target||'common';
			setTargetUrl(targetUrl);
			const target = settingTargetCodes[targetUrl];
			setTarget(target);
			await settingsGet(target);
			const point = ls('point');
			setPartnerId(point.partnerId);
		};
		await dataGet();
		setLoading(false);
	}, [props]);
	const settingsGet = async (target) => {
		const settings = await Settings.get.target(target).catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (settings === undefined) return;
		setSettings(settings);
		setSettingsAll(settings);
	};
	const settingAdd = () => {
		setIsShow(false);
		setIsEditShow(true);
		show();
	}
	const settingEdit = (e, setting) => {
		e.stopPropagation();
		setting = setting || settings.find(f => f._id === id);
		show(setting);
		setIsShow(false);
		setIsEditShow(true);
	};
	const settingShow = (setting) => {
		show(setting);
		setIsEditShow(false);
		setIsShow(true);
	};
	const show = (setting) => {
		setControlId(null);
		setId(setting?._id||null);
		setName(setting?.name||'');
		setDescription(setting?.description||'');
		setCode(setting?.code||'');
		setValue(setting ? (setting.type === settingType.FORM ? JSON.parse(setting.value||'{}') : setting.value) : '');
		if (setting) {
			if (setting.type === settingType.FORM) setValue(JSON.parse(setting.value||'{}'));
			if (setting.type === settingType.IMAGE) setValue(imageUrlGet(partnerId, targetUrl, setting._id, setting.value));
			if (setting.type === settingType.GALLERY) setValue(setting.value && setting.value.length ? setting.value.map((v) => imageUrlGet(partnerId, targetUrl, setting._id, v)) : null);
		} else setValue('');
		setPrefix(setting?setting.prefix:'');
		setPostfix(setting?setting.postfix:'');
		setType(setting?setting.type:'');
		setTarget(setting?setting.target:'');
		setIsEditShow(true);
	};
	const cancel = () => {
		settingShow(null);
		setIsEditShow(false);
		setIsShow(false);
	}
	const dataPrepare = (value) => {
		const fromdata = document.getElementById('formTemplate');
		const inputs = fromdata.getElementsByTagName('input');
		value?.keys?.forEach((v, i) => {
			if (v.type === 'checkbox') v.value = inputs[v.id].checked;
			if (v.type === 'input' || v.type === 'password') v.value = inputs[v.id].value;
		});
		return JSON.stringify(value);
	}
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name:name,
			description:description,
			code:code,
			value: type === settingType.FORM ? dataPrepare(value) : value,
			prefix:prefix,
			postfix:postfix,
			type:type,
			target:target
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название');
			return;
		}
		if (Utils.empty(data.code)) {
			errorShow('Необходимо заполнить код');
			return;
		}
		if (type === settingType.IMAGE) {
			if (Utils.isImage(value)) data.value = `${id}.${Utils.imageExtGet(value)}`;
		}
		let gallery = null;
		if (type === settingType.GALLERY) {
			gallery = [...value||[]];
			data.value = (value||[]).map((v,i) => {
				const data = imagePrepare(v);
				return data ? `${id}-${i}.${Utils.imageExtGet(v)}` : value;
			});
		}
		const res = id ?
				await Settings.admin.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Settings.admin.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			if (type === settingType.IMAGE) await imageUpload(id);
			if (type === settingType.GALLERY) await imagesUpload(id, gallery);
			successShow(res.message);
			settingsGet(target);
			cancel();
		}
	}
	const imageUpload = async (id) => {
		const ext = Utils.imageExtGet(value);
		if (ext) {
			const data = {
				name:`${id}.${ext}`,
				data:imagePrepare(value),
				target:targetUrl
			};
			Settings.imageAdd(id, data).catch((ex) => console.log(666, ex));
		}
	};
	const imagesUpload = async (id, value) => {
		value.forEach((v, i) => {
			const ext = Utils.imageExtGet(v);
			if (ext) {
				const data = {
					name:`${id}-${i}.${ext}`,
					data:imagePrepare(v),
					target:targetUrl
				};
				Settings.imageAdd(id, data).catch((ex) => console.log(666, ex));
			}
		});
	}
	const imagePrepare = (image) => Utils.isImage(image) ? image.replace(/^.*,/gi,'') : null;
	const searchSetting = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setSettings(settingsAll);
		else setSettings(settingsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const settingControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	}
	const imageError = (e) => {
		e.target.src = imgNoImage;
		e.target.className = 'image image-error';
	};
	const imageDelete = () => setValue(null);
	const handleImage = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setValue(r.target.result);
		reader.readAsDataURL(file);
	};
	const handleGallery = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setValue([...value||[], r.target.result]);
		reader.readAsDataURL(file);
	};
	const imageUrlGet = (partnerId, targetUrl, id, value) => `${API.assets}partners/${partnerId}/media/${targetUrl}/${id}/${value}`;
	const controlRender = (type, value) => {
		switch (type) {
			case settingType.INPUT:
				return <input type="text" {...bindValue} placeholder="Значение" autoFocus={true} />;
			case settingType.TEXTAREA:
				return <textarea {...bindValue} placeholder="Значение" className="large" autoFocus={true} />;
			case settingType.IMAGE:
				return <div className="product-edit-row">
					{value ?
							<div className="product-edit-images">
								<div className="product-edit-image product-edit-image-wide">
									<img src={value} alt="" onError={imageError} className="image" />
									<div className="delete" onClick={imageDelete}></div>
								</div>
							</div>
						: null}
					<label>
						<div className="product-edit-btn">
							Добавить изображение
							<input type="file" accept="image/jpeg,image/png,image/webp,image/vnd.microsoft.icon,image/x-icon,image/svg+xml" onChange={handleImage} />
						</div>
					</label>
				</div>;
			case settingType.GALLERY:
				return <div className="product-edit-row">
					{value && value.length ?
							<div className="product-edit-images">
								{value.map((v,i) => <div key={i} className="product-edit-image product-edit-image-wide">
									<img src={v} alt="" />
									<div className="delete" onClick={() => setValue(value.filter(f => f !== v))}></div>
								</div>)}
							</div>
							:
								<div className="product-edit-images">
									<div className="product-edit-image product-edit-image-wide">
										<img src={imgNoImage} alt="" className="image" />
									</div>
								</div>
					}
					<label>
						<div className="product-edit-btn">
							Добавить изображение
							<input type="file" accept="image/jpeg,image/png,image/webp,image/vnd.microsoft.icon,image/x-icon,image/svg+xml" onChange={handleGallery} />
						</div>
					</label>
				</div>;
			case settingType.TIMEZONE:
				return <div className="product-edit-row product-edit-row-oneline">
				<div className="select select-wide">
					<select id="status" onChange={handleValue} value={value}>
						<option value=""></option>
						{timezones.map((v,i) => <option key={i} value={v.value}>{v.name} ({v.value})</option>)}
					</select>
				</div>
			</div>;
			case settingType.FORM:
				const out = [];
				value?.keys?.forEach((v, i) => {
					if (v.type === 'checkbox') {
						out.push(<div key={i} className="form-row form-oneline">
							<input type="checkbox" id={v.id} checked={v.value==='checked'?v.value:(v.default?'checked':null)} onChange={handleInputValue} />
							<label htmlFor={v.id}>{v.name}</label>
						</div>);
					}
					if (v.type === 'input' || v.type === 'password') {
						out.push(<div key={i} className="form-row">
							<label htmlFor={v.id}>{v.name}</label>
							<input type={v.type === 'password' ? 'password' : 'text'} id={v.id} placeholder={v.name} value={v.value} onChange={handleInputValue} />
						</div>);
					}
				});
				value?.links?.forEach((v, i) => {
					out.push(<div key={i} className="form-row">
						<a href={v.link} target="_blank">{v.title}</a>
					</div>);
				});
				return <>
					<textarea placeholder="Значение" onChange={handleTextarea}>{JSON.stringify(value)}</textarea>
					<div id="formTemplate" className="form-template">
						{out}
					</div>
				</>;
			case settingType.COLOR:
				return <input type="color" {...bindValue} placeholder="Значение" autoFocus={true} required />;
			case settingType.SELECT:
				const select = [];
				let defkey = null;
				value?.split(';').forEach((v,i) => {
					const [key, val, def] = v.split(':');
					select.push({value:key,name:val});
					if (def === 'true') defkey = key;
				});
				return <div className="product-edit-row">
					<div className="select select-wide">
						<select id="status" onChange={handleSelect} value={defkey} required>
							<option value=""></option>
							{select.map((v,i) => <option key={i} value={v.value}>{v.name}</option>)}
						</select>
					</div>
				</div>;
			case settingType.SELECT:
				let payment = null;
				value?.split(';').forEach((v,i) => {
					const [val, name, def] = v.split(':');
					if (def === 'true') payment = name;
				});
				return payment;
			default:
				return null;
		}
	};
	const handleTextarea = (e) => {
		const v = e.target.value;
		try {
			setValue(JSON.parse(v));
		} catch (ex) {
			console.log(666, ex);
		}
	}
	const handleInputValue = (e) => {
		const val = e.target.value, id = e.target.id, type = e.target.type, ischecked = type === 'checkbox' ? e.target.checked ?? e.target.value : null;
		if (type === 'checkbox') value?.keys.map((v) => v.value = v.id === id ? (ischecked ? 'checked' : null) : v.value);
		else value?.keys.map((v) => v.value = v.id === id ? val : v.value);
		setValue({...value});
	}
	const handleValue = (e) => setValue(e.target.value);
	const handleSelect = (e) => {
		const val = e.target.value;
		const select = [];
		value?.split(';').forEach((v,i) => {
			const [key, name, def] = v.split(':');
			select.push(`${key}:${name}:${key === val}`);
		});
		setValue(select.join(';'));
	};
	const valueGet = (type, value, prefix, postfix) => {
		switch (type) {
			case settingType.INPUT:
				return <div dangerouslySetInnerHTML={{__html:`${prefix||''} ${Utils.linkFromText(value||'–')} ${postfix||''}`.trim()}} />
			case settingType.TEXTAREA:
				return `${prefix||''} ${value||'–'} ${postfix||''}`.trim();
			case settingType.IMAGE:
				return <img src={value||imgNoImage} alt="" onError={imageError} className="image" />;
			case settingType.GALLERY:
				return value && value.length ? value.map((v,i) => <img key={i} src={v} alt="" onError={imageError} className="image" />) : <img src={imgNoImage} alt="" className="image" />;
			case settingType.TIMEZONE:
				return timezones.find(f => f.value === parseInt(value))?.name;
			case settingType.FORM:
				const out = [];
				value?.keys?.forEach((v, i) => {
					if (v.type === 'checkbox') {
						out.push(<div key={i} className="product-view-row product-view-row-oneline">
							<span>{v.name}</span>
							<div>{v.value ? 'Да' : 'Нет'}</div>
						</div>);
					}
					if (v.type === 'input' || v.type === 'password') {
						out.push(<div key={i} className="product-view-row product-view-row-oneline">
							<span>{v.name}</span>
							<div>{v.value ? (v.type === 'password' ? '••••••••' : v.value) : '–'}</div>
						</div>);
					}
				});
				return out;
			case settingType.COLOR:
				return <>
					<div className="color" style={{backgroundColor:value}}></div>
					<div>{value}</div>
				</>;
			default:
				return null;
		}
	}
	const handleType = (e) => setType(parseInt(e.target.value));
	const handleTarget = (e) => setTarget(parseInt(e.target.value));
	return (
		<>
			<Template click={() => setControlId(null)}>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container">
								<SideMenu title="Настройки" type={[{type:'account'},{type:'settings',title:'Настройки'}]} allHide={true} />
								<div className="products-list">
									<div className="products-list-controls">
										<input type="search" placeholder="Поиск" className="search" onChange={searchSetting} value={search} />
										<button type="button" onClick={() => settingAdd()}>
											<img src={imgPlusButton} alt="" />
										</button>
									</div>
									<div className="container-inner">
										{settings.length > 0 ?
												<table className="items">
													<thead>
														<tr>
															<th>Название</th>
															<th className="control"></th>
														</tr>
													</thead>
													<tbody>
														{settings.map((v,i) => <tr key={i} onClick={() => settingShow(v)}>
															<td>{v.name}</td>
															<td className="control">
																<img src={imgMenuPoints} alt="" onClick={(e) => settingControlSelect(e, v._id)} />
																{controlId === v._id ?
																		<div className="control-block">
																			<div className="edit" onClick={(e) => settingEdit(e, v)}>Редактировать</div>
																		</div>
																	: null}
															</td>
														</tr>)}
													</tbody>
												</table>
											:
												<div className="products-empty">
													<div>
														Добавьте новую<br/>настройку
													</div>
													<img src={imgArrowCurveNE} alt="" />
												</div>
										}
									</div>
								</div>
							</div>
							<div className="product-edit">
								{isShow ?
										<div className="product-edit-list-container">
											<div className="container-inner">
												<div className="list">
													<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
														<div className="product-view-row-oneline product-view-row-oneline-start">
															<h3>{name}</h3>
														</div>
														<img src={imgEdit} alt="" className="edit" onClick={(e) => settingEdit(e)} />
													</div>
													{description ?
														<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
													<h4>Код</h4>
													<div className="product-view-row product-view-row-simple">
														{code}
													</div>
													{type === settingType.FORM ?
															<>
																<h4>Параметры</h4>
																{valueGet(type, value)}
															</>
															
														:
															<>
																<h4>Значение</h4>
																<div className="product-view-row-simple">
																	{valueGet(type, value, prefix, postfix)}
																</div>
															</>
													}
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
											</div>
										</div>
									: null}
								{isEditShow ?
										<form className="product-edit-list-container" onSubmit={save}>
											<div className="container-inner">
												<div className="list">
													<div className="product-edit-row">
														<input type="text" {...bindName} placeholder="Название" className="input-title" autoFocus={true} required />
													</div>
													<div className="product-edit-row">
														<textarea placeholder="Описание, комментарий" {...bindDescription} className="lite"></textarea>
													</div>
													<h4>Параметры</h4>
													<div className="product-edit-row">
														<input id="code" {...bindCode} type="text" placeholder="Код настройки" required />
													</div>
													<div className="product-edit-row">
														{controlRender(type, value)}
													</div>
													<h4>Дополнительно</h4>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="prefix" className="label-middle">Префикс</label>
														<input id="prefix" {...bindPrefix} type="text" placeholder="Префикс" />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="postfix" className="label-middle">Приставка</label>
														<input id="postfix" {...bindPostfix} type="text" placeholder="Приставка" />
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Тип</label>
														<div className="select select-middle">
															<select id="status" onChange={handleType} value={type} required>
																<option value=""></option>
																{settingTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
													<div className="product-edit-row product-edit-row-oneline">
														<label htmlFor="status">Предназначено для</label>
														<div className="select select-middle">
															<select id="status" onChange={handleTarget} value={target} required>
																<option value=""></option>
																{settingTargetName.map((v,i) => <option key={i} value={i}>{v}</option>)}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div className="product-edit-footer">
												<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
												<button type="submit" className="btn-save">Сохранить</button>
											</div>
										</form>
									: null}
								{isShow || isEditShow ? null : <Empty image={'settings'} text={<>Для редактирования настройки,<br />выберите одину из списка</>} />}
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default SettingsAdminScreen;