import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get = {
	all		: async ()		=> await Http.request('dayshifts'),
	active	: async ()		=> await Http.request('dayshifts/active')
};

const actions = {
	open	: async (id)	=> await Http.request(`dayshift/open/${id}`, httpMethods.POST),
	close	: async (id)	=> await Http.request(`dayshift/close/${id}`, httpMethods.PUT),
	reopen	: async (id)	=> await Http.request(`dayshift/reopen/${id}`, httpMethods.PUT)
};


export {
	get,
	actions
}