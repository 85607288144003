import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get = {
	all			: async ()			=> await Http.request('products'),
	id			: async (id)		=> await Http.request(`product/${id}`),
	active		: async ()			=> await Http.request('products/active'),
	store		: async ()			=> await Http.request('products/store')
};

const add		= async (data)		=> await Http.request('product', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`product/${id}`, httpMethods.PUT, data);

const status	= async (id, data)	=> await Http.request(`product/status/${id}`, httpMethods.PATCH, data);

const patch		= async (id, data)	=> await Http.request(`product/${id}`, httpMethods.PATCH, data);

const sort		= async (ids)		=> await Http.request('products/sort', httpMethods.PATCH, ids);

const remove	= async (id)		=> await Http.request(`product/${id}`, httpMethods.DELETE);

const image = {
	add			: async (id, data)	=> await Http.request(`product/image/${id}`, httpMethods.POST, data),
	copy		: async (id, id2)	=> await Http.request(`product/image/copy/${id}/${id2}`, httpMethods.POST),
	update		: async (id, data)	=> await Http.request(`product/images/${id}`, httpMethods.PATCH, data)
};


export {
	get,
	add,
	update,
	status,
	patch,
	sort,
	remove,
	image
}