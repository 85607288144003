import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Empty from '../Empty';
import {useInput} from '../Hooks/Input';
import Alert,{errorShow,successShow} from '../Alert';
import Accordion from '../Accordion';

// models
import {ComponentCategories, Components, Products, Semifinishes} from '../../Models';

// helpers
import Utils from '../../Globals/Utils';

// constants
import { measureType, measureTypeName, measureTypeSmallName } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlus from './Images/plus-black.svg';
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveDown from './Images/arrow-curve-down.svg';
import imgClose from './Images/close.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgAdd from './Images/add.svg';


const ComponentPopupStorage = (props) => {
	const componentPriceGet = (t) => {
		if (!/^[0-9.,]*$/.test(t.value)) {
			setQuantity(quantity);
			return;
		}
		const v = parseFloat(t.value?.replace(',', '.'));
		const p = t.id === 'price' ? v : price || 0;
		const q = t.id === 'quantity' ? v : quantity || 0;
		let a = 0;
		if (isSemifinish) a = (p * 100) / component.properties.brutto;
		else a = p * q;
		setTotalPrice(a > 0 ? Number(a.toFixed(2)) : 0);
	};
	const [ModalProducts, modalProductsOpen, modalProductsClose] = useModal('root');
	const [ModalComponent, modalComponentOpen, modalComponentClose] = useModal('root');
	const [ModalItem, modalItemOpen, modalItemClose] = useModal('root', {closeOnOverlayClick:false});
	const [ModalComponentCategory, modalComponentCategoryOpen, modalComponentCategoryClose] = useModal('root');
	const [ModalItemComponents, modalItemComponentsOpen, modalItemComponentsClose] = useModal('root');
	const [ModalSemifinishes, modalSemifinishesOpen, modalSemifinishesClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [products, setProducts] = useState([]);
	const [productsAll, setProductsAll] = useState([]);
	const {value:componentCategoryName, setValue:setComponentCategoryName, bind:bindComponentCategoryName} = useInput('');
	const {value:price, setValue:setPrice, bind:bindPrice} = useInput('', componentPriceGet);
	const {value:quantity, setValue:setQuantity, bind:bindQuantity} = useInput('', componentPriceGet);
	const [totalPrice, setTotalPrice] = useState(0);
	const [component, setComponent] = useState(null);
	const [components, setComponents] = useState([]);
	const [componentCategoryId, setComponentCategoryId] = useState(null);
	const [componentCategories, setComponentCategories] = useState([]);
	const {value:componentName, setValue:setComponentName, bind:bindComponentName} = useInput('');
	const {value:componentDescription, setValue:setComponentDescription, bind:bindComponentDescription} = useInput('');
	const {value:componentWeight, setValue:setComponentWeight, bind:bindComponentWeight} = useInput('');
	const {value:componentMeasureType, setValue:setComponentMeasureType, bind:bindComponentMeasureType} = useInput('');
	const {value:componentPrice, setValue:setComponentPrice, bind:bindComponentPrice} = useInput('');
	const {value:componentInvoicePrice, setValue:setComponentInvoicePrice, bind:bindComponentInvoicePrice} = useInput('');
	const {value:componentMinimum, setValue:setComponentMinimum, bind:bindComponentMinimum} = useInput('');
	const {value:componentRequestCount, setValue:setComponentRequestCount, bind:bindComponentRequestCount} = useInput('');
	const [semifinishes, setSemifinishes] = useState([]);
	const [isSemifinish, setIsSemifinish] = useState(undefined);
	const [isProduct, setIsProduct] = useState(undefined);
	const [items, setItems] = useState([]);
	useEffect(async () => {
		await componentCategoriesGet();
		await componentsGet();
		await semifinishesGet();
		await productsGet();
		setLoading(false);
	}, []);
	useEffect(() => {
		const items = props.selected;
		if (items.length) setItems([...props.selected]);
	}, [props.selected]);
	const componentsGet = async () => {
		const components = await Components.get.get().catch((ex) => console.log(666, ex));
		if (components === undefined) return;
		setComponents(components);
	};
	const componentCategoriesGet = async () => {
		const categories = await ComponentCategories.get().catch((ex) => console.log(666, ex));
		if (categories === undefined) return;
		setComponentCategories(categories);
	};
	const semifinishesGet = async () => {
		const semifinishes = await Semifinishes.get().catch((ex) => console.log(666, ex));
		if (semifinishes === undefined) return;
		setSemifinishes(semifinishes);
	};
	const productsGet = async () => {
		const products = await Products.get[props.productsAll?'all':'store']().catch((ex) => console.log(666, ex));
		if (products === undefined) return;
		setProducts(products);
		setProductsAll(products);
	};
	const searchProduct = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setProducts(productsAll);
		else setProducts(productsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	};
	const componentsItemShow = () => modalItemComponentsOpen();
	const componentSelect = (component) => {
		clear();
		setComponent(component);
		const price = component.prices?.manual||component.prices?.invoice||0;
		setPrice(price);
		modalItemComponentsClose();
		setIsSemifinish(false);
		setIsProduct(false);
	};
	const componentAdd = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (!component) {
			errorShow('Необходимо выбрать компонент');
			return;
		}
		const data = {
			id:component._id,
			name:component.name,
			price:parseFloat(price),
			quantity:parseFloat(quantity),
			measure:isProduct||isSemifinish?measureType.PIECES:component.measure,
			total:totalPrice,
			isproduct:isProduct,
			issemifinish:isSemifinish
		};
		if (isSemifinish) data.weight = component.properties.brutto;
		if (Utils.empty(data.price) || Utils.empty(data.quantity)) {
			errorShow('Необходимо заполнить все поля');
			return;
		}
		const d = [...items, data];
		setItems(d);
		props.onUpdate(d);
	};
	const componentCategoryShow = () => {
		setComponentCategoryName('');
		modalComponentCategoryOpen();
	};
	const componentCategorySave = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (Utils.empty(componentCategoryName)) return;
		const data = {name:componentCategoryName};
		const res = await ComponentCategories.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			componentCategoriesGet();
			modalComponentCategoryClose();
		}
	};
	const componentAddShow = () => {
		setComponentName('');
		setComponentDescription('');
		setComponentPrice('');
		setComponentInvoicePrice('');
		setComponentMinimum('');
		setComponentRequestCount('');
		setComponentWeight('');
		setComponentMeasureType('');
		modalComponentOpen();
	};
	const handleComponentCategory = (e) => setComponentCategoryId(e.target.value);
	const handleComponentMeasureType = (e) => setComponentMeasureType(parseInt(e.target.value));
	const itemShow = () => {
		clear();
		modalItemOpen();
	};
	const componentSave = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		const data = {
			categoryId:componentCategoryId,
			name:componentName,
			description:componentDescription,
			price:componentPrice,
			requestCount:componentRequestCount,
			minimum:componentMinimum,
			weight:componentWeight,
			measure:componentMeasureType
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название компонента');
			return;
		}
		if (data.measure === measureType.PIECES && Utils.empty(data.weight)) {
			errorShow('Необходимо заполнить вес компонента');
			return;
		}
		if (data.measure === null) {
			errorShow('Необходимо заполнить единицу измерения компонента');
			return;
		}
		const res = await Components.add(data).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		if (res) {
			successShow(res.message);
			componentsGet();
			modalComponentClose();
		}
	};
	const semifinishesShow = () => modalSemifinishesOpen();
	const semifinishSelect = (semifinish) => {
		clear();
		setComponent(semifinish);
		const price = semifinish.total||0;
		setPrice(price);
		modalSemifinishesClose();
		setIsSemifinish(true);
		setIsProduct(false);
	};
	const productItemShow = () => modalProductsOpen();
	const productComponentSelect = (product) => {
		clear();
		product.measure = product.properties.measureType;
		setComponent(product);
		const price = product.prices?.price||0;
		setPrice(price);
		modalProductsClose();
		setIsSemifinish(false);
		setIsProduct(true);
	};
	const clear = () => {
		setPrice('');
		setQuantity('');
		setTotalPrice(0);
		setComponent(null);
		setComponentCategoryId(null);
		setIsSemifinish(undefined);
		setIsProduct(undefined);
	};
	const isAllow = (type) => props.allow ? props.allow.includes(type) : true;
	const allowCount = () => props.allow ? props.allow.length : 3;
	const itemFocus = (e) => e.target.select();
	const close = () => {
		modalItemClose();
		setItems([]);
		clear();
	};
	return loading ? null :
		<>
			<>
				<button type="button" onClick={() => itemShow()}>{props.title||'Добавить'}</button>
			</>
			<Alert />
			<ModalItem>
				<div className="modal modal-popup-storage-items modal-popup-storage-items-wide">
					<div className="header">
						<h4>Добавить позицию</h4>
						<img src={imgClose} alt="" onClick={close} className="btn-close" />
					</div>
					<form onSubmit={componentAdd}>
						{component ?
								<div>
									<b>{component.name}</b> • <span>
										{isSemifinish === true ? <>{component.total||0}₽ за {component.properties.brutto}г</> : null}
										{isSemifinish === false && isProduct === false ? <>{component.prices?.manual||component.prices?.invoice||0}₽ за 1{measureTypeName[component.measure]}</> : null}
										{isProduct === true ? <>{component.prices?.price}₽ за {component.properties.weight}{measureTypeSmallName[component.properties.measureType]}</> : null}
									</span>
								</div>
							: null}
						<div className="buttons">
							{isAllow('component') ? <button type="button" className={`btn-cancel btn-select btn-select-half${allowCount()}${isProduct===false&&isSemifinish===false?' btn-select-half-select':''}`} onClick={() => componentsItemShow()}>Компонент</button> : null}
							{isAllow('semifinish') ? <button type="button" className={`btn-cancel btn-select btn-select-half${allowCount()}${isProduct===false&&isSemifinish===true?' btn-select-half-select':''}`} onClick={() => semifinishesShow()}>Полуфабрикат</button> : null}
							{isAllow('product') ? <button type="button" className={`btn-cancel btn-select btn-select-half${allowCount()}${isProduct===true&&isSemifinish===false?' btn-select-half-select':''}`} onClick={() => productItemShow()}>Товар</button> : null}
						</div>
						<div className="weights-form">
							{props.isCountOnly ? null :
								<div className="weights-form-row">
									<label htmlFor="price">Цена</label>
									<div>
										<input id="price" type="text" {...bindPrice} placeholder="0" maxLength={5} onFocus={itemFocus} required />
										<span>₽</span>
									</div>
								</div>
							}
							<div className="weights-form-row">
								<label htmlFor="quantity">Количество</label>
								<div>
									<input id="quantity" type="text" {...bindQuantity} placeholder="0" maxLength={4} onFocus={itemFocus} required />
									{component ? <span>{isProduct?'шт':(isSemifinish?'г':measureTypeName[component.measure])}</span> : <span>–</span>}
								</div>
							</div>
						</div>
						{props.isCountOnly ? <div className="weights-form-separator"></div> :
							<div className="weights-form">
								<div className="weights-form-row">
									<label>Сумма</label>
									<div>
										<b>{totalPrice} ₽</b>
									</div>
								</div>
							</div>
						}
						<div className="buttons">
							<button type="submit" className="btn-accept">Добавить</button>
						</div>
					</form>
				</div>
			</ModalItem>
			<ModalItemComponents>
				<div className="modal modal-popup-storage-conditions-lite">
					<div className="header">
						<div className="header-sub">
							<h4>Добавить компонент</h4>
							<img src={imgAdd} alt="" className="add" onClick={() => componentAddShow()} />
						</div>
						<img src={imgClose} alt="" onClick={modalItemComponentsClose} className="btn-close" />
					</div>
					<div className="conditions">
						<div className="sections">
							<div className="inner">
								{componentCategories.length ?
										<div className="list">
											{componentCategories.map((v,i) => <div key={i} className={`section${componentCategoryId===v._id?' section-select':''}`} onClick={() => setComponentCategoryId(v._id)}>
												{v.name}
											</div>)}
										</div>
									:
										<div className="category-empty">
											<div>
												Здесь можно добавить новые категории, чтобы заполнить меню
											</div>
											<img src={imgArrowCurveDown} alt="" />
										</div>
								}
							</div>
							<div className="sections-footer">
								<button type="button" onClick={() => componentCategoryShow()}>
									<img src={imgPlus} alt="" />
										Добавить категорию
								</button>
							</div>
						</div>
						<div className="content">
							{components.filter(f => f.category.id === componentCategoryId).length ?
									<div className="components-list">
										{components.filter(f => f.category.id === componentCategoryId).map((v,i) => <div key={i} className="component" onClick={() => componentSelect(v)}>
											{v.name}
											<span>{v.prices?.manual||v.prices?.invoice||0} ₽ • {v.measure === measureType.PIECES ? 1 : v.weight} {v.measure === measureType.PIECES ? 'шт.' : measureTypeName[v.measure]}</span>
										</div>)}
									</div>
								:
									<div className="empty">
										<Empty image={'components'} text={
											<>
												Для добавления компонента,<br />выберите один из списка слева
												<div className="product-add-container">
													<p>или<br/>добавьте новую позицию</p>
													<button type="button" onClick={() => componentAddShow()}>Добавить компонент</button>
												</div>
											</>} />
									</div>
								}
						</div>
					</div>
				</div>
			</ModalItemComponents>
			<ModalComponent>
				<div className="modal modal-popup-storage-component-newadd">
					<div className="header">
						<h4>Добавить компонент</h4>
						<img src={imgClose} alt="" onClick={modalComponentClose} className="btn-close" />
					</div>
					<form className="product-edit" onSubmit={componentSave}>
						<div className="container-inner">
							<div className="list">
								<div className="product-edit-row">
									<input type="text" {...bindComponentName} placeholder="Название компонента" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindComponentDescription} className="lite"></textarea>
								</div>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
									<div className="select select-wide">
										<select onChange={handleComponentCategory} value={componentCategoryId} required>
											<option value="">Выберите категорию</option>
											{componentCategories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
									<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => componentCategoryShow()} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="measure">Единица измерения</label>
									<div className="select">
										<select onChange={handleComponentMeasureType} value={componentMeasureType} required>
											{measureTypeName.map((v,i) => <option key={i} value={i===0?'':i}>{v}</option>)}
										</select>
									</div>
								</div>
								{componentMeasureType === measureType.PIECES ?
										<div className="product-edit-row product-edit-row-oneline">
											<label htmlFor="weight" className="label-middle">Вес компонента в граммах</label>
											<input id="weight" {...bindComponentWeight} type="text" placeholder="0" maxLength={4} required />
										</div>
									: null}
								<Accordion title="Цена">
									<div className="product-edit-row product-edit-row-oneline">
										<label className="label-middle">Цена из последней накладной</label>
										{componentInvoicePrice?`${componentInvoicePrice} ₽`:'–'}
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="price" className="label-middle">Цена (коррекция), ₽</label>
										<input id="price" {...bindComponentPrice} type="text" placeholder="0" maxLength={6} />
									</div>
								</Accordion>
								<Accordion title="Складской учет">
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="minimum" className="label-middle">Минимальный остаток на складе</label>
										<input id="minimum" {...bindComponentMinimum} type="text" placeholder="0" maxLength={4} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="requestcount" className="label-middle">Количество для заявки по умолчанию</label>
										<input id="requestcount" {...bindComponentRequestCount} type="text" placeholder="0" maxLength={4} />
									</div>
								</Accordion>
							</div>
						</div>
						<div className="product-edit-footer">
							<button type="button" onClick={modalComponentClose} className="btn-cancel">Отменить</button>
							<button type="submit" className="btn-save btn-accept">Сохранить</button>
						</div>
					</form>
				</div>
			</ModalComponent>
			<ModalComponentCategory>
				<div className="modal">
					<div className="header">
						<h4>Добавить категорию</h4>
						<img src={imgClose} alt="" onClick={modalComponentCategoryClose} className="btn-close" />
					</div>
					<input type="text" {...bindComponentCategoryName} placeholder="Название категории" autoFocus={true} />
					<div className="buttons">
						<button type="button" onClick={modalComponentCategoryClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={componentCategorySave} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalComponentCategory>
			<ModalSemifinishes>
				<div className="modal modal-popup-storage-products-list modal-popup-semifinishes">
					<div className="header">
						<h4>Добавить полуфабрикат</h4>
						<img src={imgClose} alt="" onClick={modalSemifinishesClose} className="btn-close" />
					</div>
					<div className="products">
						<div className="list">
							{semifinishes.length ?
									semifinishes.map((v,i) => <div key={i} className="product product-semifinish" onClick={() => semifinishSelect(v)}>
										<div>{v.name}</div>
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</ModalSemifinishes>
			<ModalProducts>
				<div className="modal modal-popup-storage-products-list">
					<div className="header">
						<h4>Добавить позицию</h4>
						<img src={imgClose} alt="" onClick={modalProductsClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchProduct} autoFocus={true} />
					<div className="products">
						<div className="list">
							{products.length ?
									products.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											<span>{v.prices.price} ₽ • {v.properties.weight} {measureTypeSmallName[v.properties.measureType]}</span>
										</div>
										<img src={component?.id === v._id ? imgCheckOn : imgCheckOff} alt="" onClick={() => productComponentSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</ModalProducts>
		</>;
};

export default ComponentPopupStorage;