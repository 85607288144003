import React, {useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";

// plug-ins
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import Layout from '../../../Components/Layout';

// models
import {Orders} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';


// start
const FeedbacksScreen = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [orders, setOrders] = useState(null);
	const [ordersAll, setOrdersAll] = useState([]);
	const [order, setOrder] = useState(null);
	const [search, setSearch] = useState('');
	const [id, setId] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await ordersGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const ordersGet = async () => {
		const transactions = await Orders.get.feedaback().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (transactions === undefined) return;
		setOrders(transactions);
		setOrdersAll(transactions);
	};
	const orderShow = (order) => show(order);
	const show = (order) => {
		setId(order?._id||null);
		setOrder(order);
	};
	const cancel = () => orderShow(null);
	const searchOrder = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setOrders(ordersAll);
		else setOrders(ordersAll.filter(f => f.client?.name.toLowerCase().includes(search.toLowerCase())
			|| f.number.toString().includes(search.toLowerCase())
			|| f.feedback.comment.toLowerCase().includes(search.toLowerCase())
			|| f.client.phone.includes(search.toLowerCase())));
		setSearch(search);
	}
	const statusGet = (v) => <div className={`status${v.feedback.rating>3?1:0}`}>
		<div className="status-point"></div>{v.feedback.rating}
	</div>;
	const goto = (link) => history.push(link);
	const feedbackViewSet = (id) => {
		Orders.feedback.view(id).catch((ex) => console.log(666, ex));
		return null;
	}
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Отзывы, рейтинг"
							type="marketing"
							data={orders}
							id={id}
							search={search}
							rows={[
								{title:'Дата',field:'createdAt',func:(v) => moment(v.createdAt).format('DD.MM.YYYY HH:mm')},
								{title:'Заказ',field:'number'},
								{title:'Клиент',field:'client',sort:'client.name',func:(v) => v.client.name},
								{title:'Оценка',field:'status',class:'status status-small',sort:'feedback.rating',func:statusGet}
							]}
							rowsShort={['createdAt','status']}
							rowControlItems={[
								{title:'Перейти в заказ',class:'receipt',action:(v) => goto(`/order/${v._id}`)},
								{title:'Профиль клиента',class:'profile',action:(v) => goto(`/books/client/${v.client.id}`)}
							]}
							empty={<>Здесь появятся заказы<br/>с отзывами или рейтингом</>}
							contentShowTitle={`№${order?.number} от ${moment(order?.createdAt).format('DD.MM.YYYY HH:mm')}`}
							contentShow={order ?
								<>
									{order?.comment ? 
										<div className="product-view-row-simple">
											{order.comment}
										</div> : null}
									<h4>Отзыв</h4>
									<div className="product-view-row product-view-row-simple">
										{order?.feedback.comment}
									</div>
									<h4>Оценка</h4>
									<div className="product-view-row product-view-row-simple">
										★ {order?.feedback.rating}
									</div>
									<h4>Состав заказа</h4>
									<div className="product-view-row product-view-row-simple">
										{order?.products.map((v,i) => <div key={i} className="product-view-row product-view-row-oneline">
											{v.name}
											<span>{v.quantity} шт.</span>
										</div>)}
									</div>
									<h4>Клиент</h4>
									<div className="product-view-row product-view-row-simple">
										{order?.client.name ? <>{order?.client.name}, </> : null} {Utils.phoneFormatter(order?.client.phone)}
									</div>
									{feedbackViewSet(id)}
									{order?.feedback.user?.id ?
										<>
											<h4>Отзыв просмотрел</h4>
											<div className="product-view-row product-view-row-simple">
												{moment(order.feedback.user.date).format('DD.MM.YYYY HH:mm')}, {order.feedback.user.name}
											</div>
										</> : null}

								</> : null
							}
							onSearch={searchOrder}
							onShow={orderShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
		</>
	);
};

export default FeedbacksScreen;