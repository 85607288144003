import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Error from '../Error';
import Alert,{errorShow,successShow} from '../Alert';

// models
import {DayShifts, Orders, Partners} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import Utils from '../../Globals/Utils';

// constants
import { API, commonStatus, commonStatusDayShiftName, TIMERS } from '../../Globals/Constants';
import Sidemenus from '../../Globals/Sidemenus';

// styles
import './styles.css';

// images
import imgPlus from './Images/plus.svg';
import imgLists from './Images/lists.svg';
import imgArrowDown from './Images/arrow-down.svg';
import imgArrowDownWhite from './Images/arrow-down-white.svg';
import imgProfile from './Images/profile.svg';
import imgBooks from './Images/book.svg';
import imgStatistic from './Images/statistic.svg';
import imgSupport from './Images/support.svg';
import imgWarehouse from './Images/warehouse.svg';
import imgMoney from './Images/money.svg';
import imgClose from './Images/close.svg';
import imgCheck from './Images/check.svg';
import imgClock from './Images/clock.svg';
import imgWarning from './Images/warning.svg';
import imgMarketing from './Images/marketing.svg';

// sound
import newOrder from './Audio/signal.mp3';

// variables
let timerId = null;

// start
const Template = (props) => {
	const [ModalAbout, openAbout, closeAbout] = useModal('root');
	const [ModalLogOff, openLogOff, closeLogOff] = useModal('root');
	const [ModalPointChange, openPointChange, closePointChange] = useModal('root');
	const [ModalDayShifts, openDayShifts, closeDayShifts] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [selectPointId, setSelectPointId] = useState(null);
	const [dayShifts, setDayShifts] = useState([]);
	const [user, setUser] = useState(null);
	const [point, setPoint] = useState(null);
	const [isError, setIsError] = useState(false);
	const [isLicenseError, setIsLicenseError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (user === null) {
				window.location.href='/error403';
				return;
			}
			setUser(user);
			const lastupdate = ls('lastupdate');
			if (lastupdate) setIsLicenseError(!lastupdate.isactive);
			const point = ls('point');
			if (point) setPoint(point);
			return await dayShiftGet(user);
		};
		const ds = await dataGet();
		setLoading(false);
		if (ds !== null) {
			if (dayShiftsCheck(ds) !== null) {
				const dswarning = ls('currentdate');
				const date = moment().format('YYYY-MM-DD');
				if (dswarning === null || dswarning !== date) {
					ls('currentdate', date);
					dayShiftsShow();
				}
			}
		}
		timer();
		return () => {
			clearInterval(timerId);
			timerId = null;
		};
	}, []);
	const timer = () => {
		timerId = setInterval(async () => {
			const id = ls('lastorder');
			const orders = await Orders.get.last(id).catch((ex) => console.log(666, ex));
			if (orders && orders.length) {
				const lastorder = ls('lastorder');
				if (lastorder === null || lastorder !== orders[0]._id) {
					ls('lastorder', orders[0]._id);
					soundPlay();
					successShow('Поступил новый заказ');
				}
			}
			const res = await Partners.license().catch((ex) => console.log(666, ex));
			const isactive = res.isactive;
			setIsLicenseError(!isactive);
			ls('lastupdate', {date:moment().format('YYYY-MM-DD HH:mm:ss'),isactive});
		}, TIMERS.UPDATE);
	};
	const soundPlay = () => {
		try {
			new Audio(newOrder).play();
		} catch (ex) {}
	};
	const dayShiftGet = async (user) => {
		const points = user.points;
		const res = await DayShifts.get.active().catch((ex) => {
			console.log(666, ex)
			setIsError(true);
		});
		if (res === undefined) return null;
		const dayShifts = [];
		points.forEach((v) => {
			const ds = res ? res.find(f => f.pointId === v.id) : null;
			if (ds) dayShifts.push({...ds, name:v.name});
			else {
				dayShifts.push({
					pointId:v.id,
					name:v.name,
					start:null,
					status:commonStatus.IN_ACTIVE
				});
			}
		});
		setDayShifts(dayShifts);
		return dayShifts.length ? dayShifts : null;
	};
	const dayShiftsCheck = (ds) => {
		if (ds?.length === 0) return 'close';
		let iserror = null;
		ds.forEach((v) => {
			if (v.status === commonStatus.IN_ACTIVE) iserror = 'close';
			if (iserror === null) {
				if (!dayShiftDurationCheck(v)) iserror = 'overdue';
			}
		});
		return iserror;
	};
	const dayShiftCheck = (ds) => dayShiftDurationCheck(ds) && ds.status === commonStatus.ACTIVE;
	const dayShiftDurationCheck = (ds) => ds.start === null ? true : moment().diff(moment(ds.start), 'hours') <= 24;
	const isselect = (page, add) => new RegExp(`${page}`).test(window.location.pathname) ? `select${add?` ${add}`:''}` : add;
	const click = (e) => {
		if (props.click) props.click(e);
	}
	const pointChangeShow = (e) => {
		e.preventDefault();
		openPointChange();
	}
	const pointSelect = (id) => setSelectPointId(id);
	const pointChange = (e) => {
		e.preventDefault();
		if (selectPointId) {
			const point = user.points.find(v => v.id === selectPointId);
			ls('point', point);
			setSelectPointId(null);
		};
		modalClose(e);
		window.location.reload();
	}
	const aboutShow = (e) => {
		e.preventDefault();
		openAbout();
	};
	const logOffShow = (e) => {
		e.preventDefault();
		openLogOff();
	}
	const dayShiftsShow = (e) => {
		e?.preventDefault();
		openDayShifts();
	};
	const modalClose = (e) => {
		e.preventDefault();
		setSelectPointId(null);
		closeLogOff();
		closePointChange();
		closeDayShifts();
	}
	const logOff = () => {
		ls();
		closeLogOff();
		window.location.href='/login';
	}
	const dayShiftTitle = () => {
		const iserror = dayShiftsCheck(dayShifts);
		let header = '', text = '';
		if (iserror === 'close') {
			header = <h4 className="warning">Смена не установлена!</h4>;
			text = <>
				Для работы в системе необходимо установить смену.<br/>
				Принимать и создавать заказы можно только в рамках установленной смены.<br/>
				Продолжительность смены – не более 24 часов.<br/>
			</>;
		}
		if (iserror === 'overdue') {
			header = <h4 className="warning">Смена просрочена!</h4>;
			text = <>
				Продолжительность смены – не более 24 часов.<br/>
				Для продолжения работы необходимо закрыть просроченную смену и открыть новую.<br/>
			</>;
		}
		if (iserror === null) {
			if (dayShifts.length === 1) header = <h4>Смена №{dayShifts[0].number}</h4>;
			else header = <h4>Активные смены</h4>;
		}
		return <>
			<div className="header">
				{header}
				<img src={imgClose} alt="" onClick={modalClose} className="btn-close" />
			</div>
			{text ? <p>{text}</p> : null}
		</>
	}
	const dayShiftAction = async (pointId) => {
		const ds = dayShifts.find(f => f.pointId === pointId);
		if (ds === undefined) {
			errorShow('Ошибка при определении смены');
			return;
		}
		let method = null;
		if (Utils.empty(ds.number)) method = 'open';
		else {
			if (dayShiftCheck(ds)) method = 'close';
			else method = 'reopen';
		}
		const res = await DayShifts.actions[method](pointId).catch((ex) => {
			errorShow();
			console.log(666, ex);
		});
		if (res) {
			closeDayShifts();
			successShow(res.message);
			if (window.location.pathname.indexOf('cashbox') !== -1) {
				window.location.reload();
				return;
			}
			const ds = await dayShiftGet(user);
			if (ds !== null) {
				if (dayShiftsCheck(ds) !== null) dayShiftsShow();
			}
		}
	}
	return loading ? null :
		<div className="container" onClick={click}>
			{isLicenseError ? <div className="license-error">Ваша компания заблокирована. Необходимо <Link to={'/account/pay'}>продлить тариф</Link>.</div> : null}
			<header>
				<ul className="main-menu">
					<li className="select select-yellow">
						<Link to={'/order-add'} className="menu-item">
							<img src={imgPlus} alt="" />
							Новый заказ
						</Link>
					</li>
					<li className={isselect('orders', 'more')}>
						<Link to={'/orders'} className="menu-item">
							<img src={imgLists} alt="" />
							Заказы
							<img src={imgArrowDown} alt="" className="arrow-down" />
						</Link>
						<div className="submenu">
							<ul>
								{Sidemenus.orders.map((v,i) => <li key={i}><Link to={v.link}>{v.name}</Link></li>)}
							</ul>
						</div>
					</li>
					<li className={isselect('books', 'more')}>
						<div className="menu-item">
							<img src={imgBooks} alt="" />
							Справочники
							<img src={imgArrowDown} alt="" className="arrow-down" />
						</div>
						<div className="submenu">
							<ul>
								{Sidemenus.books.map((v,i) => <li key={i}><Link to={v.link}>{v.name}</Link></li>)}
							</ul>
						</div>
					</li>
					<li className={isselect('marketing', 'more')}>
						<div className="menu-item">
							<img src={imgMarketing} alt="" />
							Маркетинг
							<img src={imgArrowDown} alt="" className="arrow-down" />
						</div>
						<div className="submenu">
							<ul>
								{Sidemenus.marketing.map((v,i) => <li key={i}><Link to={v.link}>{v.name}</Link></li>)}
							</ul>
						</div>
					</li>
					<li className={isselect('warehouse', 'more')}>
						<div className="menu-item">
							<img src={imgWarehouse} alt="" />
							Склад
							<img src={imgArrowDown} alt="" className="arrow-down" />
						</div>
						<div className="submenu">
							<ul>
								{Sidemenus.warehouse.map((v,i) => <li key={i}><Link to={v.link}>{v.name}</Link></li>)}
							</ul>
						</div>
					</li>
					<li className={isselect('money', 'more')}>
						<div className="menu-item">
							<img src={imgMoney} alt="" />
							Деньги
							<img src={imgArrowDown} alt="" className="arrow-down" />
						</div>
						<div className="submenu">
							<ul>
								{Sidemenus.money.map((v,i) => <li key={i}><Link to={v.link}>{v.name}</Link></li>)}
							</ul>
						</div>
					</li>
					<li className={isselect('statistics', 'more')}>
						<div className="menu-item">
							<img src={imgStatistic} alt="" />
							Статистика
							<img src={imgArrowDown} alt="" className="arrow-down" />
						</div>
						<div className="submenu">
							<ul>
								{Sidemenus.statistics.map((v,i) => <li key={i}><Link to={v.link}>{v.name}</Link></li>)}
							</ul>
						</div>
					</li>
					<li className={isselect('xxx', `dayshift more ${dayShiftsCheck(dayShifts)===null?'':'dayshift-warning'}`)}>
						<div className="menu-item">
							<img src={dayShiftsCheck(dayShifts) === null ? imgClock : imgWarning} alt="" />
							Смена
							<img src={imgArrowDownWhite} alt="" className="arrow-down" />
						</div>
						<div className="submenu">
							<ul>
								{dayShifts?.map((v,i) => <li key={i} className={`point${v.number?'':' point-warning'}`}>
									<Link to={''} onClick={dayShiftsShow}>
										{point.name}
										<span className="dayshiftnumber">Смена {v.number ? `№${v.number}` : `не установлена`}</span>
									</Link>
								</li>)}
							</ul>
						</div>
					</li>
					<li className={isselect('account|settings', 'more')}>
						<Link to={'/account'} className="menu-item">
							<img src={imgProfile} alt="" />
							Профиль
							<img src={imgArrowDown} alt="" className="arrow-down" />
						</Link>
						<div className="submenu user-menu">
							<ul>
								<li className="point">
									<Link to={''} onClick={pointChangeShow}>
										Сменить филиал
										<span>{point.name}</span>
									</Link>
								</li>
								<li><Link to={'/settings'}>Настройки</Link></li>
								<li><Link to={'/support'}>Поддержка</Link></li>
								<li><a href={`${API.help}`} target='_blank'>Помощь</a></li>
								<li><Link to={'/'} onClick={aboutShow}>О системе</Link></li>
								<li><Link to={''} onClick={logOffShow}>Выйти</Link></li>
							</ul>
						</div>
					</li>
				</ul>
			</header>
			<main className={`no-license`}>
				{isError ? <Error /> : props.children}
			</main>
			<ModalPointChange>
				<div className="modal">
					<div className="header">
						<h4>Переключить филиал</h4>
						<img src={imgClose} alt="" onClick={modalClose} className="btn-close" />
					</div>
					<div className="points">
						{user.points.map((v,i) => <div key={i} className="point" onClick={() => pointSelect(v.id)}>
							<span>{v.name}</span>
							{(selectPointId ? (v.id === selectPointId) : (v.id === point.id)) ? <img src={imgCheck} alt="" /> : null}
						</div>)}
					</div>
					<div className="buttons">
						<button type="button" onClick={modalClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={pointChange} className="btn-accept">Переключить</button>
					</div>
				</div>
			</ModalPointChange>
			<ModalLogOff>
				<div className="modal">
					<div className="header">
						<h4>Подтвердите пожалуйста</h4>
						<img src={imgClose} alt="" onClick={modalClose} className="btn-close" />
					</div>
					<p>Вы уверены что хотите выйти из системы администрирования?</p>
					<div className="buttons">
						<button type="button" onClick={modalClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={logOff} className="btn-accept btn-delete">Да</button>
					</div>
				</div>
			</ModalLogOff>
			<ModalDayShifts>
				<div className="modal modal-dayshift">
					{dayShiftTitle()}
					<p>При закрытитии и переотрытии смены, будет закрыта касса, остаток денежных средств будет переведен в следующую смену.</p>
					<div className="dayshifts">
						{dayShifts.map((v,i) => <div key={i} className={`dayshift${dayShiftCheck(v)?'':' dayshift-warning'}`}>
							<div>
								<div className="area">{v.name}</div>
								<div className="number">
									{v.number ?
											<>
												Смена №{v.number}
												<div className={`status${dayShiftDurationCheck(v)?(v.status===commonStatus.IN_ACTIVE?' status-closed':''):' status-timeout'}`}>{commonStatusDayShiftName[v.status]}</div>
											</>
										:
											<div className="warning">Смена не установлена</div>
									}
								</div>
								{v.start ?
										<span className={`date${dayShiftDurationCheck(v)?'':' warning'}`}>открыта {moment(v.start).format('DD.MM.YYYY HH:mm')} ({moment(v.start).fromNow()})</span>
									: null}
							</div>
							<button type="button" onClick={() => dayShiftAction(v.pointId)}>{dayShiftDurationCheck(v) ? (v.status === commonStatus.ACTIVE ? 'Закрыть' : 'Открыть') : 'Переоткрыть'}</button>
						</div>)}
					</div>
				</div>
			</ModalDayShifts>
			<ModalAbout>
				<div className="modal modal-about">
					<div className="header">
						<h4>О системе</h4>
						<img src={imgClose} alt="" onClick={closeAbout} className="btn-close" />
					</div>
					<p><b>PastilAPP</b> — cистема управления общепитом и доставкой</p>
					<p>Готовые сайты, мобильные приложения (Android, iOS) для доставок еды.</p>
					<p className="version">
						Версия {API.version}<br/>
						от {moment(API.versionDate).format('ll')}
					</p>
					<div className="buttons">
						<button type="button" onClick={closeAbout} className="btn-cancel">Закрыть</button>
					</div>
				</div>
			</ModalAbout>
			<Alert />
			{isLicenseError ?
				<style>
					{`
						.container main {
							height:calc(100% - 80px - 41px) !important;
						}
						.layout-container .components-categories-list .categories-list-container {
							height:calc(100vh - 225px - 41px) !important;
						}
						.components-categories-list .categories-list-container.categories-list-container-menu {
							height:calc(100vh - 225px - 41px) !important;
						}
						.layout-container .components-categories-list .categories-list-container {
							height:calc(100vh - 225px - 41px) !important;
						}
						.layout-container .categories-container .products-list .products-empty {
							height:calc(100% - 60px - 41px) !important;
						}
						.orderadd-container .main {
							height:calc(100% - 70px - 41px) !important;
						}
						.orderadd-container .main .left-side .left-side-main.left-side-main-tab0 {
							height:calc(100% - 70px - 41px) !important;
							overflow:hidden;
						}
						.orders-container .main {
							height:calc(100% - 165px - 41px) !important;
						}
						.orders-container-archive .main {
							height:calc(100% - 165px - 41px) !important;
						}
					`}
				</style> : null}
		</div>;
};
export default Template;