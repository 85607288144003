import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import Accordion from '../../../Components/Accordion';

// models
import {Roles} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { permissionTypeName } from '../../../Globals/Constants';

// images
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckOff from './Images/checkbox-off.svg';


// start
const RolesScreen = () => {
	const [loading, setLoading] = useState(true);
	const [rolesAll, setRolesAll] = useState([]);
	const [roles, setRoles] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:code, setValue:setCode, bind:bindCode} = useInput('');
	const [permissions, setPermissions] = useState([]);
	const [fullAccess, setFullAccess] = useState(false);
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await rolesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const rolesGet = async () => {
		const roles = await Roles.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (roles === undefined) return;
		setRoles(roles);
		setRolesAll(roles);
	};
	const roleAdd = () => show();
	const roleEdit = (role) => {
		role = role || roles.find(f => f._id === id);
		show(role);
	};
	const roleShow = (role) => show(role);
	const show = (role) => {
		setId(role?._id||null);
		setName(role?.name||'');
		setDescription(role?.description||'');
		setCode(role?.code||'');
		setPermissions(role?.permissions||[]);
		setFullAccess(role?.fullAccess||false);
	};
	const cancel = () => roleShow(null);
	const save = async () => {
		const data = {
			name:name,
			description:description,
			code:code,
			permissions:permissions,
			fullAccess:fullAccess
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название роли');
			return;
		}
		if (Utils.empty(data.code)) {
			errorShow('Необходимо заполнить код роли');
			return;
		}
		if (!data.fullAccess) {
			if (data.permissions === null || data.permissions.length === 0) {
				errorShow('Для ролей без полного доступа необходимо установить права');
				return;
			}
		}
		const res = id ?
				await Roles.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Roles.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			rolesGet();
			cancel();
			return true;
		}
	}
	const searchRole = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setRoles(rolesAll);
		else setRoles(rolesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const roleDelete = (id) => {
		Roles.remove(id).then((res) => {
			successShow(res.message);
			rolesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const codeCreate = (e) => {
		const name = e.target.value;
		setCode(Utils.translit(name));
		setName(name);
	}
	const permissionSet = (id) => {
		if (permissions.includes(id)) setPermissions(permissions.filter(f => f !== id));
		else setPermissions([...permissions, id]);
	};
	const isInPermission = (id) => permissions.includes(id);
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = roles.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					description:s.description,
					code:s.code,
					permissions:s.permissions,
					fullAccess:s.fullAccess
				};
				await Roles.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		rolesGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await Roles.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		rolesGet();
		cancel();
		return true;
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Роли"
							type="books"
							data={roles}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'}
							]}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первую<br/>роль</>}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<div className="product-view-row product-view-row-simple">
									<h4>Права доступа</h4>
									{permissions.map((v,i) => <div key={i} className="permission-item">
										{permissionTypeName.find(f => f.id === v)?.name}
									</div>)}
								</div>
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название роли" className="input-title" autoFocus={true} onChange={codeCreate} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
								</div>
								<Accordion title="Права доступа">
									<div className="product-edit-row">
										{permissionTypeName.map((v,i) => <div key={i} className="permission-item" onClick={() => permissionSet(v.id)}>
											<img src={isInPermission(v.id)?imgCheckOn:imgCheckOff} alt="" />
											{v.name}
										</div>)}
									</div>
								</Accordion>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="code" className="label-middle">Код роли</label>
									<input id="code" {...bindCode} type="text" placeholder="Код роли" />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label>Полный доступ</label>
									<img src={fullAccess ? imgCheckOn : imgCheckOff} alt="" onClick={() => setFullAccess(!fullAccess)} />
								</div>
							</>}
							onDelete={roleDelete}
							onEdit={roleEdit}
							onAdd={roleAdd}
							onSave={save}
							onSearch={searchRole}
							onShow={roleShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default RolesScreen;