import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {Loyalities} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Orfo from '../../../Globals/Orfo';

// constants
import { commonStatus, commonStatusName } from '../../../Globals/Constants';

// images
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';


// start
const LoyalitiesScreen = () => {
	const [loading, setLoading] = useState(true);
	const [loyalties, setLoyalities] = useState(null);
	const [loyaltiesAll, setLoyalitiesAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:discount, setValue:setDiscount, bind:bindDiscount} = useInput('');
	const {value:bonuses, setValue:setBonuses, bind:bindBonuses} = useInput('');
	const {value:days, setValue:setDays, bind:bindDays} = useInput('30');
	const {value:orders, setValue:setOrders, bind:bindOrders} = useInput('');
	const {value:amounts, setValue:setAmounts, bind:bindAmounts} = useInput('');
	const [freeDelivery, setFreeDelivery] = useState(false);
	const [isFirstOrder, setIsFirstOrder] = useState(false);
	const [search, setSearch] = useState(null);
	const [status, setStatus] = useState(commonStatus.ACTIVE);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await loyalitiesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const loyalitiesGet = async () => {
		const loyalties = await Loyalities.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (loyalties === undefined) return;
		setLoyalities(loyalties);
		setLoyalitiesAll(loyalties);
	};
	const loyaltyAdd = () => show();
	const loyaltyEdit = (loyalty) => {
		loyalty = loyalty || loyalties.find(f => f._id === id);
		show(loyalty);
	};
	const loyaltyShow = (loyalty) => show(loyalty);
	const show = (loyalty) => {
		setId(loyalty?._id||null);
		setName(loyalty?.name||'');
		setDescription(loyalty?.description||'');
		setDiscount(loyalty?loyalty.rules.discount:'');
		setBonuses(loyalty?loyalty.rules.bonuses:'');
		setDays(loyalty?loyalty.properties.days:'');
		setFreeDelivery(loyalty?loyalty.rules.freeDelivery:false);
		setIsFirstOrder(loyalty?loyalty.options.isFirstOrder:false);
		setOrders(loyalty?loyalty.options.orders:'');
		setAmounts(loyalty?loyalty.options.amounts:'');
		setStatus(loyalty?loyalty.status:commonStatus.ACTIVE);
	};
	const cancel = () => loyaltyShow(null);
	const save = async () => {
		const data = {
			name:name,
			description:description,
			discount:discount,
			bonuses:bonuses,
			days:days,
			freeDelivery:freeDelivery,
			orders:orders,
			amounts:amounts,
			isFirstOrder:isFirstOrder,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название программы лояльности');
			return;
		}
		if (Utils.empty(data.discount) && Utils.empty(data.bonuses) && !data.freeDelivery) {
			errorShow('Необходимо заполнить хотя бы одно правило');
			return;
		}
		if (!Utils.empty(data.discount) && (data.discount < 0 || data.discount > 100)) {
			errorShow('Скидка должна быть от 0 до 100');
			return;
		}
		if (Utils.empty(data.orders) && Utils.empty(data.amounts) && !data.isFirstOrder) {
			errorShow('Необходимо заполнить хотя бы одно условие');
			return;
		}
		const res = id ?
				await Loyalities.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Loyalities.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			loyalitiesGet();
			cancel();
			return true;
		}
	}
	const searchLoyality = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setLoyalities(loyaltiesAll);
		else setLoyalities(loyaltiesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const loyaltyDelete = (id) => {
		Loyalities.remove(id).then((res) => {
			successShow(res.message);
			loyalitiesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Прог.лояльности"
							type="marketing"
							data={loyalties}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'},
								{title:'Скидка',field:'rules.discount',class:'weight',func:(v) => `${v.rules.discount}%`},
								{title:'Статус',field:'status',class:'status status-small',func:statusGet}
							]}
							empty={<>Добавьте первый статус<br/>программы лояльности</>}
							contentShowTitle={name}
							contentShow={<>
								{description ? 
									<div className="product-view-row-simple">
										{description}
									</div> : null}
								<h4>Правила</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Скидка</span>
									<div>{discount?`${discount}%`:'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Кол-во бонусов</span>
									<div>{bonuses?`${bonuses} ₽`:'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Бесплатная доставка</span>
									<div>{freeDelivery?'Да':'Нет'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Обновление 1 раз в</span>
									<div>{Orfo.counters.days(days)}</div>
								</div>
								<h4>Условия</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Минимальное кол-во заказов</span>
									<div>{orders?`${orders} ₽`:'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Минимальная сумма заказа</span>
									<div>{amounts?`${amounts} ₽`:'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Первый заказ</span>
									<div>{isFirstOrder?'Да':'Нет'}</div>
								</div>
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription}></textarea>
								</div>
								<h4>Правила</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="discount" className="label-middle">Процент скидки</label>
									<input id="discount" {...bindDiscount} type="text" placeholder="0" maxLength={2} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="bonuses" className="label-middle">Кол-во бонусов</label>
									<input id="bonuses" {...bindBonuses} type="text" placeholder="0" maxLength={5} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label className="label-middle">Бесплатная доставка</label>
									<img src={freeDelivery ? imgCheckOn : imgCheckOff} alt="" onClick={() => setFreeDelivery(!freeDelivery)} />
								</div>
								<h4>Условия</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label className="label-middle">Первый заказ</label>
									<img src={isFirstOrder ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsFirstOrder(!isFirstOrder)} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="orders" className="label-middle">Кол-во заказов</label>
									<input id="orders" {...bindOrders} type="text" placeholder="0" maxLength={4} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="amount" className="label-middle">Сумма заказов</label>
									<input id="amount" {...bindAmounts} type="text" placeholder="0" maxLength={5} />
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="days" className="label-middle">Период обновленя, дней</label>
									<input id="days" {...bindDays} type="text" placeholder="0" maxLength={2} required />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleStatus} value={status} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</>}
							onDelete={loyaltyDelete}
							onEdit={loyaltyEdit}
							onAdd={loyaltyAdd}
							onSave={save}
							onSearch={searchLoyality}
							onShow={loyaltyShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default LoyalitiesScreen;