
const counters = {
	months		: (count) => counterGet(count, ['месяц','месяца','месяцев']),
	days		: (count) => counterGet(count, ['день','дня','дней']),
	years		: (count) => counterGet(count, ['год','года','лет']),
	products	: (count) => counterGet(count, ['товар','товара','товаров']),
	components	: (count) => counterGet(count, ['компонент','компонента','компонентов']),
	groups		: (count) => counterGet(count, ['группа','группы','групп']),
	tags		: (count) => counterGet(count, ['ярлык','ярлыка','ярлыков'])
};
const counterGet = (count, titles) => `${count} ${titles[(count % 100 > 4 && count % 100 < 20) ? 2 : [2,0,1,1,1,2][(count % 10 < 5) ? count % 10 :5 ]]}`


export default {
	counters
};