import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()			=> await Http.request('productoptions');

const add		= async (data)		=> await Http.request('productoption', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`productoption/${id}`, httpMethods.PUT, data);

const remove	= async (id)		=> await Http.request(`productoption/${id}`, httpMethods.DELETE);

const image = {
	add			: async (id, data)	=> await Http.request(`productoption/image/${id}`, httpMethods.POST, data),
	copy		: async (id, id2)	=> await Http.request(`productoption/image/copy/${id}/${id2}`, httpMethods.POST)
};


export {
	get,
	add,
	update,
	remove,
	image
};