import React, {useEffect,useState} from 'react';

// components
import ComponentAdd, { componentAddShow } from '../ComponentAdd';

// helpers
import Utils from '../../Globals/Utils';

// constants
import { measureType, measureTypeName, measureTypeSmallName } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import imgDelete from './Images/delete-grey.svg';


const ComponentInline = (props) => {
	const item = {name:'',price:'',quantity:'',measure:''};
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [dataAll, setDataAll] = useState([]);
	const [items, setItems] = useState([]);
	const [index, setIndex] = useState(null);
	const [search, setSearch] = useState(null);
	const [isNameFocus, setIsNameFocus] = useState(false);
	const [show, setShow] = useState(props.isShow||false);
	useEffect(async () => {
		const data = props.data.components ? Utils.deepCopy(props.data.components) : []	;
		data.forEach((v) => {
			v.id = v._id;
			v.price = v.prices.manual||v.prices.invoice
		});
		if (props.data.semifinishes) {
			const p = [];
			props.data.semifinishes.forEach((v) => {
				p.push({
					id:v._id,
					name:v.name,
					price:v.total,
					quantity:v.properties.brutto,
					weight:v.properties.brutto,
					measure:v.properties.measure,
					total:v.total,
					isproduct:false,
					issemifinish:true
				});
			});
			data.push(...p);
		}
		if (props.data.products) {
			const p = [];
			props.data.products.forEach((v) => {
				p.push({
					id:v._id,
					name:v.name,
					price:v.prices.price,
					quantity:1,
					measure:measureType.PIECES,
					total:v.prices.price,
					isproduct:true,
					issemifinish:false
				});
			});
			data.push(...p);
		}
		setData(data);
		setDataAll(data);
		setLoading(false);
	}, []);
	useEffect(() => {
		const items = props.selected;
		if (items.length === 0) setItems([item]);
		else setItems([...props.selected]);
	}, [props.selected]);
	const switcher = () => setShow(!show);
	const quantityHandle = (e, i, v) => fieldUpdate(i, e, v, 'quantity');
	const priceHandle = (e, i, v) => fieldUpdate(i, e, v, 'price');
	const totalHandle = (e, i, v) => fieldUpdate(i, e, v, 'total');
	const fieldUpdate = (i, e, v, field) => {
		const value = e.target.value?.replace(',', '.');
		if (!/^[0-9.,]*$/.test(value)) return;
		items[i][field] = value;
		if (field === 'total') {
			if (Utils.empty(value)) items[i].total = '';
			else items[i].total = value;
			if (v.issemifinish) {
				const q = ((value * v.weight) / items[i].price);
				if (isNaN(q)) items[i].quantity = '';
				else items[i].quantity = q.toFixed(2);
			} else {	
				const p = items[i].total / items[i].quantity;
				if (isNaN(p)) items[i].price = '';
				else items[i].price = p.toFixed(2);
			}
		} else {
			if (Utils.empty(value)) items[i][field] = '';
			else items[i][field] = value;
			if (v.issemifinish) {
				const t = (items[i].quantity * items[i].price) / items[i].weight;
				if (isNaN(t)) items[i].total = '';
				else items[i].total = t.toFixed(2);
			} else {
				const t = items[i].price * items[i].quantity;
				if (isNaN(t)) items[i].total = '';
				else items[i].total = t.toFixed(2);
			}
		}
		update(items);
	};
	const nameHandle = (e, i) => {
		if (i !== index) return;
		const search = e.target.value;
		if (Utils.empty(search)) setData(dataAll);
		else {
			let res = dataAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase()));
			if (res.length === 0) {
				const s = Utils.keyboardLayout(search);
				res = dataAll.filter(f => f.name.toLowerCase().includes(s.toLowerCase()));
			}
			setData(res);
		}
		setSearch(search);
	};
	const nameFocusHandle = (e, i) => {
		setIndex(i);
		setSearch(e.target.value);
		setIsNameFocus(true);
	};
	const countFocusHandle = (e) => e.target.select();
	const nameBlurHandle = () => clear();
	const add = () => update([...items,item]);
	const remove = (idx) => update(items.filter((v,i) => i !== idx));
	const update = (items) => {
		setItems(items);
		props.onUpdate(items);
		clear();
	};
	const totalCalc = (v) => v.price * v.quantity;
	const select = (v, i) => {
		const item = {
			id:v.id,
			name:v.name,
			price:v.price,
			quantity:v.issemifinish?0:1,
			measure:v.isproduct?measureType.PIECES:v.measure,
			total:v.price,
			weight:v.weight,
			isproduct:v.isproduct,
			issemifinish:v.issemifinish
		};
		item.total = totalCalc(item);
		items[i||index] = item;
		update(items);
	}
	const clear = () => {
		setIsNameFocus(false);
		setIndex(null);
		setSearch(null);
		setData(dataAll);
	};
	const allCalc = () => {
		const total = items.reduce((acc, v) => acc + (v.issemifinish ? parseFloat(v.total) : totalCalc(v)), 0);
		return isNaN(total) ? 0 : total.toFixed(2);
	};
	const addComponent = (e) => {
		e.preventDefault();
		componentAddShow(Utils.keyboardLayout(search), {index});
	};
	const componentInsert = async (component, dataProps) => {
		component.id = component._id;
		component.price = component.prices.manual||component.prices.invoice;
		const d = [...data,component]
		setData(d);
		setDataAll(d);
		select(component, dataProps.index);
	};
	return loading ? null :
		<>
			<div className={`component-inline${show?'':' component-inline-hide'}`}>
				<div className="component-inline-title" onClick={switcher}>
					<div className="component-inline-title-title">
						<h4>{props.title}</h4>
						{show ? props.notice : (props.info ? <div className="info">{props.info}</div> : null)}
					</div>
					<div className="component-inline-arrow"></div>
				</div>
				<div className="components-edit">
					<table className="components">
						<thead>
							<tr>
								<th className="name">Наименование</th>
								<th className="count">Кол-во</th>
								<th className="count">Цена</th>
								<th className="count">Сумма</th>
								<th className="delete"></th>
							</tr>
						</thead>
						<tbody>
							{items.map((v,i) => <tr key={i}>
									<td className="name">
										<input type="search" value={isNameFocus&&i===index?search:v.name} onChange={(e) => nameHandle(e, i)} onFocus={(e) => nameFocusHandle(e, i)} onBlur={nameBlurHandle} />
										{v && v.name ? <span className="measure">{v.issemifinish?measureTypeSmallName[v.measure]:measureTypeName[v.measure]}</span> : null}
										{isNameFocus && i === index ?
											<div className="components-list">
												{data.length ?
														data.map((v,i) => <div key={i} className="components-list-item" onMouseDown={() => select(v)}>{v.name} ({v.issemifinish?measureTypeSmallName[v.measure]:measureTypeName[v.measure]}){v.isproduct?<span>т</span>:null}{v.issemifinish?<span>п/ф</span>:null}</div>)
													:
														(props.allowAddComponent ?
																<div className="components-list-add" onMouseDown={addComponent}>Добавить <b>{Utils.keyboardLayout(search)}</b></div>
															:
																<div className="components-list-empty">Не найдено</div>
														)
												}
											</div> : null}
									</td>
									<td className="count"><input type="text" value={v.quantity} placeholder="0" onChange={(e) => quantityHandle(e, i, v)} onFocus={countFocusHandle} /></td>
									<td className="count"><input type="text" value={v.price} placeholder="0" onChange={(e) => priceHandle(e, i, v)} onFocus={countFocusHandle} disabled={props.isCountOnly} /></td>
									<td className="count"><input type="text" value={v.total} placeholder="0" onChange={(e) => totalHandle(e, i, v)} onFocus={countFocusHandle} disabled={props.isCountOnly}  /></td>
									<td className="delete"><img src={imgDelete} alt="" onClick={() => remove(i)} /></td>
								</tr>)}
							{items.length ?
									<tr className="total">
										<td colSpan="3">Итого</td>
										<td colSpan="2">{allCalc()}</td>
									</tr>
								: null}
						</tbody>
					</table>
					<div className="buttons">
						<button type="button" onClick={add}>＋ Добавить еще</button>
						{props.additionButtonComponent}
					</div>
				</div>
			</div>
			{props.allowAddComponent ? <ComponentAdd callback={componentInsert} /> : null}
		</>;
};

export default ComponentInline;