import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import Accordion from '../../../Components/Accordion';

// models
import {ComponentCategories, Components, Products} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Orfo from '../../../Globals/Orfo';

// constants
import { measureType, measureTypeName, measureTypeSmallName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgPlus from './Images/plus-black.svg';
import imgPlusButton from './Images/plus-btn.svg';
import imgArrowCurveDown from './Images/arrow-curve-down.svg';
import imgClose from './Images/close.svg';


// start
const ComponentsScreen = () => {
	const [ModalCategoryAdd, modalCategoryAddOpen, modalCategoryAddClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [tab, setTab] = useState(1);
	const [componentsAll, setComponentsAll] = useState([]);
	const [components, setComponents] = useState(null);
	const [products, setProducts] = useState([]);
	const [category, setCategory] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:weight, setValue:setWeight, bind:bindWeight} = useInput('');
	const {value:price, setValue:setPrice, bind:bindPrice} = useInput('');
	const [invoicePrice, setInvoicePrice] = useState('');
	const {value:minimum, setValue:setMinimum, bind:bindMinimum} = useInput('');
	const {value:requestCount, setValue:setRequestCount, bind:bindRequestCount} = useInput('');
	const [categoryId, setCategoryId] = useState(null);
	const [categories, setCategories] = useState([]);
	const {value:categoryName, setValue:setCategoryName, bind:bindCategoryName} = useInput('');
	const [search, setSearch] = useState(null);
	const [componentMeasureType, setComponentMeasureType] = useState('');
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const category = await categoriesGet();
			await componentsGet(category);
			await productsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const componentsGet = async (c) => {
		const components = await Components.get.store().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (components === undefined) return;
		setComponents(components);
		setComponentsAll(components);
		componentFilter(components, c||category);
	};
	const productsGet = async () => {
		const products = await Products.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		setProducts(products);
	};
	const categoriesGet = async () => {
		const categories = await ComponentCategories.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setCategories(categories);
		return category;
	};
	const componentFilter = (components, category) => {
		if (category) {
			const c = components.filter(f => f.category.id === category._id);
		 	setComponents(c);
		}
	}
	const componentAdd = () => show();
	const componentEdit = (component) => {
		component = component || components.find(f => f._id === id);
		show(component);
	};
	const componentShow = (component) => show(component);
	const show = (component) => {
		setId(component?._id||null);
		setName(component?.name||'');
		setDescription(component?.description||'');
		setPrice(component?.prices.manual||'');
		setInvoicePrice(component?.prices.invoice||'');
		setMinimum(component?.options?.minimum||'');
		setRequestCount(component?.options?.requestCount||'');
		setWeight(component?.weight||'');
		setComponentMeasureType(component?.measure||null);
		setCategoryId(component?.category?.id||null);
	};
	const cancel = () => {
		componentShow(null);
		modalCategoryAddClose();
	}
	const save = async () => {
		const data = {
			categoryId:categoryId,
			name:name,
			description:description,
			price:price,
			requestCount:requestCount,
			minimum:minimum,
			weight:weight,
			measure:componentMeasureType
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название компонента');
			return;
		}
		if (data.measure === null) {
			errorShow('Необходимо заполнить единицу измерения компонента');
			return;
		}
		if (data.measure === measureType.PIECES && Utils.empty(data.weight)) {
			errorShow('Необходимо заполнить вес компонента');
			return;
		}
		const res = id ?
				await Components.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Components.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			componentsGet();
			cancel();
			return true;
		}
	}
	const handleMeasureType = (e) => setComponentMeasureType(parseInt(e.target.value));
	const searchComponent = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setComponents(componentsAll);
		else setComponents(componentsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const componentDelete = (id) => {
		Components.remove(id).then((res) => {
			successShow(res.message);
			componentsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const handleCategory = (e) => setCategoryId(e.target.value);
	const categoryAddShow = (category) => {
		setCategoryName(category?.name||'');
		modalCategoryAddOpen();
	}
	const categorySave = async () => {
		if (Utils.empty(categoryName)) return;
		const data = {name:categoryName};
		const res = await ComponentCategories.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			categoriesGet();
			modalCategoryAddClose();
		}
	}
	const categorySelect = (cat) => {
		if (category) {
			if (category._id === cat._id) return categorySelectAll();
		}
		const components = componentsAll.filter(f => f.category.id === cat._id);
		setCategory(cat);
		setComponents(components);
	}
	const categorySelectAll = () => {
		setCategory(null);
		setComponents(componentsAll);
	}
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = components.find(f => f._id === v);
			if (s) {
				const data = {
					categoryId:s.categoryId,
					name:`${s.name} (копия)`,
					description:s.description,
					price:s.price,
					requestCount:s.requestCount,
					minimum:s.minimum,
					weight:s.weight,
					measure:s.componentMeasureType
				};
				await Components.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		componentsGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await Components.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		componentsGet();
		cancel();
		return true;
	};
	const tabSet = (tab) => setTab(tab);
	const tabsBlock = <div className="categories-tabs">
		<div className="tabs">
			<div className={`tab${tab===0?' active':''}`} onClick={() => tabSet(0)}>Разделы</div>
			<div className={`tab${tab===1?' active':''}`} onClick={() => tabSet(1)}>Категории</div>
		</div>
	</div>;
	const usedProductsGet = (id) => {
		const out = [];
		const component = components.find(f => f._id === id);
		if (component) {
			products.forEach((v) => {
				const used = v.components.find(f => f.id === id);
				if (used) {
					out.push({
						name:v.name,
						weight:used.weight,
						measure:used.measure
					});
				}
			});
		}
		return out;
	};
	const totalSumGet = () => components.reduce((a,v) => a + v.balance.total, 0).toFixed(2);
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Компоненты"
							type="books"
							data={components}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'},
								{title:'Цена ручн.',field:'manual',class:'prices',func:(v) => v.prices?.manual||'–',sort:'prices.manual'},
								{title:'Цена накл.',field:'inv',class:'prices',func:(v) => v.prices?.invoice||'–',sort:'prices.invoice'},
								{title:'Остатки',field:'counts',class:'weight',func:(v) => v.balance.balance ? `${v.balance.balance} ${measureTypeName[v.measure]}` : '0',sort:'balance.balance'},
								{title:'Мин.остаток',field:'prices',class:'weight',func:(v) => v.options?.minimum ? `${v.options.minimum} ${measureTypeName[v.measure]}` : '–',sort:'options.minimum'},
								{title:'Сумма',field:'total',class:'prices prices-mid',func:(v) => v.balance.total.toFixed(2),sort:'balance.total'},
							]}
							rowsShort={['name','manual','inv']}
							rowsFooter={<>
								<tr className="info-row">
									<td colSpan="4">Итого</td>
									<td className="prices">{totalSumGet()}</td>
									<td colSpan="2"></td>
								</tr>
							</>}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первый<br/>компонент</>}
							contentTabs={tab === 0 ? tabsBlock : null}
							contentSideMenu={tab === 1 ?
									<>
										<div className="categories-list-container">
											{tabsBlock}
											<div className="container-inner">
												<div className="list">
													<div className={`category${category?'':' category-select'}`} onClick={() => categorySelectAll()}>
														Все категории <i>{componentsAll.length}</i>
													</div>
													{categories.length ?
															categories.map((v,i) => <div key={i} className={`category${v._id===category?._id?' category-select':''}`} onClick={() => categorySelect(v)}>
																<div>
																	{v.name}
																	<i>{componentsAll.filter(f => f.category.id === v._id).length}</i>
																</div>
																<span className="category-edit" onClick={() => categoryAddShow(v)}></span>
															</div>)
														:
															<div className="category-empty">
																<div>
																	Здесь можно добавить новые категории
																</div>
																<img src={imgArrowCurveDown} alt="" />
															</div>
													}
												</div>
											</div>
											<div className="categories-list-footer">
												<button type="button" onClick={() => categoryAddShow()}>
													<img src={imgPlus} alt="" />
													Добавить категорию
												</button>
											</div>
										</div>
									</>
								: null}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<div className="product-view-row product-view-row-oneline">
									<span>Объем / Вес</span>
									<div>{weight} {measureTypeName[componentMeasureType]}</div>
								</div>
								<h4>Цена</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Цена из последней накладной</span>
									<div>{invoicePrice?`${invoicePrice} ₽`:'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Цена (коррекция)</span>
									<div>{price?`${price} ₽`:'–'}</div>
								</div>
								<h4>Складской учет</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Минимальный остаток на складе</span>
									<div>{minimum?`${minimum} ${measureTypeName[componentMeasureType]}`:'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Количество для заявки по умолчанию</span>
									<div>{requestCount?`${requestCount} ${measureTypeName[componentMeasureType]}`:'–'}</div>
								</div>
								{usedProductsGet(id).length ?
									<Accordion title={'Используется в товарах'} info={Orfo.counters.products(usedProductsGet(id).length)}>
										{usedProductsGet(id).map((v,i) => <div key={i} className="product-list">
											<span>{v.name}</span>
											<div>{v.weight} {measureTypeSmallName[v.measure]}</div>
										</div>)}
									</Accordion> : null}
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название компонента" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
								</div>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
									<div className="select select-wide">
										<select onChange={handleCategory} value={categoryId} required>
											<option value="">Выберите категорию</option>
											{categories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
									<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => categoryAddShow()} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="measure">Единица измерения</label>
									<div className="select">
										<select onChange={handleMeasureType} value={componentMeasureType} required>
											{measureTypeName.map((v,i) => <option key={i} value={i===0?'':i}>{v}</option>)}
										</select>
									</div>
								</div>
								{componentMeasureType === measureType.PIECES ?
										<div className="product-edit-row product-edit-row-oneline">
											<label htmlFor="weight" className="label-middle">Вес компонента в граммах</label>
											<input id="weight" {...bindWeight} type="text" placeholder="0" maxLength={4} required />
										</div>
									: null}
								<Accordion title="Цена">
									<div className="product-edit-row product-edit-row-oneline">
										<label className="label-middle">Цена из последней накладной</label>
										<b>{invoicePrice?`${invoicePrice} ₽`:'–'}</b>
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="price" className="label-middle">Цена (коррекция), ₽</label>
										<input id="price" {...bindPrice} type="text" placeholder="0" maxLength={6} />
									</div>
								</Accordion>
								<Accordion title="Складской учет">
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="minimum" className="label-middle">Минимальный остаток на складе</label>
										<input id="minimum" {...bindMinimum} type="text" placeholder="0" maxLength={4} />
									</div>
									<div className="product-edit-row product-edit-row-oneline">
										<label htmlFor="requestcount" className="label-middle">Количество для заявки по умолчанию</label>
										<input id="requestcount" {...bindRequestCount} type="text" placeholder="0" maxLength={4} />
									</div>
								</Accordion>
							</>}
							onDelete={componentDelete}
							onEdit={componentEdit}
							onAdd={componentAdd}
							onSave={save}
							onSearch={searchComponent}
							onShow={componentShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalCategoryAdd>
				<div className="modal modal-component-add">
					<div className="header">
						<h4>Добавить категорию</h4>
						<img src={imgClose} alt="" onClick={modalCategoryAddClose} className="btn-close" />
					</div>
					<input type="text" {...bindCategoryName} placeholder="Название категории" autoFocus={true} />
					<div className="buttons">
						<button type="button" onClick={modalCategoryAddClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={categorySave} className="btn-accept">Сохранить</button>
					</div>
				</div>
			</ModalCategoryAdd>
		</>
	);
};

export default ComponentsScreen;