import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {Sources} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';


// start
const SourcesScreen = () => {
	const [loading, setLoading] = useState(true);
	const [sourcesAll, setSourcesAll] = useState([]);
	const [sources, setSources] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:url, setValue:setUrl, bind:bindUrl} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await sourcesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const sourcesGet = async () => {
		const sources = await Sources.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (sources === undefined) return;
		setSources(sources);
		setSourcesAll(sources);
	};
	const sourceAdd = () => show();
	const sourceEdit = (source) => {
		source = source || sources.find(f => f._id === id);
		show(source);
	};
	const sourceShow = (source) => show(source);
	const show = (source) => {
		setId(source?._id||null);
		setName(source?.name||'');
		setUrl(source?.url||'');
		setDescription(source?.description||'');
	};
	const cancel = () => sourceShow(null);
	const save = async () => {
		const data = {
			name:name,
			url:url,
			description:description
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название источника');
			return;
		}
		const res = id ?
				await Sources.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Sources.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			sourcesGet();
			cancel();
			return true;
		}
	}
	const searchSource = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setSources(sourcesAll);
		else setSources(sourcesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const sourceDelete = (id) => {
		Sources.remove(id).then((res) => {
			successShow(res.message);
			sourcesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	};
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = sources.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					url:s.url,
					description:s.description
				};
				await Sources.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		sourcesGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await Sources.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		sourcesGet();
		cancel();
		return true;
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Источники заказов"
							type="books"
							data={sources}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name',subfield:'url',class:''}
							]}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первую<br/>причину списания</>}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<div className="product-view-row product-view-row-simple">
									<a href={url} target="_blank" rel="noreferrer">{url}</a>
								</div>
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название источника" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<input type="text" placeholder="URL" {...bindUrl} />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
								</div>
							</>}
							onDelete={sourceDelete}
							onEdit={sourceEdit}
							onAdd={sourceAdd}
							onSave={save}
							onSearch={searchSource}
							onShow={sourceShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default SourcesScreen;