import React, {useEffect,useState} from 'react';

// constants
import { measureType } from '../../Globals/Constants';


const ComponentList = (props) => {
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		setLoading(false);
	}, []);
	const totalGet = () => props.items.reduce((acc, v) => acc + v.total, 0);
	return loading ? null :
		props.items.length ?
			<>
				<h4>{props.title||'Состав'}</h4>
				<div className="product-edit-components-block">
					{props.items.map((v,i) => <div key={i} className="product-edit-component-item">
						<div className="product-edit-component-item-name">
							<b>{v.name} • <span>{v.total}₽</span></b>
							{v.issemifinish ? <span className="type">п/ф</span> : null}
							{v.isproduct ? <span className="type">товар</span> : null}
						</div>
						<div className="info">
							<div>{v.measure === measureType.PIECES ? `кол-во: ${v.pieces}шт • ` : null}брутто: {v.brutto}г • нетто: {v.netto}г • потери: {v.loss}г</div>
						</div>
					</div>)}
					<div className="product-edit-row">
						<div><b>Итого: {totalGet()} ₽</b></div>
					</div>
				</div>
			</> : null;
};

export default ComponentList;