import React, {useEffect,useState} from 'react';

// plug-ins
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import ComponentInline from '../../../Components/ComponentInline';
import ComponentPositionList from '../../../Components/ComponentPositionList';
import ComponentPopupStorage from '../../../Components/ComponentPopupStorage';

// models
import {Products, Releases, Semifinishes, Storages} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';


// start
const ReleasesScreen = () => {
	const [loading, setLoading] = useState(true);
	const [releasesAll, setReleasesAll] = useState([]);
	const [releases, setReleases] = useState(null);
	const [storages, setStorages] = useState([]);
	const [storagesAll, setStoragesAll] = useState([]);
	const [id, setId] = useState(null);
	const [name, setName] = useState(null);
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:date, setValue:setDate, bind:bindDate} = useInput('');
	const [storageId, setStorageId] = useState(null);
	const [items, setItems] = useState([]);
	const [inventory, setInventory] = useState([]);
	const [products, setProducts] = useState([]);
	const [semifinishes, setSemifinishes] = useState([]);
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const point = ls('point');
			await storagesGet(point.id);
			await productsGet();
			await semifinishesGet();
			await releasesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const storagesGet = async (pointId) => {
		const storages = await Storages.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (storages === undefined) return;
		const s = storages.filter(f => f.points.filter(f => f.id === pointId).length !== 0);
		setStorages(s);
		setStoragesAll(storages);
	};
	const releasesGet = async () => {
		const releases = await Releases.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (releases === undefined || releases.length === 0) {
			setReleases(null);
			return;
		}
		setReleases(releases);
		setReleasesAll(releases);
	};
	const semifinishesGet = async () => {
		const semifinishes = await Semifinishes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (semifinishes === undefined) return;
		setSemifinishes(semifinishes);
	};
	const productsGet = async () => {
		const products = await Products.get.store().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		setProducts(products);
	};
	const releaseAdd = () => show();
	const releaseEdit = (release) => {
		release = release || releases.find(f => f._id === id);
		show(release);
	};
	const releaseShow = (release) => show(release);
	const show = (release) => {
		setId(release?._id||null);
		setName(release ? moment(release.date).format('DD.MM.YYYY HH:mm') : '');
		setDate(Utils.dateTimeNormalize(release, 'date'));
		setDescription(release?.description||'');
		setStorageId(release?.storage?.id||null);
		setItems(release?.items||[]);
		setInventory(release?.inventory||null);
	};
	const cancel = () => releaseShow(null);
	const save = async () => {
		const data = {
			date:date,
			description:description,
			storageId:storageId,
			total:calcTotalGet(),
			items:items
		};
		if (Utils.empty(data.date)) {
			errorShow('Необходимо заполнить дату');
			return;
		}
		if (Utils.empty(data.storageId)) {
			errorShow('Необходимо выбрать склад');
			return;
		}
		if (items.length === 0) {
			errorShow('Необходимо добавить товары');
			return;
		}
		const res = id ?
				await Releases.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Releases.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			releasesGet();
			cancel();
			return true;
		}
	}
	const handleStorage = (e) => setStorageId(e.target.value);
	const searchRelease = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setReleases(releasesAll);
		else setReleases(releasesAll.filter(f => f.date.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const releaseDelete = (id) => {
		Releases.remove(id).then((res) => {
			successShow(res.message);
			releasesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = releases.find(f => f._id === v);
			if (s) {
				const data = {
					date:Utils.dateTimeNormalize(null, 'date'),
					description:s.description,
					storageId:s.storage.id,
					items:s.items
				};
				await Releases.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		releasesGet();
		cancel();
		return true;
	};
	const calcTotalGet = () => items.reduce((acc, v) => acc += parseFloat(v.total), 0).toFixed(2);
	const totalSumGet = () => releases ? releases.reduce((acc, v) => acc + (v.total ? parseFloat(v.total) : 0), 0).toFixed(2) : 0;
	const componentChange = (items) => setItems([...items]);
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Выпуск продукции"
							type="warehouse"
							data={releases}
							id={id}
							search={search}
							rows={[
								{title:'Дата',field:'date',func:(v) => <>
									{moment(v.date).format('DD.MM.YYYY HH:mm')}
									{v.description ? <span>{v.description}</span> : null}
								</>},
								{title:'Склад',field:'storage',class:'area',sort:'storage.name',func:(v) => v.storage ? v.storage.name : '–'},
								{title:'Сумма',field:'total',class:'prices'}
							]}
							rowsShort={['date']}
							rowsFooter={<>
								<tr className="info-row">
									<td colSpan="2">Итого</td>
									<td className="prices">{totalSumGet()}</td>
									<td colSpan="2"></td>
								</tr>
							</>}
							footerItems={[
								{title:'Сделать копию',action:massCopy}
							]}
							empty={<>Добавьте первый<br/>выпуск продукции</>}
							contentShowTitle={name}
							contentShow={<>
								{inventory ?
									<div className="product-view-row product-view-row-alert">
										Этот выпуск нельзя изменить или удалить! Он используется в инвентаризации от {moment(inventory.date).format('DD.MM.YYYY HH:mm')}
									</div>
								: null}
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<div className="product-view-row product-view-row-oneline">
									<span>Дата</span>
									<div>{moment(date).format('DD.MM.YYYY HH:mm')}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Склад</span>
									<div>{storagesAll.find(f => f._id === storageId)?.name||'–'}</div>
								</div>
								{inventory ?
									<div className="product-view-row product-view-row-oneline">
										<span>Инвентаризация от</span>
										<div>{moment(inventory.date).format('DD.MM.YYYY HH:mm')}</div>
									</div> : null}
								<ComponentPositionList items={items} />
							</>}
							contentEdit={<>
								<div className="product-edit-row product-edit-row-oneline product-edit-row-first">
									<label htmlFor="date" className="label-middle">Дата</label>
									<input id="date" {...bindDate} type="datetime-local" required />
								</div>
								<div className="product-edit-row">
									<div className="select select-wide">
										<select onChange={handleStorage} value={storageId} required>
											<option value="">Выберите склад</option>
											{storages.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Примечание" {...bindDescription} className="lite"></textarea>
								</div>
								<ComponentInline
									title="Позиции"
									data={{semifinishes,products}}
									selected={items}
									isShow={items.length===0}
									isCountOnly={true}
									onUpdate={componentChange}
									info={<>
										{items.length ?
											items.map((v,i) => (v.name)).join(', ') : null}
									</>}
									notice={<>
										<p className="row-notice">
											Добавить можно только товары, имеющие складской остаток. 
											Сейчас таких товаров {products.length||'нет'} шт.
										</p>
									</>}
									additionButtonComponent={<>
										<ComponentPopupStorage title={'Найти позиции'} data={{semifinishes,products}} selected={items} allow={['semifinish','product']} isCountOnly={true} onUpdate={componentChange} />
									</>} />
							</>}
							deleteExpression={(v) => !v.inventory}
							editExpression={(v) => !v.inventory}
							hideEditExpression={() => inventory}
							onDelete={releaseDelete}
							onEdit={releaseEdit}
							onAdd={releaseAdd}
							onSave={save}
							onSearch={searchRelease}
							onShow={releaseShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default ReleasesScreen;