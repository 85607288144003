import React, {useEffect,useState} from 'react';

// plug-ins
import InputMask from 'react-input-mask';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import PointsSelect from '../../../Components/PointsSelect';

// models
import {Points, Storages} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';


// start
const StoragesScreen = () => {
	const [loading, setLoading] = useState(true);
	const [points, setPoints] = useState([]);
	const [storagesAll, setStoragesAll] = useState([]);
	const [storages, setStorages] = useState(null);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:address, setValue:setAddress, bind:bindAddress} = useInput('');
	const {value:contact, setValue:setContact, bind:bindContact} = useInput('');
	const [storagePoints, setStoragePoints] = useState([]);
	const [search, setSearch] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await pointsGet();
			await storagesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const storagesGet = async () => {
		const storages = await Storages.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (storages === undefined || storages.length === 0) {
			setStorages(null);
			return;
		}
		setStorages(storages);
		setStoragesAll(storages);
	};
	const storageAdd = () => show();
	const storageEdit = (storage) => {
		storage = storage || storages.find(f => f._id === id);
		show(storage);
	};
	const storageShow = (storage) => show(storage);
	const show = (storage) => {
		setId(storage?._id||null);
		setName(storage?.name||'');
		setDescription(storage?.description||'');
		setPhone(storage?.contacts.phone||'');
		setContact(storage?.contacts.name||'');
		setAddress(storage?.address||'');
		setStoragePoints(storage?.points||[]);
		setStoragePoints(storage ? storage.points : points.map(v => ({id:v._id,name:v.name})));
	};
	const cancel = () => storageShow(null);
	const save = async () => {
		const data = {
			points:storagePoints,
			name:name,
			description:description,
			phone:phone,
			contact:contact,
			address:address
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название склада');
			return;
		}
		if (data.points.length === 0) {
			errorShow('Необходимо выбрать филиалы');
			return;
		}
		const res = id ?
				await Storages.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Storages.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			storagesGet();
			cancel();
			return true;
		}
	};
	const searchStorages = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setStorages(storagesAll);
		else setStorages(storagesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	};
	const storageDelete = (id) => {
		if (storagesAll.length === 1) {
			errorShow('Нельзя удалить последний склад');
			return;
		}
		Storages.remove(id).then((res) => {
			successShow(res.message);
			storagesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	};
	const pointGet = (item) => {
		const p = item.points;
		const out = [];
		if (p && p.length !== points.length) {
			for (let i = 0; i < p.length; i++) {
				out.push(points.find(f => f._id === p[i].id).name);
			}
		}
		return out.length === 0 ? 'Все филиалы' : out.join(', ');
	};
	const pointStorageSelect = (point) => {
		const p = storagePoints.find(f => f.id === point._id);
		if (p) setStoragePoints(storagePoints.filter(f => f.id !== point._id));
		else setStoragePoints([...storagePoints, {id:point._id,name:point.name}]);
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Склады"
							type="books"
							data={storages}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'},
								{title:'Филиал',field:'point',class:'area',func:pointGet}
							]}
							rowsShort={['name','point']}
							empty={<>Добавьте первый<br/>склад</>}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<PointsSelect isShow={true} selected={storagePoints} />
								{phone || address || contact ? <h4>Контакты</h4> : null}
								{phone ?
									<div className="product-view-row product-view-row-oneline">
										<span>Телефон</span>
										<div>{Utils.phoneFormatter(phone)}</div>
									</div> : null}
								{contact ?
									<div className="product-view-row product-view-row-oneline">
										<span>Контактное лицо</span>
										<div>{contact}</div>
									</div> : null}
								{address ?
									<div className="product-view-row product-view-row-oneline">
										<span>Адрес</span>
										<div>{address}</div>
									</div> : null}
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название склада" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription}></textarea>
								</div>
								<PointsSelect isEdit={true} points={points} selected={storagePoints} onSelect={pointStorageSelect} />
								<h4>Контакты</h4>
								<div className="product-edit-row">
									<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindPhone} />
								</div>
								<div className="product-edit-row">
									<input type="text" placeholder="Контактное лицо" {...bindContact} />
								</div>
								<div className="product-edit-row">
									<input type="text" placeholder="Адрес" {...bindAddress} />
								</div>
							</>}
							onDelete={storageDelete}
							onEdit={storageEdit}
							onAdd={storageAdd}
							onSave={save}
							onSearch={searchStorages}
							onShow={storageShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default StoragesScreen;