import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {Promocodes, Referrals} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { commonStatus, commonStatusName } from '../../../Globals/Constants';


// start
const ReferralsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [referrals, setReferrals] = useState(null);
	const [referralsAll, setReferralsAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:bonuses, setValue:setBonuses, bind:bindBonuses} = useInput('');
	const {value:sortOrder, setValue:setSortOrder, bind:bindSortOrder} = useInput('');
	const [promocode, setPromocode] = useState('');
	const [promocodes, setPromocodes] = useState([]);
	const [search, setSearch] = useState(null);
	const [status, setStatus] = useState(commonStatus.ACTIVE);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await promocodesGet();
			await referralsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const referralsGet = async () => {
		const referrals = await Referrals.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (referrals === undefined) return;
		setReferrals(referrals);
		setReferralsAll(referrals);
	};
	const promocodesGet = async () => {
		const promocodes = await Promocodes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (promocodes === undefined) return;
		setPromocodes(promocodes);
	};
	const referralAdd = () => show(null);
	const referralEdit = (referral) => {
		referral = referral || referrals.find(f => f._id === id);
		show(referral);
	};
	const referralShow = (referral) => show(referral);
	const show = (referral) => {
		setId(referral?._id||null);
		setName(referral?.name||'');
		setDescription(referral?.description||'');
		setBonuses(referral?.properties.bonuses||'');
		setPromocode(referral?.properties.promocode?.id||'');
		setSortOrder(referral?.options.sortOrder||'');
		setStatus(referral?referral.status:'');
	};
	const cancel = () => referralShow(null);
	const save = async () => {
		const data = {
			name:name,
			description:description,
			sortOrder:sortOrder,
			status:status
		};
		if (bonuses) data.bonuses = bonuses;
		if (promocode) data.promocode = promocode;
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название уровня');
			return;
		}
		if (Utils.empty(data.bonuses) && Utils.empty(data.promocode)) {
			errorShow('Необходимо заполнить бонусы или промокод');
			return;
		}
		const res = id ?
				await Referrals.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Referrals.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			referralsGet();
			cancel();
			return true;
		}
	}
	const searchReferral = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setReferrals(referralsAll);
		else setReferrals(referralsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const referralDelete = (id) => {
		Referrals.remove(id).then((res) => {
			successShow(res.message);
			referralsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Реф.программа"
							type="marketing"
							data={referrals}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'},
								{title:'Статус',field:'status',class:'status status-small',func:statusGet}
							]}
							empty={<>Добавьте первый<br/>уровень</>}
							contentShowTitle={name}
							contentShow={<>
								{description ?
									<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
								<div className="product-view-row product-view-row-oneline">
									<span>Бонусы для начисления</span>
									<b>{bonuses}</b>
								</div>
								{promocode ?
									<div className="product-view-row product-view-row-oneline">
										<span>Промокод</span>
										<b>{promocodes.find(f => f._id === promocode)?.name}</b>
									</div> : null}
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название уровня" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
								</div>
								<h4>Параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="discount" className="label-middle">Бонусы для<br/>начисления</label>
									<input type="text" {...bindBonuses} placeholder="0" />
								</div>
								<div className="product-edit-row">
									<div className="select select-wide">
										<select onChange={(e) => setPromocode(e.target.value)} value={promocode}>
											<option value="">Промокод</option>
											{promocodes.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
										</select>
									</div>
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="sortOrder" className="label-middle">Порядок сортировки</label>
									<input id="sortOrder" {...bindSortOrder} type="text" placeholder="0" maxLength={2} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleStatus} value={status} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</>}
							onDelete={referralDelete}
							onEdit={referralEdit}
							onAdd={referralAdd}
							onSave={save}
							onSearch={searchReferral}
							onShow={referralShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default ReferralsScreen;