import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {Labels, Pushes} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { notificationStatus, notificationStatusName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClose from './Images/close.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';


// start
const PushesScreen = () => {
	const [ModalLabels, modalLabelsOpen, modalLabelsClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [pushes, setPushes] = useState(null);
	const [pushesAll, setPushesAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:title, setValue:setTitle, bind:bindTitle} = useInput('');
	const {value:subTitle, setValue:setSubTitle, bind:bindSubTitle} = useInput('');
	const {value:text, setValue:setText, bind:bindText} = useInput('');
	const {value:start, setValue:setStart, bind:bindStart} = useInput('');
	const [includes, setIncludes] = useState([]);
	const [excludes, setExcludes] = useState([]);
	const [update, setUpdate] = useState('');
	const [labels, setLabels] = useState([]);
	const [labelsAll, setLabelsAll] = useState([]);
	const [labelsSelected, setLabelsSelected] = useState([]);
	const [labelDirection, setLabelDirection] = useState(null);
	const [status, setStatus] = useState('');
	const [search, setSearch] = useState('');
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await labelsGet();
			await pushesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const labelsGet = async () => {
		const labels = await Labels.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (labels === undefined) return;
		setLabels(labels);
		setLabelsAll(labels);
	};
	const pushesGet = async () => {
		const pushes = await Pushes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (pushes === undefined) return;
		setPushes(pushes);
		setPushesAll(pushes);
	};
	const pushAdd = () => show();
	const pushEdit = (push) => {
		push = push || pushes.find(f => f._id === id);
		show(push);
	};
	const pushShow = (push) => show(push);
	const show = (push) => {
		setId(push?._id||null);
		setName(push?push.name:'');
		setTitle(push?push.properties?.title:'');
		setSubTitle(push?push.properties?.subtitle:'');
		setText(push?push?.properties?.text:'');
		setStart(push && push.options && push.options.start ? Utils.dateTimeNormalize(push.options, 'start') : null);
		setIncludes(push?push.target.includes:[]);
		setExcludes(push?push.target.excludes:[]);
		setUpdate(Utils.dateTimeNormalize(push, 'update', false));
		setStatus(push?push.status:'');
	};
	const cancel = () => {
		pushShow(null);
		modalLabelsClose();
	}
	const save = async () => {
		const data = {
			name:name,
			title:title,
			subtitle:subTitle,
			text:text,
			start:start,
			includes,
			excludes
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название');
			return;
		}
		if (Utils.empty(data.title)) {
			errorShow('Необходимо заполнить заголовок');
			return;
		}
		if (Utils.empty(data.text)) {
			errorShow('Необходимо заполнить сообщение');
			return;
		}
		const res = id ?
				await Pushes.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Pushes.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			pushesGet();
			cancel();
			return true;
		}
	}
	const pushStart = async () => {
		const res = await Pushes.start(id).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			pushesGet();
			cancel();
		}
	}
	const searchPush = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setPushes(pushesAll);
		else setPushes(pushesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const labelsShow = (isinclude) => {
		setLabelsSelected((isinclude ? includes : excludes).map(m => labels.find(f => f._id === m)));
		setLabelDirection(isinclude);
		modalLabelsOpen();
	}
	const labelSelect = (label) => {
		const i = labelsSelected.findIndex(f => f._id === label._id);
		if (i === -1) setLabelsSelected([...labelsSelected, label]);
		else setLabelsSelected(labelsSelected.filter(f => f._id !== label._id));
	};
	const labelAdd = () => {
		const data = labelsSelected.map(m => m._id);
		if (labelDirection) setIncludes([...data]);
		else setExcludes([...data]);
		modalLabelsClose();
	};
	const searchLabel = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setLabels(labelsAll);
		else setLabels(labelsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	};
	const labelNameGet = (id) => labels.find(f => f._id === id)?.name || '';
	const pushDelete = (id) => {
		Pushes.remove(id).then((res) => {
			successShow(res.message);
			pushesGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const statusGet = (v) => <div className={`status${v.status===notificationStatus.ADDED?1:0}`}>
		<div className="status-point"></div>{notificationStatusName[v.status]}
	</div>;
	const nameGet = (v) => <>
		{v.name}
		{v.options.start ? <span>{moment(v.options.start).format('DD.MM.YYYY HH:mm')}</span> : null}
	</>;
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = pushes.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					title:s.properties.title,
					subtitle:s.properties.subtitle,
					text:s.properties.text,
					start:s.options.start,
					includes:s.target.includes,
					excludes:s.target.excludes
				};
				await Pushes.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		pushesGet();
		cancel();
		return true;
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Пуши"
							type="marketing"
							data={pushes}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name',func:nameGet},
								{title:'Статус',field:'status',class:'status',func:statusGet}
							]}
							footerItems={[
								{title:'Сделать копию',action:massCopy}
							]}
							empty={<>Добавьте пуш<br/>рассылку</>}
							contentShowTitle={name}
							contentShow={<>
								<div className="product-view-row product-view-row-oneline">
									<span>Статус</span>
									<div>{notificationStatusName[status]}</div>
								</div>
								<h4>Параметры</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Заголовок</span>
									<div>{title}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Подзаголовок</span>
									<div>{subTitle}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Сообщение</span>
									<div>{text}</div>
								</div>
								{start ?
										<div className="product-view-row product-view-row-oneline">
											<span>Дата</span>
											<div>{start?moment(start).format('DD.MM.YYYY HH:mm'):'–'}</div>
										</div>
									:
										(status === notificationStatus.ADDED ?
												<>
													<div className="product-edit-row">
														<span>Дата рассылки не установлена</span>
													</div>
													<div className="product-view-row product-edit-row">
														<div className="product-edit-btn" onClick={() => pushStart()}>Запустить</div>
													</div>
												</>
											: null)
								}
								{update ?
									<div className="product-view-row product-view-row-oneline">
										<span>Обновлено</span>
										<div>{start?moment(update).format('DD.MM.YYYY HH:mm'):'–'}</div>
									</div> : null}
								<h4>Получатели</h4>
								<div className="product-view-row">
									<span>Рассылка по меткам</span>
									<div>{includes.length ? includes.map((v,i) => labelNameGet(v)).join(', ') : '–'}</div>
								</div>
								<div className="product-view-row">
									<span>Исключить метки</span>
									<div>{excludes.length ? excludes.map((v,i) => labelNameGet(v)).join(', ') : '–'}</div>
								</div>
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название рассылки" className="input-title" autoFocus={true} required />
								</div>
								<h4>Параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<input {...bindTitle} type="text" placeholder="Заголовок" required />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<input {...bindSubTitle} type="text" placeholder="Подзаголовок" />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Сообщение" {...bindText} required></textarea>
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<input {...bindStart} type="datetime-local" placeholder="Дата" />
								</div>
								<h4>Получатели с метками</h4>
								<div className="product-edit-row">
									{includes.length ?
											<div className="product-edit-components">
												{includes.map((v,i) => <div key={i} className="product-edit-component">
													{labelNameGet(v)}
													<img src={imgClose} alt="" onClick={() => setIncludes(includes.filter(f => f !== v))} />
												</div>)}
											</div>
										: null
									}
									<div className="product-edit-btn" onClick={() => labelsShow(true)}>Добавить метку</div>
								</div>
								<h4>Получатели без метками</h4>
								<div className="product-edit-row">
									{excludes.length ?
											<div className="product-edit-components">
												{excludes.map((v,i) => <div key={i} className="product-edit-component">
													{labelNameGet(v)}
													<img src={imgClose} alt="" onClick={() => setExcludes(excludes.filter(f => f !== v))} />
												</div>)}
											</div>
										: null
									}
									<div className="product-edit-btn" onClick={() => labelsShow(false)}>Добавить метку</div>
								</div>
							</>}
							editExpression={(v) => v.status === notificationStatus.ADDED}
							deleteExpression={(v) => v.status === notificationStatus.ADDED}
							hideEditExpression={() => status === '' || status === notificationStatus.SENT}
							onDelete={pushDelete}
							onEdit={pushEdit}
							onAdd={pushAdd}
							onSave={save}
							onSearch={searchPush}
							onShow={pushShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalLabels>
				<div className="modal modal-products">
					<div className="header">
						<h4>Добавить метку</h4>
						<img src={imgClose} alt="" onClick={modalLabelsClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchLabel} autoFocus={true} />
					<div className="products">
						<div className="list">
							{labels.length ?
									labels.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											{v.description ? <span>{v.description}</span> : null}
										</div>
										<img src={labelsSelected.find(f => f._id === v._id) ? imgCheckOn : imgCheckOff} alt="" onClick={() => labelSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
						<div className="buttons">
							<button type="button" onClick={labelAdd} className="btn-accept">Добавить</button>
						</div>
					</div>
				</div>
			</ModalLabels>
		</>
	);
};

export default PushesScreen;