import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import {YMaps,Map,ZoomControl,Polygon} from 'react-yandex-maps';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {Addresses, Areas, Cities, Points} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { MAP } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClose from './Images/close.svg';
import imgEdit from './Images/edit.svg';
import imgEditStop from './Images/edit-stop.svg';


// start
const DeliveryAreasScreen = () => {
	const [ModalMap, modalMapOpen, modalMapClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [areasAll, setAreasAll] = useState([]);
	const [areas, setAreas] = useState(null);
	const [point, setPoint] = useState(null);
	const [addresses, setAddresses] = useState([]);
	const [cities, setCities] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const [area, setArea] = useState([]);
	const {value:address, setValue:setAddress, bind:bindAddress} = useInput('');
	const [latitude, setLatitude] = useState(null);
	const [longitude, setLongitude] = useState(null);
	const [search, setSearch] = useState(null);
	const [isMapEdit, setIsMapEdit] = useState(false);
	const [isError, setIsError] = useState(false);
	let map = null;
	let polygons = null;
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const point = ls('point');
			await pontGet(point.id);
			await citiesGet();
			await areasGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const pontGet = async (id) => {
		const point = await Points.get.id(id).catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (point === undefined) return;
		setPoint(point);
	};
	const citiesGet = async () => {
		const cities = await Cities.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (cities === undefined) return;
		setCities(cities);
	};
	const areasGet = async () => {
		const areas = await Areas.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined || areas.length === 0) return;
		setAreas(areas);
		setAreasAll(areas);
	};
	const areaAdd = () => show();
	const areaEdit = (area) => {
		area = area || areas.find(f => f._id === id);
		show(area);
	};
	const areaShow = (area) => show(area);
	const show = (area) => {
		setId(area?._id||null);
		setName(area?.name||'');
		setAddress(area?.address||'');
		setLatitude(area?.properties?.latitude||null);
		setLongitude(area?.properties?.longitude||null);
		setArea(area && area.area ? area.area : []);
	};
	const cancel = () => {
		areaShow(null);
		mapClose();
	}
	const save = async () => {
		const data = {
			name:name,
			address:address,
			latitude:latitude,
			longitude:longitude,
			area:area
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название зоны доставки');
			return;
		}
		if (Utils.empty(data.area)) {
			errorShow('Необходимо заполнить область действия точки');
			return;
		}
		const res = id ?
				await Areas.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await Areas.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			successShow(res.message);
			areasGet();
			cancel();
			return true;
		}
	}
	const searchArea = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setAreas(areasAll);
		else setAreas(areasAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const areaDelete = (id) => {
		Areas.remove(id).then((res) => {
			successShow(res.message);
			areasGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const cityCoordinatesGet = () => {
		const city = cities.find(f => f._id === point.cityId);
		const latitude = city?.coordinates?.latitude||MAP.defCoords[0], longitude = city?.coordinates?.longitude||MAP.defCoords[1];
		return [latitude,longitude];
	};
	const startEdit = () => {
		if (polygons) {
			if (area && area.length) polygons.editor.startEditing();
			else polygons.editor.startDrawing();
			polygons.geometry.events.add('change', (e) => setArea(e.get('newCoordinates')));
		}
		setIsMapEdit(true);
	};
	const stopEdit = () => {
		if (polygons) polygons.editor.stopEditing();
		setIsMapEdit(false);
	}
	const mapClose = () => {
		stopEdit();
		modalMapClose();
	};
	const mapLoaded = (ymaps) => {
		setTimeout(() => {
			if (map) {
				if (area && area.length) {
					const y = ymaps.geoQuery(map.geoObjects);
					map.setBounds(y.getBounds(), {zoomMargin: [30, 30, 30, 30]});
					map.setZoom(map.getZoom());
				}
			}
		}, 1000)
	};
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = areas.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					address:s.address,
					latitude:s.latitude,
					longitude:s.longitude,
					area:s.area
				};
				await Areas.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
			}
		};
		successShow('Операция прошла успешно');
		areasGet();
		cancel();
		return true;
	};
	const staticMapGet = (area) => {
		const poligons = [];
		const color = '00ff00';
		area.forEach((v) => {
			const p = [];
			v.forEach((v) => {
				p.push(v[1]);
				p.push(v[0]);
			});
			poligons.push(`c:${color},f:${color}30,w:3,${p.join(',')}`);
		});
		const url = `${MAP.URLS.static}&size=400,300&pl=${poligons.join('~')}&apikey=${MAP.keys.YStatic}`;
		return <img src={url} alt="" />
	};
	const addressGet = async (e) => {
		const address = e.target.value;
		const addresses = await Addresses.get(address, point.cityId).catch((ex) => console.log(666, ex));
		setAddress(address);
		setAddresses(addresses);
	};
	const selectAddress = (address) => {
		setAddress(address.address);
		setLatitude(address.latitude);
		setLongitude(address.longitude);
		setAddresses([]);
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Точки самовывоза"
							type={[{type:'account'},{type:'settings',title:'Настройки'}]}
							allHide={true}
							data={areas}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'},
								{title:'Адрес',field:'address'}
							]}
							rowsShort={['name']}
							footerItems={[
								{title:'Сделать копию',action:massCopy}
							]}
							empty={<>Добавьте первую<br/>точку самовывоза<br/><span>не обязательно если у вас один филиал</span></>}
							contentShowTitle={name}
							contentShow={<>
								<h4>Адрес</h4>
								<div className="product-view-row">
									<div>{address}</div>
								</div>
								{area && area.length ?
									<>
										<h4>Область обслуживания</h4>
										<div className="product-view-row-simple">
											{staticMapGet(area)}
										</div>
									</> : null}
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название точки" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<input type="text" {...bindAddress} placeholder="Адрес точки" onChange={addressGet} required />
								</div>
								<div className="product-edit-row-addresses">
									{addresses.length ?
										<div className="point-addresses-list">
											{addresses.map((v,i) => <div key={i} onClick={() => selectAddress(v)}>{v.address}</div>)}
										</div> : null}
									</div>
								<h4>Область обслуживания</h4>
								<div className="product-edit-row">
									{area && area.length ? staticMapGet(area) : null}
									<div className="product-edit-btn" onClick={() => modalMapOpen()}>{area && area.length ? 'Изменить' : 'Добавить'}</div>
								</div>
							</>}
							onDelete={areaDelete}
							onEdit={areaEdit}
							onAdd={areaAdd}
							onSave={save}
							onSearch={searchArea}
							onShow={areaShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalMap>
				<div className="modal modal-map">
					<div className="map-container">
						<YMaps query={{apikey:MAP.keys.Yandex}}>
							<Map
								instanceRef={m => map = m}
								onLoad={mapLoaded}
								defaultState={{center:cityCoordinatesGet(),zoom:12}}
								modules={['geoObject.addon.editor','geoQuery']}
								width={'100%'} height={'100%'}
							>
								<ZoomControl />
								<Polygon
									instanceRef={p => polygons = p}
									geometry={area||[]}
									options={{
										editorDrawingCursor:'crosshair',
										strokeOpacity:.5,
										strokeWidth:3,
										fillColor:'#00ff00',
										opacity:.5
									}}
									properties={{hintContent:name}}
									modules={['geoObject.addon.hint']}
									onLoad={startEdit} />
							</Map>
						</YMaps>
						<div className="map-icon map-close">
							<img src={imgClose} alt="" onClick={mapClose} />
						</div>
						<div className="map-icon map-edit">
							<img src={isMapEdit ? imgEditStop : imgEdit} alt="" onClick={() => isMapEdit ? stopEdit() : startEdit()} />
						</div>
					</div>
				</div>
			</ModalMap>
		</>
	);
};

export default DeliveryAreasScreen;