import React, {useEffect,useState} from 'react';

// styles
import './styles.css';


const Accordion = (props) => {
	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState(props.isShow||false);
	useEffect(() => {
		setLoading(false);
	}, []);
	const switcher = () => setShow(!show);
	return loading ? null :
		<div className={`product-accordeon-accordeon${show?'':' product-accordeon-hide'}`}>
			<div className="product-accordeon-title" onClick={switcher}>
				<div>
					<h4>{props.title}</h4>
					{show ? null :
						props.info ?
								<div className="info">
									{props.info}
								</div>
							: null
					}
				</div>
				<div className="product-accordeon-arrow"></div> 
			</div>
			<div className="product-accordeon-content">
				{props.children}
			</div>
		</div>;
};

export default Accordion;