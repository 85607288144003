import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()			=> await Http.request('promoactions');

const add		= async (data)		=> await Http.request('promoaction', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`promoaction/${id}`, httpMethods.PUT, data);

const status	= async (id, data)	=> await Http.request(`promoaction/status/${id}`, httpMethods.PATCH, data);

const remove	= async (id)		=> await Http.request(`promoaction/${id}`, httpMethods.DELETE);

const image = {
	add			: async (id, data)	=> await Http.request(`promoaction/image/${id}`, httpMethods.POST, data),
	copy		: async (id, id2)	=> await Http.request(`promoaction/image/copy/${id}/${id2}`, httpMethods.POST)
};


export {
	get,
	add,
	update,
	status,
	remove,
	image
}