import React, {useEffect,useState} from 'react';

// plug-ins
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {LoyalityTransactions} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Orfo from '../../../Globals/Orfo';

// constants
import { transactionTypeName } from '../../../Globals/Constants';


// start
const LoyalityTransactionsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [transactions, setTransactions] = useState(null);
	const [transactionsAll, setTransactionsAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:amount, setValue:setAmount, bind:bindAmount} = useInput('');
	const {value:comment, setValue:setComment, bind:bindComment} = useInput('');
	const {value:phones, setValue:setPhones, bind:bindPhones} = useInput('');
	const {value:days, setValue:setDays, bind:bindDays} = useInput('30');
	const [createdAt, setCreatedAt] = useState('');
	const [clients, setClients] = useState([]);
	const [type, setType] = useState('');
	const [search, setSearch] = useState('');
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await loyalitiesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const loyalitiesGet = async () => {
		const transactions = await LoyalityTransactions.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (transactions === undefined) return;
		setTransactions(transactions);
		setTransactionsAll(transactions);
	};
	const transactionAdd = () => show();
	const transactionShow = (transaction) => show(transaction);
	const show = (transaction) => {
		setId(transaction?transaction._id:null);
		setAmount(transaction?transaction.amount:'');
		setComment(transaction?transaction.description:'');
		setPhones(transaction?transaction.phones:'');
		setDays(transaction?transaction.properties.days:'');
		setType(transaction?transaction.type:'');
		setClients(transaction?transaction.clients:[]);
		setCreatedAt(transaction?transaction.createdAt:'');
	};
	const cancel = () => transactionShow(null);
	const save = async () => {
		const data = {
			amount:amount,
			comment:comment,
			phones:phones,
			days:days,
			type:type,
		};
		if (Utils.empty(data.amount)) {
			errorShow('Необходимо заполнить сумму');
			return;
		}
		const res = await LoyalityTransactions.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			loyalitiesGet();
			cancel();
			return true;
		}
	}
	const searchTransactions = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setTransactions(transactionsAll);
		else setTransactions(transactionsAll.filter(f => f.phone.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const handleType = (e) => setType(parseInt(e.target.value));
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Бонусы"
							type="marketing"
							data={transactions}
							id={id}
							search={search}
							rows={[
								{title:'Дата',field:'createdAt',func:(v) => moment(v.createdAt).format('DD.MM.YYYY HH:mm')},
								{title:'Тип',field:'status',class:'status',func:(v) => <div className={`status${v.type}`}>{transactionTypeName[v.type]}</div>}
							]}
							empty={<>Добавьте бонусы<br/>программы лояльности</>}
							contentShowTitle={moment(createdAt).format('DD.MM.YYYY HH:mm')}
							contentShow={<>
								{comment ? 
									<div className="product-view-row-simple">
										{comment}
									</div> : null}
								<h4>Пользователи</h4>
								<div className="product-view-row">
									{clients.map((v,i) => <div key={i}>
										{Utils.phoneFormatter(v.phone)}
										{v.name ? ` (${v.name})` : null}
									</div>)}
								</div>
								<h4>Параметры</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Бонусов</span>
									<div>{amount?`${amount} ₽`:'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Действует в течении</span>
									<div>{Orfo.counters.days(days)}</div>
								</div>
							</>}
							contentEdit={<>
								<div className="product-edit-row product-edit-row-first">
									<div className="select select-wide">
										<select onChange={handleType} value={type} required>
											<option value="">Выберите тип</option>
											{transactionTypeName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Комментарий" {...bindComment}></textarea>
								</div>
								<h4>Пользователи</h4>
								<div className="product-edit-row">
									<textarea placeholder="Телефоны через запятую" {...bindPhones}></textarea>
								</div>
								<h4>Правила</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="bonuses" className="label-middle">Кол-во бонусов</label>
									<input id="bonuses" {...bindAmount} type="text" placeholder="0" maxLength={5} required />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="bonuses" className="label-middle">Время действия, дней</label>
									<input id="bonuses" {...bindDays} type="text" placeholder="0" maxLength={3} required />
								</div>
							</>}
							onAdd={transactionAdd}
							onSave={save}
							onSearch={searchTransactions}
							onShow={transactionShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default LoyalityTransactionsScreen;