import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get = {
	get			: async ()			=> await Http.request('points'),
	id			: async (id)		=> await Http.request(`point/${id}`),
	coords		: async (lat, lng)	=> await Http.request(`point/coordinates/${lat},${lng}`)
};

const add		= async (data)		=> await Http.request('point', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`point/${id}`, httpMethods.PUT, data);

const status	= async (id, data)	=> await Http.request(`point/status/${id}`, httpMethods.PATCH, data);


export {
	get,
	add,
	update,
	status
}